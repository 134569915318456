import { Typography, Grid, Button } from "@mui/material";
import React from "react";
import Research from "./images/Research.png";
import Research1 from "./images/Research1.png";
import Research2 from "./images/Research2.jpg";
import "./feed.css"
import ResearchForm from "./ResearchForm";
import styled from '@emotion/styled';
import { NavLink, useNavigate } from "react-router-dom";
import ResearchDetails from "./Agriculture";

const Button1 = styled(Button)({
  color: "#fff"
})



export default function HomePage() {

  const navigate = useNavigate();

  return (

    <Grid container>


      <Grid container sx={{ marginBottom: '2%' }}>
        <Grid item xs={12} lg={8} />

        <Grid item xs={12} lg={4} sx={{ display: 'flex', justifyContent: 'space-around', marginTop: '2%' }}>

          <NavLink to={'/researchfeed'} style={{ textDecoration: 'none' }}><Button1 variant="contained" sx={{ height: "40px", width: "100px", fontSize: '1.1rem'}}><b>Feed</b></Button1></NavLink>

        </Grid>

      </Grid>

      <Grid container sx={{ marginBottom: '100px' }}>

        <Grid item xs={12} lg={6} >
          <Grid sx={{ padding: '2% 7%' }}>
            <Typography variant="h3" sx={{ marginBottom: '40px' }}>
              Welcome to the <span style={{ color: '#1a50b2' }}>Research</span> <br /> <span style={{ color: '#1a50b2' }}>Quarters</span>
            </Typography>

            <Typography sx={{ fontFamily: 'Inter', lineHeight: '30px', textAlign: 'justify', fontSize: '1.25rem' }}>
              Greetings! This is an oasis for synergy research. We take pride in standing as an
              intersection for research companies and candidates looking to broaden their horizons.
              Our platform empowers research of all kinds, be it “topic research” or “applied research”.
            </Typography>

          </Grid>

        </Grid>


        <Grid item xs={12} lg={6}>
          <Grid sx={{ paddingLeft: '15%' }}>
            <img src={Research2} alt='image'
              style={{ width: '90%', height: '60%' }}
            />
          </Grid>
        </Grid>

      </Grid>

      <Grid item xs={12} sx={{ textAlign: 'center', marginBottom: '50px' }}>
        <Typography variant="h3">
          Significance of <span style={{ color: '#1a50b2' }}>Research</span>
        </Typography>
      </Grid>

      <Grid container sx={{ backgroundColor: '#e9fcfd', marginBottom: '50px' }}>


        <Grid item xs={12} lg={8} sx={{ display: 'flex', alignItems: 'center' }}>

          <Grid sx={{ padding: '0% 5% 0% 15%' }}>
            <Typography sx={{ fontFamily: 'Inter', lineHeight: '30px', textAlign: 'justify', fontSize: '1.25rem' }}>
              The main motives of research are to report action, gather documentation for theories, and contribute to developing knowledge in a given field of study.
              Research is a tool for constructing knowledge and facilitates learning. It provides means to a deeper understanding of issues and increases public awareness.
              In addition, research enables us to succeed in business, as it is a means to find, gauge, and seize opportunities.

            </Typography>
          </Grid>

        </Grid>


        <Grid item xs={12} lg={4} >
          <Grid sx={{ paddingLeft: '5%' }} >
            <img src={Research1} alt='image'
              style={{ margin: '4% 0%', width: '90%', height: '70%' }}
            />
          </Grid>
        </Grid>

      </Grid>

      <Grid container sx={{ marginBottom: '50px' }}>
        <Grid item xs={12} lg={12}>
          <ResearchDetails buttonText1='Upload Image' buttonText2='Upload PDF' />
        </Grid>

      </Grid>

    </Grid>
  )
}