import * as React from "react";
import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";
import { Grid, Typography,FormControl,InputLabel,MenuItem,Select } from "@mui/material";
import {JobContext} from "./Postjob"

export default function Secondmcq() {
  const {job,setJob}= React.useContext(JobContext)
  
  return (
    <Grid item container spacing={2} sx={{display: 'flex'}}>
      
    <Grid item xs={12} lg={3} sm={5}>
          <Typography
            sx={{
              fontFamily: "Epilogue",
              fontStyle: "normal",
              fontWeight: "600",
              fontSize: "18px",
            }}
          >
            Type of Employment
          </Typography>
        
        <br/>
          <Typography
            sx={{
              color: "#7C8493",
              fontFamily: "Epilogue",
              fontStyle: "normal",
              fontWeight: "400",
              fontSize: "18px",
            //   width: "200px"
            }}
          >
            You can select multiple employment
          </Typography>
        
      </Grid>
      <Grid item lg={2} sm={1}/>
      <Grid item  xs={12} lg={7} sm={6}>
      <FormControl sx={{ m: 1, minWidth: 320 }}>
        <InputLabel id="demo-simple-select-helper-label">Your job category</InputLabel>
        <Select required
          labelId="demo-simple-select-helper-label"
          id="demo-simple-select-helper"
          value={job.typeOfEmployment}
          label="Your job category"
          onChange={e=>setJob({...job,typeOfEmployment:e.target.value})}
        >
          <MenuItem value="">
            <em>None</em>
          </MenuItem>
          <MenuItem value={'Full-Time'}>Full-Time</MenuItem>
          <MenuItem value={'Part-Time'}>Part-Time</MenuItem>
          <MenuItem value={'Remote'}>Remote</MenuItem>
          <MenuItem value={'Internship'}>Internship</MenuItem>
          <MenuItem value={'Contract'}>Contract</MenuItem>
          <MenuItem value={'Training'}>Training</MenuItem>
          <MenuItem value={'Live Project'}>Live Project</MenuItem>
          
          
        </Select>
      </FormControl>
      </Grid>
      
    </Grid>
  );
}
