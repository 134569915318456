import React, { createContext, useContext, useEffect, useState } from "react";
import { CognitoUserPool } from 'amazon-cognito-identity-js';
import {
    Grid,
    Typography,
    Box,
    FormControl,
    Button,
    Avatar
    , Divider,
    TextField
} from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import OutlinedInput from "@mui/material/OutlinedInput";
import kin from "./kin.png";
import Switch from '@mui/material/Switch';
import { styled } from '@mui/material/styles';
import tag from './tag.png';
import tag1 from './tag1.png';
import tag2 from './tag2.png';
import JoditC from "./JoditC";
import BasicCat from "./BasicCat";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import { useTheme } from "@mui/material/styles";
import Payment from '../payment/Payment'
import Hheader from '../Vaibhav/Header'
import axios from "axios";
import { Context } from "../App";
import { useNavigate } from "react-router-dom";

import { useAlert } from 'react-alert'

const Input = styled('input')({
    display: 'none',
});

const catname = ['Educational Entity','Incubation Centers','Angle Investors','Private Firms','Individual Contributors',"MSME'S", 'Co-operate Houses ( Business Groups )','Finance Houses','Banks'];
const name = ['Job Portal', 'Research', 'Incubation', 'All of Above'];

export const profileContext = createContext();
const BasicInfo = () => {
    const alert = useAlert()
    const navigate = useNavigate();
    const { authState, setAuthState } = useContext(Context);
    console.log(authState.user)
    const [render,setRender]=useState(false)
    const [data, setData] = useState({
        id: authState.user.id,
        name: authState.user.name,
        email: authState.user.email,
        phone: authState.user.phone,
        website: "",
        discription: "",
        address: "",
        country: "",
        state: "",
        income: "",
        contact: "",
        category: "",
        subcategory: "",
        partneringFor: "",
        img: "",
        imgUrl: ""
    });

    const handleChange = (event) => {
        const {
            target: { value },
        } = event;
        setData({
            ...data,
            partneringFor: typeof value === "string" ? value.split(",") : value
        }
        );
    };
    const CathandleChange = (event) => {
        const {
            target: { value },
        } = event;
        setData({
            ...data,
            category: typeof value === "string" ? value.split(",") : value
        }
        );
    };
    useEffect(() => {
        axios({
            method: "post",
            url: "https://po.keewesolutions.com/cpbyid",
            data: {
                id: authState.user.id
            }
        }).then(res => {
            const cp = res.data.Item;
            
            if (cp !=undefined) {
               // setData({...data,name:cp.name})
               console.log(cp)
                setData({
                id: authState.user.id,
                name:cp.name,
                email:cp.email,
                phone:cp.phone,
                website: cp.website,
                discription:cp.discription,
                address:cp.address,
                country:cp.country,
                state:cp.state,
                income:cp.income,
                contact:cp.contact,
                category:cp.category,
                subcategory:cp.subcategory,
                partneringFor:cp.partneringFor,
                imgUrl:cp.imgUrl,
                img:""})
                setRender(true)
            }
            else{
                setRender(true)
            }
        })
    }, [])
    const submit = async () => {
        var formData = new FormData();
        console.log(data.id)
        if(data.id!=undefined){

        formData.append('id', data.id)
        formData.append('name', data.name)
        formData.append('email', data.email)
        formData.append('phone', data.phone)
        formData.append('website', data.website)
        formData.append('discription', data.discription)
        formData.append('address', data.address)
        formData.append('country', data.country)
        formData.append('state', data.state)
        formData.append('income', data.income)
        formData.append('contact', data.contact)
        formData.append('category', data.category)
        formData.append('subcategory', data.subcategory)
        formData.append('partneringFor', data.partneringFor);
        formData.append('imgUrl', data.imgUrl);
        await axios({
            method: "post",
            url: "https://po.keewesolutions.com/cp",
            data: formData
        }).then(res => {
            console.log("okk");
        //    setData({
        //     id: authState.user.id,
        //     name: "",
        //     email: "",
        //     phone: "",
        //     website: "",
        //     discription: "",
        //     address: "",
        //     country: "",
        //     state: "",
        //     income: "",
        //     contact: "",
        //     category: "",
        //     subcategory: "",
        //     partneringFor: "",
        //     img: "",
        //     imgUrl: ""
        //    });
           alert.show('Successfully Updated',{
            type: 'success'
           })
        })
                    
    }
    }

    return (
        <>
        {render?
            <profileContext.Provider value={{ data, setData }}>
                <Grid container sx={{  padding: "2%" }}>

                    <Grid xs={2}></Grid>

                    <Grid xs={12} lg={8} sx={{ backgroundColor: "white" }}>
                        <Box sx={{ padding: "4%" }}>
                            <Box display={"flex"}>
                                <img src={tag} alt="" />
                                <Typography
                                    sx={{
                                        fontFamily: "Poppin",
                                        fontWeight: "800",
                                        fontSize: "26px",
                                        lineHeight: "120%",
                                        color: "#25324B",
                                        marginLeft: '2%'
                                    }}
                                >
                                    Company Profile
                                </Typography>
                            </Box>

                            <Grid xs={12} display={"flex"} marginTop={"4%"}>
                                <Grid xs={2}>
                                
                                {(data.img == "") ? (
							<img
								src={data.imgUrl}
								alt="max 200KB"
								style={{
									width: '150px',
									height: '150px',
									objectFit: 'contain'
								}}
							/>
						 ) : (
							<img
								src={data.img}							
								alt="max 200KB"
								style={{
									width: '150px',
									height: '150px',
									objectFit: 'contain'
								}}
							/>
						
						)}  

                                </Grid>


                                <Grid xs={10}
                                    sx={{
                                        display: "flex",
                                        marginLeft: "16%",
                                        marginTop: '3%'
                                    }}
                                >
                                    <label htmlFor="contained-button-file">
                                        <Input accept="image/*" id="contained-button-file" multiple type="file"
                                            onChange={(e) => {
                                                setData({...data,imgUrl:e.target.files[0],img:URL.createObjectURL(e.target.files[0])})
                                            }} />
                                        <Button
                                            component="span"
                                            variant="contained"
                                            sx={{
                                                backgroundColor: "#2A85FF",
                                                borderRadius: "12px",
                                                height: "48px",
                                                fontFamily: "Poppin",
                                                fontWeight: "500",
                                                fontSize: "13px",
                                                color: "white",
                                                textTransform: "capitalize",

                                            }}
                                        >
                                            <AddIcon />
                                            Company Logo
                                        </Button>
                                    </label>

                                    <Button
                                        variant="outlined"
                                        sx={{
                                            backgroundColor: "#FCFCFC",
                                            border: "1px solid #EFEFEF",
                                            borderRadius: "12px",
                                            marginLeft: "4%",

                                            height: "48px",
                                            fontFamily: "Poppin",
                                            fontWeight: "700",
                                            fontSize: "13px",
                                            lineHeight: "24px",
                                            letterSpacing: "-0.01em",
                                            color: "#000000",
                                            textTransform: "capitalize",
                                            minWidth: '90px'
                                        }}
                                        onClick={e=>{setData({...data,img:""})}}
                                    >
                                        Remove
                                    </Button>
                                </Grid>

                            </Grid>
                            <Grid sx={{ marginTop: "4%" }}>
                                <FormControl sx={{ m: 1 }} variant="outlined" fullWidth>
                                    <Typography
                                        sx={{
                                            fontFamily: "Poppin",
                                            fontWeight: "600",
                                            fontSize: "18px",
                                            lineHeight: "303%",
                                            color: "#515B6F",
                                        }}
                                    >
                                        Display name
                                    </Typography>
                                    <OutlinedInput sx={{ borderRadius: '19px', }}
                                        value={data.name}
                                        onChange={e => setData({ ...data, name: e.target.value })} required />
                                </FormControl>
                                <FormControl sx={{ m: 1 }} variant="outlined" fullWidth>
                                    <Typography
                                        sx={{
                                            fontFamily: "Poppin",
                                            fontWeight: "600",
                                            fontSize: "18px",
                                            lineHeight: "160%",
                                            color: "#515B6F",
                                        }}
                                    >
                                        Email
                                    </Typography>
                                    <OutlinedInput sx={{ borderRadius: '19px',  }}
                                        value={data.email}
                                        onChange={e => setData({ ...data, email: e.target.value })} required />
                                </FormControl>
                                <FormControl sx={{ m: 1 }} variant="outlined" fullWidth>
                                    <Typography
                                        sx={{
                                            fontFamily: "Poppin",
                                            fontWeight: "600",
                                            fontSize: "18px",
                                            lineHeight: "160%",
                                            color: "#515B6F",
                                        }}
                                    >
                                        Phone
                                    </Typography>
                                    <OutlinedInput sx={{ borderRadius: '19px',  }}
                                        value={data.phone}
                                        onChange={e => setData({ ...data, phone: e.target.value })} required />
                                </FormControl>

                                <FormControl sx={{ m: 1 }} variant="outlined" fullWidth>
                                    <Typography
                                        sx={{
                                            fontFamily: "Poppin",
                                            fontWeight: "600",
                                            fontSize: "18px",
                                            lineHeight: "160%",
                                            color: "#515B6F",
                                        }}
                                    >
                                        Company website
                                    </Typography>
                                    <OutlinedInput sx={{ borderRadius: '19px',  }}
                                        value={data.website}
                                        onChange={e => setData({ ...data, website: e.target.value })} required />
                                </FormControl>
                                <FormControl sx={{ m: 1 }} variant="outlined" fullWidth>
                                    <Typography
                                        sx={{
                                            fontFamily: "Poppin",
                                            fontWeight: "600",
                                            fontSize: "20px",
                                            marginTop: "3%",
                                            color: "#515B6F",
                                        }}
                                    >
                                        Description
                                    </Typography>
                                    <TextField
                                        multiline
                                        rows={4}
                                        value={data.discription}
                                        onChange={e => setData({ ...data, discription: e.target.value })}
                                        required
                                    />
                                </FormControl>
                                <Divider sx={{ marginTop: '4%' }} />
                                <Box display={"flex"}>
                                    <img src={tag1} alt="" style={{ marginTop: '4%' }} />
                                    <Typography
                                        sx={{
                                            fontFamily: "Poppin",
                                            fontWeight: "1000",
                                            fontSize: "24px",
                                            marginTop: "4%",
                                            color: "#25324B",
                                            marginLeft: '2%'

                                        }}
                                    >
                                        Address
                                    </Typography>
                                </Box>

                                <FormControl sx={{ m: 1 }} variant="outlined" fullWidth>
                                    <Typography
                                        sx={{
                                            fontFamily: "Poppin",
                                            fontWeight: "600",
                                            fontSize: "18px",
                                            lineHeight: "280%",
                                            color: "#515B6F",
                                        }}
                                    >

                                    </Typography>
                                    <OutlinedInput sx={{ borderRadius: '19px',  }}
                                        value={data.address}
                                        required
                                        onChange={e => setData({ ...data, address: e.target.value })} />
                                </FormControl>
                                <FormControl sx={{ m: 1 }} variant="outlined" fullWidth>
                                    <Grid display={"flex"} xs={12}>
                                        <Grid xs={6}>
                                            <Typography
                                                sx={{
                                                    fontFamily: "Poppin",
                                                    fontWeight: "600",
                                                    fontSize: "18px",
                                                    lineHeight: "160%",
                                                    color: "#515B6F",
                                                }}
                                            >
                                                Country
                                            </Typography>
                                            <OutlinedInput sx={{ width: "99%", borderRadius: '19px',  }}
                                                value={data.country}
                                                required
                                                onChange={e => setData({ ...data, country: e.target.value })}
                                            />
                                        </Grid>
                                        <Grid xs={6}>
                                            <Typography
                                                sx={{
                                                    fontFamily: "Poppin",
                                                    fontWeight: "600",
                                                    fontSize: "18px",
                                                    lineHeight: "160%",
                                                    color: "#515B6F",
                                                }}
                                            >
                                                State/City
                                            </Typography>
                                            <OutlinedInput sx={{ width: "99%", borderRadius: '19px', }}
                                                value={data.state}
                                                required
                                                onChange={e => setData({ ...data, state: e.target.value })} />
                                        </Grid>
                                    </Grid>
                                </FormControl>

                                <FormControl sx={{ m: 1 }} variant="outlined" fullWidth>
                                    <Grid display={"flex"} xs={12}>
                                        <Grid xs={6}>
                                            <Typography
                                                sx={{
                                                    fontFamily: "Poppin",
                                                    fontWeight: "600",
                                                    fontSize: "18px",
                                                    lineHeight: "160%",
                                                    color: "#515B6F",
                                                }}
                                            >
                                                Authorized Person to contact
                                            </Typography>
                                            <OutlinedInput sx={{ width: "99%", borderRadius: '19px',  }}
                                                value={data.contact}
                                                required
                                                onChange={e => setData({ ...data, contact: e.target.value })} />
                                        </Grid>
                                        <Grid xs={6}>
                                            <Typography
                                                sx={{
                                                    fontFamily: "Poppin",
                                                    fontWeight: "600",
                                                    fontSize: "18px",
                                                    lineHeight: "160%",
                                                    color: "#515B6F",
                                                }}
                                            >
                                                Income Level
                                            </Typography>
                                            <OutlinedInput sx={{ width: "99%", borderRadius: '19px',  }}
                                                value={data.income}
                                                required
                                                onChange={e => setData({ ...data, income: e.target.value })} />
                                        </Grid>
                                    </Grid>
                                </FormControl>


                                <Box display={"flex"}>
                                    <img src={tag1} alt="" style={{ marginTop: '4%' }} />
                                    <Typography
                                        sx={{
                                            fontFamily: "Poppin",
                                            fontWeight: "1000",
                                            fontSize: "24px",
                                            marginTop: "4%",
                                            color: "#25324B",
                                            marginLeft: '2%'
                                        }}
                                    >
                                        Category
                                    </Typography>
                                </Box>

                                {/* <Grid item display={"flex"} xs={12}>


                                    <BasicCat data={data} setData={setData}/>


                                </Grid> */}
                                <Grid item display={"flex"} xs={12} sx={{
                                    justifyContent:'space-between'
                                }}>
                                <Grid item xs={12} lg={6}>
                                <Typography
                                                sx={{
                                                    fontFamily: "Poppin",
                                                    fontWeight: "600",
                                                    fontSize: "18px",
                                                    lineHeight: "160%",
                                                    color: "#515B6F",
                                                }}
                                            >
                                                Category
                                            </Typography>
                                            <OutlinedInput sx={{ width: "99%", borderRadius: '19px',height: '50px', }}
                                                value={data.category}
                                                placeholder="e.g. MSME" 
                                                required
                                                onChange={e => setData({ ...data, category: e.target.value })} />
                                    {/* <FormControl
                                        sx={{ width: '100%' }}
                                    >
                                        <Select
                                            sx={{ width: "100%", height: '50px', borderRadius: '19px', backgroundColor: '#F4F4F4' }}
                                            displayEmpty
                                            value={data.category}
                                            required
                                            onChange={CathandleChange}
                                            input={<OutlinedInput />}
                                            renderValue={(e) => {
                                                if (e.length === 0) {
                                                    return <Typography>Category</Typography>;
                                                }

                                                return e;
                                            }}
                                            MenuProps={MenuPropss}
                                            inputProps={{ "aria-label": "Without label" }}
                                        >
                                            <MenuItem disabled value="">
                                                <em>Category</em>
                                            </MenuItem>
                                            {catname.map((catname) => (
                                                <MenuItem
                                                    key={catname}
                                                    value={catname}
                                                >
                                                    {catname}
                                                </MenuItem>
                                            ))}
                                        </Select>
                                    </FormControl> */}
                                </Grid>

                                {/* <Grid item xs={12} lg={6}>
                                            <Typography
                                                sx={{
                                                    fontFamily: "Poppin",
                                                    fontWeight: "600",
                                                    fontSize: "18px",
                                                    lineHeight: "160%",
                                                    color: "#515B6F",
                                                }}
                                            >
                                                Sub Category
                                            </Typography>
                                            <OutlinedInput sx={{ width: "99%", borderRadius: '19px',height: '50px', backgroundColor: '#F4F4F4' }}
                                                value={data.subcategory}
                                                required
                                                onChange={e => setData({ ...data, subcategory: e.target.value })} />
                                        </Grid>*/}
                                        <Grid item xs={12} lg={6} marginTop='27px'>
                                        <FormControl
                                        sx={{ width: '100%' }}
                                    >
                                        <Select
                                            sx={{ width: "100%", height: '50px', borderRadius: '19px',  }}
                                            displayEmpty
                                            value={data.partneringFor}
                                            required
                                            onChange={handleChange}
                                            input={<OutlinedInput />}
                                            renderValue={(e) => {
                                                if (e.length === 0) {
                                                    return <Typography>Partnering For</Typography>;
                                                }

                                                return e;
                                            }}
                                            MenuProps={MenuPropss}
                                            inputProps={{ "aria-label": "Without label" }}
                                        >
                                            <MenuItem disabled value="">
                                                <em>Partnering For</em>
                                            </MenuItem>
                                            {name.map((name) => (
                                                <MenuItem
                                                    key={name}
                                                    value={name}
                                                >
                                                    {name}
                                                </MenuItem>
                                            ))}
                                        </Select>
                                    </FormControl>
                                    </Grid>

                                 </Grid>  
                                 



                                <Grid item xs={12} lg={12}>
                                    
                                </Grid>
                                <Divider sx={{ marginTop: '1%' }} />
                                <Grid sx={{ justifyContent: 'space-between', display: 'flex', marginTop: '4%' }}>
                                    <Button
                                        variant="contained"
                                        sx={{


                                            borderRadius: "12px",
                                            height: "44px",
                                            fontFamily: "Poppin",
                                            fontWeight: "700",
                                            fontSize: "18px",
                                            lineHeight: "24px",
                                            letterSpacing: "-0.01em",
                                            color: "#FCFCFC",
                                            textTransform: "capitalize",
                                            minWidth: '90px',
                                        }}
                                        onClick={submit}
                                    //   if(data.name.length !=0 , data.email.length !=0,data.phone.length !=0, data.website.length !=0, data.address.length !=0 ,data.country.length !=0 ,data.state.length !=0, data.contact.length !=0,data.income.length !=0 , data.discription.length !=0)
                                    // }}
                                    >
                                        Save
                                    </Button>

                                    <Button
                                        variant="outlined"
                                        sx={{
                                            backgroundColor: "#FCFCFC",
                                            border: "1px solid #EFEFEF",
                                            borderRadius: "12px",
                                            height: "48px",
                                            fontFamily: "Poppin",
                                            fontWeight: "700",
                                            fontSize: "18px",
                                            lineHeight: "24px",
                                            letterSpacing: "-0.01em",
                                            color: "#000000",
                                            textTransform: "capitalize",
                                            minWidth: '90px',

                                        }}
                                    >
                                        Cancel
                                    </Button>
                                </Grid>

                            </Grid>
                        </Box>
                    </Grid>

                    <Grid xs={2}></Grid>
                </Grid>
            </profileContext.Provider>:<h1>Loading Page</h1>}
        </>
    );

};

export default BasicInfo;

//partner

const ITEM_HEIGHTS = 48;
const ITEM_PADDING_TOPS = 8;
const MenuPropss = {
    PaperProps: {
        style: {
            maxHeight: ITEM_HEIGHTS * 4.5 + ITEM_PADDING_TOPS,
            width: 250,
        },
    },
};


