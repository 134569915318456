import React, { useState } from 'react';
import { Typography, Grid, Card, Button, ImageList } from '@mui/material';
import ModalComponent from "./ModalComponent"
import { fontFamily } from '@mui/system';
import ModalPlant from './images/ModalPlant.png'
import { NavLink } from 'react-router-dom';
export default function ResearchCard({ companyName, thumbnail, topic, discription, allowance, deadline, guidelines }) {
  // console.log(modalcontent);
  const LongText = ({ content, limit }) => {
    const [showAll, setShowAll] = useState(false);

    const showMore = () => setShowAll(true);
    const showLess = () => setShowAll(false);
    if (content.length <= limit) {
      // there is nothing more to show
      return <div>{content}</div>
    }
    else {
      return <div style={{ textAlign: 'justify' }}>{content.substring(0, limit) + "..."}</div>
    }
    // if (showAll) {
    //    We show the extended text and a link to reduce it
    //   return <div> 
    //     {content} 
    //     <button onClick={showLess}>Read less</button> 
    //   </div>
    // }
    //  In the final case, we show a text with ellipsis and a `Read more` button
    // const toShow = content.substring(0, limit) + "...";
    // return <div> 
    //   {toShow} 
    //   <button onClick={showMore}>Read more</button>
    // </div>
  }


  return (
    <Grid container>
      <Card sx={{ width: '380px', height: 'auto', marginBottom: '2%', marginLeft: '2%', border: '1px solid #c9cdd0', boxShadow: '0px 3px 5px 3px #c9cdd0' }}>
        <Grid container >
          <Grid container sx={{ margin: '2% 5%' }}>
            <Grid item xs={8} lg={7}>
              <Typography sx={{ fontFamily: 'poppins', marginTop: '2%' }}><b>{companyName}</b></Typography>
            </Grid>
            <Grid item xs={4} lg={5}>
              <ModalComponent heading={topic} modalcontent={discription} picture={thumbnail} pdf={guidelines} />
            </Grid>
          </Grid>

          <Grid item xs={12} sx={{ margin: '2% 5%' }}>
            <img src={thumbnail} alt='image' width="340" height="200" />
          </Grid>

          <Grid item xs={12} sx={{ marginLeft: '5%', marginBottom: '1%' }}>
            <Typography sx={{ fontFamily: 'poppins', fontSize: '18px' }}><b>{topic}</b></Typography>
          </Grid>

          <Grid item xs={12} sx={{ margin: '2% 5%', height: '130px', marginBottom: '15px' }}>
            {/* <Typography sx={{ fontFamily: 'poppins' }}>{discription}</Typography> */}
            <LongText content={discription} limit={280} />
          </Grid>

          <Grid container sx={{ margin: '4% 5%' }} >
            <Grid item xs={6}>
              <Typography sx={{ fontSize: '15px', fontFamily: 'poppins' }}><span style={{ color: '#21b2bb' }}>Deadline:</span> {deadline}</Typography>
            </Grid>

            <Grid item xs={6}>
              <Typography sx={{ fontSize: '15px', fontFamily: 'poppins' }}><span style={{ color: '#21b2bb' }}>Allowance:</span> {allowance}</Typography>
            </Grid>
          </Grid>

          <Grid item xs={12} sx={{ marginBottom: '5%' }}>
            <NavLink to={'/researchdetails'} style={{ textDecoration: 'none' }}><Button variant='contained'
              sx={{ backgroundColor: '#21b2bb', marginLeft: '5%', marginRight: '5%', width: '90%' }}

            >
              Edit Details
            </Button></NavLink>
          </Grid>
        </Grid>
      </Card>
    </Grid>
  )
}