import * as React from 'react';
import { DataGrid } from '@mui/x-data-grid';
import { Grid, Typography, IconButton, Button } from '@mui/material';
import { NavLink, useNavigate } from 'react-router-dom';
import Nomad from "./Nomad.png"
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import axios from 'axios';
import { Context } from '../../App';





export default function DataTable() {
    const navigate = useNavigate()
    const columns = [

        { field: 'jobTitle', headerName: 'Job Title', width: 200 },
        { field: 'employmentType', headerName: 'Position Name', width: 200 },
        { field: 'salary', headerName: 'Salary' },
        { field: 'Startdate', headerName: 'Date Posted' },
        { field: 'EndDate', headerName: 'Due Date' },
        { field: 'numberOfPositions', headerName: 'Empty Positions', width: 150 },
        // { field: 'applicantsnum', headerName: 'Applicants' },
        {
            field: "applicants",
            headerName: "Applicants",
            width: 150,
            sortable: false,
            renderCell: (params) => {
                const onClick = (e) => {
                    e.stopPropagation(); // don't select this row after clicking

                    return navigate("/applicants", { state: { jobId: id } });
                };

                return <Button 
                onClick={onClick}
                >
                Click to See
                </Button>;
            }
        }
    ];

    const [rows, setrows] = React.useState([]);
    const [id,setid] = React.useState([])
    
       
    
    
    
    const { authState } = React.useContext(Context)
    React.useEffect(() => {
        async function data() {
            try {
                const getdata = await axios("https://po.keewesolutions.com/jp/companyjob", {
                    method: "POST",
                    data: {
                        "companyId": authState.user.id
                    }
                }).then((response) => {setrows(response.data.Items)

                    for(let item in response.data.Items){
                        
                setid(response.data.Items[item].id)}
              
                })


            } catch (error) {
                console.log(error)
            }
        }
        data()
    }, [])


    return (
        <>
            <Grid item container marginTop={'50px'}>


                <Grid item lg={1} >

                </Grid>
                {/* <Grid lg={1} /> */}

                <Grid item xs={5} lg={2} md={3} sm={3} sx={{ paddingLeft: '1%' }}>
                    <NavLink to={'/postajob'} style={{ textDecoration: 'none' }} > <Button variant='contained'
                        sx={{
                            borderRadius: '0px', backgroundColor: '#1a50b2', marginTop: '2%',
                            '&:hover': { boxShadow: '4px 4px #e5e5e5' },
                            borderRadius: '6px'
                        }}
                    > + Post a Job</Button></NavLink>
                </Grid>
            </Grid>
            <Grid item container xs={12} display={"flex"} justifyContent={"space-between"}  padding='20px'>
                {/* <Grid item xs={12} lg={6} marginTop={'5px'}>
                    <Grid>
                        
                    </Grid>
                    <Grid>
                        <Typography sx={{ color: '#7C8493' }}>
                            {/* Here is your jobs listing status from July 19 - July 25. 
                        </Typography>
                    </Grid>
                </Grid> */}
                <Grid container item>
                    <Grid item lg={1} />
                    
                    <Grid item xs={12} lg={10} style={{ height: 400, width: '80%',marginBottom:'40px' }}>
                    <Typography
                            sx={{
                                fontFamily: "Montserrat",
                                fontSize: "30px",
                                fontStyle: "normal",
                                fontWeight: "600",
                                lineHeight: "25px",
                                letterSpacing: "0.03em",
                                textAlign: "left",
                                marginTop: "1%",
                                marginBottom:'20px'
                            }}
                        >
                            Job Listing
                        </Typography>
                        <DataGrid
                            rows={rows}
                            columns={columns}
                            pageSize={5}
                            rowsPerPageOptions={[5]}
                            checkboxSelection
                            
                        >

                        </DataGrid>
                    </Grid>
                </Grid>
            </Grid>


        </>

    );
}
