import React, { useRef } from "react";
import "./Player.css";
import { Grid,Button,Input } from "@mui/material";

import video from "./video.mp4";
import useVideoPlayer from "./Usevideoplayer";
import PlayArrowIcon from '@mui/icons-material/PlayArrow';
import PauseIcon from '@mui/icons-material/Pause';

export default function Video() {
    const videoElement = useRef(null);
    const {
      playerState,
      togglePlay,
      handleOnTimeUpdate,
      handleVideoProgress,
      handleVideoSpeed,
      toggleMute,
    } = useVideoPlayer(videoElement);
    return (
      <Grid className="container" xs={12} lg={10}> 
        <Grid className="video-wrapper">
          <video
            src={video}
            ref={videoElement}
            onTimeUpdate={handleOnTimeUpdate}
          />
          <Grid className="controls">
            <Grid className="actions">
              <Button sx={{color:'white'}} onClick={togglePlay}>
                {!playerState.isPlaying ? (
                  <i className="bx bx-play"> <PlayArrowIcon/></i>
                ) : (
                  <i className="bx bx-pause"><PauseIcon/></i>
                )}
              </Button>
            </Grid>
            <input 
              type="range"
              min="0"
              max="100"
              value={playerState.progress}
              onChange={(e) => handleVideoProgress(e)}
            />
            <select 
              className="velocity"
              value={playerState.speed}
              onChange={(e) => handleVideoSpeed(e)}
            >
              <option style={{color:'#127ffc'}} value="0.50" >0.50x</option>
              <option style={{color:'#127ffc'}} value="1" >1x</option>
              <option style={{color:'#127ffc'}} value="1.25" >1.25x</option>
              <option style={{color:'#127ffc'}} value="2" >2x</option>
            </select>
            {/* <Button  className="mute-btn" onClick={toggleMute}>
              {!playerState.isMuted ? (
                <i className="bx bxs-volume-full"></i>
              ) : (
                <i className="bx bxs-volume-mute"></i>
              )}
            </Button> */}
          </Grid>
        </Grid>
      </Grid>
    );
  };