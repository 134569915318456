import * as React from 'react';
import { styled } from '@mui/material/styles';
import {Box, Paper, Grid, Button, Avatar, Typography, AvatarGroup} from '@mui/material';
import Rocket1 from '../images/Rocket1.png';
import Networking from '../images/Networking.png';
import ArrowDropDownCircleIcon from '@mui/icons-material/ArrowDropDownCircle';
import './IncubationPo.css';


const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
  ...theme.typography.body2,
  padding: theme.spacing(1),
  textAlign: 'center',
  color: theme.palette.text.secondary,
}));

export default function IncubationPo() {
  return (
    <Box overflowX="hidden" marginTop={'20px'} >
      <Grid container spacing={2} className="incub">
        <Grid item container xs={3} sm={3} className="imggrid">
            <Grid item>
                <img src={Rocket1} alt="IdeaImg" style={{ maxWidth: '150%', position: 'relative', paddingTop: '25%' }} />
            </Grid>
        </Grid>
        <Grid item container xs={9} sm={9} className='con-text' style={{backgroundColor: '#DDF7FF', minWidth: 'min-content', padding: '8%'}}>
            <Grid item container display="flex">
                <Grid item xs={7} sm={7} md={7}>
                    <Grid item xs style={{ fontSize: '60px', fontFamily: 'Montserrat', fontWeight: '500' }} className="text1">
                        For Start-Ups Looking For Guidance & Support
                    </Grid>
                    <Grid item xs style={{ fontSize: '20px', fontFamily: 'Montserrat', fontWeight: '400', margin: '4% 0' }} className="text2">
                        If you are looking for futuristic IDEAs which can have an impact on the lives of many addressing a complex problem with a solution which will benefit the BUSSINESS & the USERS.
                        Our team with the support of our Partnered Organization will guide and support such IDEAs.
                    </Grid>
                    <Grid item xs display="flex" margin= '4% 0'>
                        <Button variant='contained' size='large' style={{ padding: '0 4%', width: '250px', height: '50px', fontWeight: '700',fontFamily: 'Montserrat', backgroundImage: 'linear-gradient(to right, #21D4FD , #B721FF)'}}>
                            Ideation
                        </Button>
                        <Button variant='contained' size='large' style={{ padding: '0 4%', marginLeft: '10%', width: '250px', height: '50px', backgroundImage: 'linear-gradient(to right, #21D4FD , #B721FF)', fontWeight: '700',fontFamily: 'Montserrat' }}>
                            Exploration
                        </Button>
                    </Grid>
                </Grid>
                <Grid item xs={7} sm={5} md={5} backgroundColor="#DDF7FF" borderRadius="0 15px 15px 0">
                    <img src={Networking} alt="Networking" style={{ maxWidth: '100%' }} />
                </Grid>
            </Grid>
            <Grid item xs style={{ display: 'inline-block', margin: '4% 0' }}>
                <ArrowDropDownCircleIcon fontSize="large" style={{ color: '#B721FF'}}/>   
                <Typography style={{display: 'flex'}}>
                <Button style={{padding: '0 2%'}}>
                        Initial Funding
                    </Button>
                    <Button style={{padding: '0 2%'}}>
                        Mentorship
                    </Button>
                    <Button style={{padding: '0 2%'}}>
                        Legal Support
                    </Button>
                    <Button style={{padding: '0 2%'}}>
                        Office Space
                    </Button>
                    <Button style={{padding: '0 2%'}}>
                        Patent & Copyrights
                    </Button>
                </Typography>
            </Grid>
        
        </Grid>
      </Grid>
    </Box>
  );
}
