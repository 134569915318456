import { useContext } from "react"
import Hheader from "../Vaibhav/Header"
import BasicInfo from "./BasicInfo"

const CompanyProfile = () => {
    return (
        <>
            <Hheader />
            <BasicInfo />
        </>
    )
}
export default CompanyProfile;