import React from 'react';
import { Typography, Card, Grid, Button, IconButton, ImageList, TextField, InputLabel, MenuItem, FormControl, Select } from '@mui/material';
import NotificationsActiveOutlinedIcon from '@mui/icons-material/NotificationsActiveOutlined';
import Incubation2 from '../images/Incubation2.png';
import { Box } from '@mui/material'

import AvatarImage from '../images/AvatarImage.png';
import IncubationCard from './IncubationCard';

import { NavLink } from "react-router-dom";
import SearchIcon from '@mui/icons-material/Search';
import FilterAltIcon from '@mui/icons-material/FilterAlt';
import NFTnew from '../images/NFTnew.jpg';
import Drone1 from '../images/Drone1.jpg';
import Plant1 from '../images/Plant1.jpg'
import GoToTop from '../../../Components/Gototop';


export default function HomePage2() {
 
  const [filter, setfilter] = React.useState("MOst Viewed");

const handleChange = (event) => {
  setfilter(event.target.value);}

    
   
  

    return (
      <Grid container>
        <Grid container sx={{ margin: '2% 4%' }}>
          <Grid item xs={12} lg={9}>
            <Typography variant='h4'><b>Incubation</b></Typography>
          </Grid>

          <Grid item xs={7} lg={2}>
            <NavLink to={'/incubhome'} style={{ textDecoration: 'none' }}> <Button variant='outlined' sx={{ borderRadius: '0px' }}>Back to homepage</Button></NavLink>
          </Grid>

          <Grid item xs={3} lg={1}>
            <IconButton>
              <NotificationsActiveOutlinedIcon />
            </IconButton>
          </Grid>
        </Grid>

        <div style={{ position: "relative", width: "100%", height: '400px' }}>
          <img
            src={Incubation2}
            alt="imagesa"
            style={{ width: "100%", height: '400px', position: "absolute" }}
          />
        </div>

        <Grid item xs={12} margin='2%'>
          <Typography variant='h4'><b>All Ideas</b></Typography>
        </Grid>
        <Grid>
        <ImageList className='scroll' sx={{ display: "flex", marginTop: '50px' }} >
            <IncubationCard />
           
          </ImageList>
        </Grid>
        <GoToTop/>
      </Grid>
    )
  }