import * as React from 'react';
import { styled } from '@mui/material/styles';
import {Box, Paper, Grid, Button, Typography, Link} from '@mui/material';
import SendingMessage from '../images/SendingMessage.png';
import ResearchImg from '../images/ResearchImg.png';
import ArrowDropDownCircleIcon from '@mui/icons-material/ArrowDropDownCircle';
import './IncubationPo.css';


const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
  ...theme.typography.body2,
  padding: theme.spacing(1),
  textAlign: 'center',
  color: theme.palette.text.secondary,
}));

export default function ResearchPo() {
  return (
    <Box overflowX="hidden" marginTop={'20px'} >
      <Grid container spacing={2} className="incub">
        <Grid item xs={3} sm={3} className="imggrid">
            <Grid item>
                <img src={SendingMessage} alt="IdeaImg" style={{ maxWidth: '150%', position: 'relative' }} />
            </Grid>
        </Grid>
        <Grid item xs={6} sm={7} className='con-text' style={{backgroundColor: '#DDF7FF', minWidth: 'min-content', padding: '8%', borderRadius: '15px 0 0 15px'}}>
            <Grid item xs style={{ fontSize: '60px', fontFamily: 'Montserrat', fontWeight: '500' }} className="text1">
                Research
            </Grid>
            <Grid item xs style={{ fontSize: '20px', fontFamily: 'Montserrat', fontWeight: '400', margin: '4% 0' }} className="text2">
                At the most promising Research Projects receive funding from our Partnered Organizations. We make sure all our Research Projects are futuristic and are solving problems for the current generation.
            </Grid>
            
            <Grid item xs style={{ display: 'inline-block', margin: '4% 0' }}>
                <ArrowDropDownCircleIcon fontSize="large" style={{ color: '#B721FF'}}/>
                <div style={{display: 'flex', justifyContent: 'space-around'}}>
                    <Link style={{ padding: '5% 5% 0 0', textDecoration: 'none', width: "-webkit-fill-available" }}>
                        Initial Funding
                    </Link>
                    <Link style={{ padding: '5% 5% 0 0', textDecoration: 'none' }}>
                        Mentorship
                    </Link>
                    <Link style={{ padding: '5% 5% 0 0', textDecoration: 'none' }}>
                        Research Papers
                    </Link>
                    <Link style={{ padding: '5% 5% 0 0', textDecoration: 'none' }}>
                        Research Articles
                    </Link>
                </div>
            </Grid>
        </Grid>
        <Grid item xs={3} sm={2} backgroundColor="#DDF7FF" borderRadius="0 15px 15px 0">
            <img src={ResearchImg} alt="ResearchImg" style={{ maxWidth: '160%', position: 'relative', top: '15%', left: '-50%' }} />
        </Grid>
      </Grid>
    </Box>
  );
}
