import * as React from "react";
import { useEffect, useContext } from 'react';
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import IconButton from "@mui/material/IconButton";
import MenuIcon from "@mui/icons-material/Menu";
import NotificationsNoneIcon from '@mui/icons-material/NotificationsNone';
import AccountCircleOutlinedIcon from '@mui/icons-material/AccountCircleOutlined';
import MenuItem from "@mui/material/MenuItem";
import Avatar from '@mui/material/Avatar';
import Menu from "@mui/material/Menu";
import Stack from '@mui/material/Stack';
import Button from '@mui/material/Button';
import KeeweLogo from "./KeeweLogo.png";
import left from '../VikramForms/mrsptu.png';
import { useNavigate, NavLink } from 'react-router-dom'
import { Context } from "../App";
import { Grid } from "@mui/material";




const Hheader = () => {
  const { authState } = useContext(Context)
  const navigate = useNavigate();



  const [anchorEl, setAnchorEl] = React.useState(null);
  const [Profile, setProfile] = React.useState(null);

  const handleCloseProfile = () => {
    setProfile(null)
  }

  const handleprofile = (event) => {
    setProfile(event.currentTarget);
  };
  const handleMenu = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const LogOut = () => {
    localStorage.removeItem("keewe.PO");
    navigate('/login');
    location.reload();
  }

  const handleProfile=async ()=>{
    navigate("/companyprofile")
  }

  return (
    <Box sx={{ flexGrow: 1 }}>
      {/* <Grid Contanier lg={12} xs={12}>
        <Grid lg={12} xs={12}> */}
          <AppBar position="static" sx={{ backgroundColor: "#ffffff ", height: "70px",boxShadow:'10px 0px 0px 0px gray' }}>
            <Toolbar>
              <Box sx={{
                height: "60px",
                flexGrow: 0.75,display:'flex'
              }}>
                <img src={left} alt=""  style={{ width: '70px', height: '70px' }} />
                <Typography sx={{color:'#001A49',marginTop:'15px',fontSize:'25px',lineHeight:'1'}}>Partnered<br/> Organization</Typography>
              </Box>
              
              {/* <div style={{display: 'block'}}>
            <div style={{ flexGrow: 0.5, color: "#001A49", fontFamily: 'Playfair Display', fontWeight: '400', fontSize: '28px', lineHeight: '37px', marginLeft: '8px' }}>
              KEEWE
            </div>
            <div style={{ fontSize: '9px', color: '#001A49', marginLeft: '9%', marginTop:'-5px' }}>Powered by Crestbell</div>
          </div> */}
              {/* <div style={{ marginTop: '25px' }}> */}
                <Stack spacing={4} direction="row"
                  sx={{
                    display: {
                      lg: 'flex',
                      xs: "none"
                    },
                    marginTop: '0.7%'
                  }}><NavLink to={'/Dashboard'} style={{ textDecoration: 'none' }}>
                    <Typography sx={{
                      background: "#ffffff",
                      color: "#001A49",
                      textTransform: "capitalize",
                      fontFamily: 'Merriweather',
                      fontWeight: '400',
                      fontSize: '18px',
                      lineHeight: '23px',
                      marginLeft: "300px",
                      ':hover': {
                        background: "none ",
                        color: "#1a50b2"


                      }
                    }
                    } > Dashboard</Typography></NavLink>
                  <NavLink to={'/Jobportal'} style={{ textDecoration: 'none' }}><Typography sx={{
                    background: "#ffffff",
                    color: "#001A49",
                    textTransform: "capitalize",
                    fontFamily: 'Merriweather',
                    fontWeight: '400',
                    fontSize: '18px',
                    lineHeight: '23px',
                    '&:hover': {
                      background: "none ",
                      color: "#1a50b2"
                    }
                  }
                  } >JobPortal</Typography></NavLink>
                  <NavLink to={'/CMS'} style={{ textDecoration: 'none' }}><Typography sx={{
                    background: "#ffffff",
                    color: "#001A49",
                    textTransform: "capitalize",
                    fontFamily: 'Merriweather',
                    fontWeight: '400',
                    fontSize: '18px',
                    lineHeight: '23px',
                    '&:hover': {
                      background: "none ",
                      color: "#1a50b2"
                    }
                  }
                  } >CreateStudio</Typography></NavLink>
                  <NavLink to={'/Incubation'} style={{ textDecoration: 'none' }}> <Typography sx={{
                    background: "#ffffff",
                    color: "#001A49",
                    textTransform: "capitalize",
                    fontFamily: 'Merriweather',
                    fontWeight: '400',
                    fontSize: '18px',
                    lineHeight: '23px',
                    '&:hover': {
                      background: "none  ",
                      color: "#1a50b2"
                    }
                  }
                  } >Incubation</Typography></NavLink>
                  <NavLink to={'/Research'} style={{ textDecoration: 'none' }}><Typography sx={{
                    background: "#ffffff",
                    color: "#001A49",
                    textTransform: "capitalize",
                    fontFamily: 'Merriweather',
                    fontWeight: '400',
                    fontSize: '18px',
                    lineHeight: '23px',
                    '&:hover': {
                      background: "none ",
                      color: "#1a50b2"
                    }
                  }
                  } >Research</Typography></NavLink>

                  <IconButton

                    size="small"
                    aria-label="account of current user"
                    aria-controls="menu-appbar"
                    aria-haspopup="true"
                    onClick={handleprofile}
                    color="inherit"
                    sx={{
                      '&:hover': {
                        // background: "none ",
                      },
                      color: '#001A49'
                    }}
                  >

                    {/* <Avatar alt=""
                      src={left}
                      sx={{ width: 56, height: 56 }}
                    /> */}
                    <MenuIcon sx={{marginTop: '-6px'}} />

                  </IconButton >
                </Stack>

              {/* </div> */}
              {/* <span style={{ justifyContent: 'center', alignItems: 'flex-end', marginLeft: '3%' }}> */}
                
              {/* </span> */}

              {/* <div style={{}}> */}
                <IconButton
                  size="large"
                  edge="start"
                  color="inherit"
                  ar
                  ia-label="menu"
                  sx={{
                    // marginBottom: '8px',
                    display: {
                      lg: "none",
                      xs: "block"
                    }
                  }}
                  onClick={handleMenu}

                >
                  <MenuIcon sx={{ color: "#001A49" }} />
                </IconButton>
                <Menu
                  id="menu-appbar"
                  anchorEl={Profile}
                  anchorOrigin={{
                    vertical: "bottom",
                    horizontal: "left"
                  }}
                  keepMounted
                  transformOrigin={{
                    vertical: "top",
                    horizontal: "center "
                  }}
                  open={Boolean(Profile)}
                  onClose={handleCloseProfile}
                >
                  <MenuItem sx={{ color: '#001A49'}} onClick={handleProfile}>Profile</MenuItem>
                  {/* <MenuItem onClick={handleClose} sx={{ color: '#001A49'}}>My account</MenuItem> */}
                  <MenuItem onClick={LogOut} sx={{ color: '#001A49'}}>LogOut</MenuItem>
                </Menu>
                <Menu


                  id="menu-appbar"
                  anchorEl={anchorEl}
                  anchorOrigin={{
                    vertical: "bottom",
                    horizontal: "left"
                  }}
                  keepMounted
                  transformOrigin={{
                    vertical: "top",
                    horizontal: "right"
                  }}
                  open={Boolean(anchorEl)}
                  onClose={handleClose}
                >

                  <NavLink to={'/Dashboard'} style={{ textDecoration: 'none', color: '#001A49' }}>
                    <MenuItem onClick={handleClose}>Dashboard</MenuItem>
                  </NavLink>
                  <NavLink to={'/Incubation'} style={{ textDecoration: 'none', color: '#001A49' }}>
                    <MenuItem onClick={handleClose}>Incubation</MenuItem>
                  </NavLink>
                  <NavLink to={'/Research'} style={{ textDecoration: 'none', color: '#001A49' }}>
                    <MenuItem onClick={handleClose}>Research</MenuItem>
                  </NavLink>
                  <NavLink to={'/CMS'} style={{ textDecoration: 'none', color: '#001A49' }}>
                    <MenuItem onClick={handleClose}>Create Studio</MenuItem>
                  </NavLink>
                  <NavLink to={'/Jobportal'} style={{ textDecoration: 'none', color: '#001A49' }}>
                    <MenuItem onClick={handleClose}>Job Portal</MenuItem>
                  </NavLink>
                  <NavLink to={'/companyprofile'} style={{ textDecoration: 'none', color: '#001A49' }}>
                  <MenuItem onClick={handleClose} sx={{ color: '#001A49'}}>Profile</MenuItem>
                  </NavLink>
                  <MenuItem onClick={handleClose} sx={{ color: '#001A49'}}>My account</MenuItem>
                  <MenuItem onClick={LogOut} sx={{ color: '#001A49'}}>LogOut</MenuItem>
                </Menu>

                {/* <IconButton

                >
                  <NotificationsNoneIcon fontSize="large" sx={{ marginLeft: '5px', color: '#001A49'}} />
                </IconButton> */}
              {/* </div> */}
            </Toolbar>
          </AppBar>
        {/* </Grid>
      </Grid > */}
    </Box>
  );
}

export default Hheader;
