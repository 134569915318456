import { Grid, Box, Typography, Divider, Button, Link } from '@mui/material';
import React from 'react';
import man from './harjit.png';
import GradeIcon from '@mui/icons-material/Grade';
import { styled } from '@mui/material/styles';
import LinearProgress, { linearProgressClasses } from '@mui/material/LinearProgress';
import ChatIcon from '@mui/icons-material/Chat';
import MailOutlineIcon from '@mui/icons-material/MailOutline';
import PhoneIphoneIcon from '@mui/icons-material/PhoneIphone';
import InstagramIcon from '@mui/icons-material/Instagram';
import TwitterIcon from '@mui/icons-material/Twitter';
import LanguageIcon from '@mui/icons-material/Language';
import FiberManualRecordIcon from '@mui/icons-material/FiberManualRecord';
import PropTypes from 'prop-types';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';



const BorderLinearProgress = styled(LinearProgress)(({ theme }) => ({
  height: 10,

  [`&.${linearProgressClasses.colorPrimary}`]: {
    backgroundColor: theme.palette.grey[theme.palette.mode === 'light' ? 200 : 800],
  },
  [`& .${linearProgressClasses.bar}`]: {

    backgroundColor: theme.palette.mode === 'light' ? '#1a90ff' : '#308fe8',
  },
}));
const bull = (
  <Box
    component="span"
    sx={{ display: 'inline-block', mx: '10px', transform: 'scale(2)' }}
  >
    •
  </Box>
);
const Table = ({ data, userId }) => {
  console.log(userId)
  const arr = [
    {
      imgsrc: man,
      name: 'Harjeet Singh',
      profile: 'Wb Developer',
      rating: '4.0',
      daysago: '2 days ago',
      branch: 'Web Development',
      skill1: 'React',
      skill2: 'Full-Time',
      email: 'Harjeet SIngh@email.com',
      phone: '+91 854528252',
      insta: 'instagram.com/Hari',
      twitter: 'twitter.com/Hari',
      website: 'www.Harjeetsingh.com'
    },

  ];
  return (
    <Grid xs={12}>
      {data?.map((item, index) => {


        if (
          Boolean(userId.find((iteme) => iteme === item.userId))
        ) {
          return (

            <Box sx={{
              width: '369px',
              height: '961px', border: '1px solid #D6DDEB', padding: '4%'
            }}>

              <>
                <Grid display={"flex"}>
                  <img src={item.imageFile} alt="" width={'120px'} />
                  <Grid sx={{ width: '58%' }}>
                    <Typography sx={{
                      fontFamily: 'Clash Display',
                      fontWeight: '600',
                      fontSize: '26px',
                      lineHeight: '120%',
                      color: '#25324B',
                      marginTop: '1%', marginLeft: '26%', cursor: 'context-menu'
                    }}>
                      {item.fname} {item.lname}
                    </Typography>
                    <Grid display={"flex"}>
                      {/* <GradeIcon sx={{
                        color: '#FFB836', marginLeft: '24%',
                        marginTop: '3%'
                      }} />
                      <Typography sx={{
                        fontFamily: 'Epilogue',
                        fontWeight: '500',
                        fontSize: '20px',
                        lineHeight: '160%',
                        color: '#25324B', marginTop: '2%', marginLeft: '4%', cursor: 'context-menu'
                      }}>
                        {/* {item.rating}  4.0
                      </Typography> */}
                    </Grid>

                  </Grid>


                </Grid>
                <Box sx={{
                  backgroundColor: '#F8F8FD', width: '351px',
                  height: '121px', marginTop: '6%', padding: '2.6%'
                }}>
                  <Grid display={"flex"} sx={{ justifyContent: 'space-between' }}>
                    <Typography sx={{
                      fontFamily: 'Epilogue',
                      fontWeight: '400',
                      fontSize: '18px',
                      lineHeight: '160%',
                      color: '#25324B', cursor: 'context-menu'
                    }}>
                      Applied On
                    </Typography>
                    <Typography sx={{
                      fontFamily: 'Epilogue',
                      fontWeight: '400',
                      fontSize: '18px',
                      lineHeight: '160%',
                      color: '#7C8493', cursor: 'context-menu'
                    }}>
                      {item.Submitdate}
                    </Typography>
                  </Grid>
                  <Box sx={{
                  backgroundColor: '#F8F8FD', width: '351px',
                  height: '66px', marginTop: '4%', padding: '2.6%'
                }}>
                  <Grid display={"flex"} sx={{ justifyContent: 'space-between' }}>
                    <Typography sx={{
                      fontFamily: 'Epilogue',
                      fontWeight: '400',
                      fontSize: '18px',
                      lineHeight: '160%',
                      color: '#25324B', cursor: 'context-menu'
                    }}>
                      Stage
                    </Typography>
                    <Typography sx={{
                      fontFamily: 'Epilogue',
                      fontWeight: '400',
                      fontSize: '18px',
                      lineHeight: '160%',
                      color: '#26A4FF', cursor: 'context-menu'
                    }}>
                      {bull}Interview
                    </Typography>
                  </Grid>
                  <BorderLinearProgress variant="determinate" value={50} sx={{ marginTop: '4%' }} />
                </Box>
                  <Divider sx={{ marginTop: '4%' }} />
                  <Typography sx={{
                    fontFamily: 'Epilogue',
                    fontWeight: '600',
                    fontSize: '22px',
                    lineHeight: '160%',
                    color: '#25324B', marginTop: "2%", cursor: 'context-menu'
                  }}>
                    {item.headline}
                  </Typography>
                 
                </Box>
                
               
                <Divider sx={{ marginTop: '4%' }} />
                <Grid marginTop={'35px'}>
                    {item.SkillArr?.map((skill) => {
                      return (
                        <div style={{display:'flex'}}>
                          < FiberManualRecordIcon sx={{ fontSize: '0.8rem', color: '#515B6F', margin: '2%' }} />
                          <Typography sx={{
                            fontFamily: 'Epilogue',
                            fontWeight: '400',
                            fontSize: '18px',
                            lineHeight: '160%',
                            color: '#515B6F', cursor: 'context-menu'
                          }}>
                            {skill.title}
                          </Typography>
                        </div>
                      )
                    })}



                  </Grid>

                <Typography sx={{
                  fontFamily: 'Epilogue',
                  fontWeight: '600',
                  fontSize: '24px',
                  lineHeight: '120%',
                  color: '#25324B', marginTop: '3%', cursor: 'context-menu'
                }}>
                  Contact
                </Typography>
                <Grid display={"flex"} sx={{ marginTop: '4%' }}>
                  <MailOutlineIcon sx={{ color: '#7C8493', fontSize: '2rem' }} />
                  <Grid sx={{ marginLeft: '6%' }}>
                    <Typography sx={{
                      fontFamily: 'Epilogue',
                      fontWeight: '400',
                      fontSize: '20px',
                      lineHeight: '160%',
                      color: '#7C8493', cursor: 'context-menu'
                    }}>
                      Email
                    </Typography>
                    <Typography sx={{
                      fontFamily: 'Epilogue',
                      fontWeight: '400',
                      fontSize: '18px',
                      lineHeight: '160%',
                      color: '#25324B', cursor: 'context-menu'
                    }}>
                      {item.email}
                    </Typography>
                  </Grid>

                </Grid>
                <Grid display={"flex"} sx={{ marginTop: '4%' }}>
                  <PhoneIphoneIcon sx={{ color: '#7C8493', fontSize: '2rem' }} />
                  <Grid sx={{ marginLeft: '6%' }}>
                    <Typography sx={{
                      fontFamily: 'Epilogue',
                      fontWeight: '400',
                      fontSize: '20px',
                      lineHeight: '160%',
                      color: '#7C8493', cursor: 'context-menu'
                    }}>
                      Phone
                    </Typography>
                    <Typography sx={{
                      fontFamily: 'Epilogue',
                      fontWeight: '400',
                      fontSize: '18px',
                      lineHeight: '160%',
                      color: '#25324B', cursor: 'context-menu'
                    }}>
                      {item.phone}
                    </Typography>
                  </Grid>

                {/* </Grid>
                <Grid display={"flex"} sx={{ marginTop: '4%' }}>
                  <InstagramIcon sx={{ color: '#7C8493', fontSize: '2rem' }} />
                  <Grid sx={{ marginLeft: '6%' }}>
                    <Typography sx={{
                      fontFamily: 'Epilogue',
                      fontWeight: '400',
                      fontSize: '20px',
                      lineHeight: '160%',
                      color: '#7C8493', cursor: 'context-menu'
                    }}>
                      Instagram
                    </Typography>
                    <Link href={'https://www.google.com'} style={{
                      fontFamily: 'Epilogue',
                      fontWeight: '400',
                      fontSize: '18px',
                      lineHeight: '160%',
                      color: '#4640DE', cursor: "pointer", textDecoration: 'none'
                    }}>
                      {item.insta}
                    </Link>
                  </Grid>

                </Grid>
                <Grid display={"flex"} sx={{ marginTop: '4%' }}>
                  <TwitterIcon sx={{ color: '#7C8493', fontSize: '2rem' }} />
                  <Grid sx={{ marginLeft: '6%' }}>
                    <Typography sx={{
                      fontFamily: 'Epilogue',
                      fontWeight: '400',
                      fontSize: '20px',
                      lineHeight: '160%',
                      color: '#7C8493', cursor: 'context-menu'
                    }}>
                      Twitter
                    </Typography>
                    <Link href={'https://www.google.com'} sx={{
                      fontFamily: 'Epilogue',
                      fontWeight: '400',
                      fontSize: '18px',
                      lineHeight: '160%',
                      color: '#4640DE', cursor: "pointer", textDecoration: 'none'
                    }}>
                      {item.twitter}
                    </Link>
                  </Grid>

                </Grid>
                <Grid display={"flex"} sx={{ marginTop: '4%' }}>
                  <LanguageIcon sx={{ color: '#7C8493', fontSize: '2rem' }} />
                  <Grid sx={{ marginLeft: '6%' }}>
                    <Typography sx={{
                      fontFamily: 'Epilogue',
                      fontWeight: '400',
                      fontSize: '20px',
                      lineHeight: '160%',
                      color: '#7C8493', cursor: 'context-menu'
                    }}>
                      Website
                    </Typography>
                    <Link href={'https://www.google.com'} sx={{
                      fontFamily: 'Epilogue',
                      fontWeight: '400',
                      fontSize: '18px',
                      lineHeight: '160%',
                      color: '#4640DE', cursor: "pointer", textDecoration: 'none'
                    }}>
                      {item.website}
                    </Link>
                  </Grid>
                  */}
                </Grid> 
              </>


            </Box>
          )
        }

      })} 
      </Grid>
  );
};

export default Table