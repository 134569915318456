import React from 'react';
import {Typography, Grid, Button, IconButton, Box} from '@mui/material';
import NotificationsActiveOutlinedIcon from '@mui/icons-material/NotificationsActiveOutlined';
import ArrowBackOutlinedIcon from '@mui/icons-material/ArrowBackOutlined';
import JobInfo from '../images/JobInfo.png';
import JobDesc from '../images/JobDesc.png';
import Perks from '../images/Perks.png';
import Divider from '../images/Divider.png'
import JobCardComponent from './JobCardComponent';
import Healthcare from '../images/Healthcare.png';
import Travel from "../images/Travel.png";

import styled from "@emotion/styled"
import { NavLink, useNavigate } from 'react-router-dom';
import Nomad from './Nomad.png';
import Step3 from '../NehaBhagat1/Step3';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import GoToTop from '../../Components/Gototop';
import NewSyaapa from '../NehaBhagat1/NewSyaapa';

const Typography1 = styled(Typography)({
  fontFamily:'inter'
})


export default function JobInformation(){
   
  const navigate = useNavigate()
    return(
    <>
  

        <Grid >
        
          

           <Step3/>

     
        
         <GoToTop/>
          
     
        </Grid>
          </>
    );
}