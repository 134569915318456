import React, { useContext, useEffect, useState } from "react";
import { Typography, Grid, Card, TextField, Button, styled } from '@mui/material';
import { useTheme } from "@mui/material/styles";
import OutlinedInput from "@mui/material/OutlinedInput";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import Stack from '@mui/material/Stack';
import imgs from './images/Previews.jpg';
import DesktopDatePicker from '@mui/lab/DesktopDatePicker';
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import axios from 'axios';
import { Context } from "../App";


const Typography1 = styled(Typography)({
    fontFamily: 'Inter',
    marginBottom: '10px'

})

const names = ['Computers', 'Mechanical', 'Management', 'Finance', 'Electrical', 'Textile', 'Sales', 'Electronics', 'Agriculture', 'HR', 'Engineering', 'Marketing', 'Animal Husbandary',"others"];

export default function ResearchDetails({ buttonText1, buttonText2 }) {

    const { authState } = useContext(Context);
    const User = authState.user;
    console.log(User)


    const [fileName, setFileName] = useState('')
    const [topic, setTopic] = useState('')
    const [discription, setDiscription] = useState('')
    const [category, setCategory] = useState('')
    const [allowance, setAllowance] = useState('')
    const [deadline, setDeadline] = useState('')
    const [imageUrl, setImageUrl] = useState(null)
    const [fileObj, setFileObj] = useState(null)
    const [pdf, setPdf] = useState('')
    const [courseImageFile, setCourseImageFile] = useState("");


    const submit = () => {
        console.log("yha tak theek hai")
        var Data = new FormData();
        Data.append('rimage', courseImageFile);
        Data.append('pdf', pdf);
        Data.append('companyId', User.id);
        Data.append('companyName', User.name);
        Data.append('topic', topic);
        Data.append('discription', discription);
        Data.append('category', category);
        Data.append('deadline', deadline);
        Data.append('allowance', allowance);
        axios({
            url: 'https://po.keewesolutions.com/research/researchdetails',
            method: 'post',
            data: Data,
        })
    }


    const [value, setValue] = React.useState(new Date(''));
    const handleChange = (newValue) => {
        setValue(newValue);
    };


    return (
        <Grid container>
            <Grid item xs={12} lg={12}
                sx={{ textAlign: 'center', marginY: '1%' }}
            >
                <Typography variant='h3'><span style={{ color: '#1a50b2' }}>Research</span> Details</Typography>
            </Grid>
            <Card sx={{
                margin: '0% 10%',
                padding: '2% 10%'

            }}>
                <Grid container >
                    <Grid item xs={12} lg={6}>
                        <Typography1><b>Topic of Research*</b></Typography1>
                        <TextField
                            onChange={(e) => {
                                setTopic(e.target.value)
                            }}
                            label='Topic of Research'
                            variant='outlined'
                            value={topic}
                            sx={{ marginBottom: '20px', width: '90%' }}
                        />
                        {/* <span color="red">{errorMessage}</span> */}
                    </Grid>

                    <Grid item xs={12} lg={6}>
                        <Typography1><b>Category of Research*</b></Typography1>
                        <FormControl
                            sx={{ width: '90%', marginBottom: "3%" }}
                        >
                            <Select

                                displayEmpty
                                value={category}
                                onChange={e => setCategory(e.target.value)}
                                input={<OutlinedInput />}
                                MenuProps={MenuProps}
                                inputProps={{ "aria-label": "Without label" }}
                            >
                                <MenuItem disabled value="">
                                </MenuItem>
                                {names.map((name) => (
                                    <MenuItem
                                        key={name}
                                        value={name}
                                    >
                                        {name}
                                    </MenuItem>
                                ))}
                            </Select>
                        </FormControl>
                    </Grid>

                    <Grid item xs={12} lg={6}>


                        <Typography1><b> Research Allowance*</b></Typography1>
                        <TextField
                            onChange={e =>
                                setAllowance(e.target.value)
                            }
                            id='outlined-basic'
                            label='Research Allowance'
                            variant='outlined'
                            value={allowance}
                            sx={{ marginBottom: '20px', width: '90%' }}
                        />

                    </Grid>

                    <Grid item xs={12} lg={6}>
                        <Typography1><b>Deadline for Research*</b></Typography1>
                        <LocalizationProvider dateAdapter={AdapterDateFns}  >
                            <DesktopDatePicker
                                label="Deadline for Research"
                                inputFormat="MM/dd/yyyy"
                                value={deadline}
                                onChange={e => setDeadline(e.getDate() +
                                    "/" +
                                    e.getMonth() +
                                    "/" +
                                    e.getFullYear())}
                                renderInput={(params) => <TextField {...params} sx={{ marginBottom: '20px', width: '90%' }} />}
                            />
                        </LocalizationProvider>
                        {/* <TextField
                            onChange={e =>
                                setDeadline(e.target.value)
                            }
                            id='outlined-basic'
                            label='Last date for submission of Research'
                            variant='outlined'
                            value={deadline}
                            sx={{ marginBottom: '20px', width: '90%' }}
                        /> */}

                    </Grid>
                    <Grid item xs={12} lg={6}>
                        <Typography1><b>Details of Research Topic*</b></Typography1>
                        <TextField
                            onChange={e =>
                                setDiscription(e.target.value)
                            }
                            // id='outlined-basic'
                            // label='Details of Research Topic'
                            // variant='outlined'
                            value={discription}
                            limit={80}
                            sx={{ marginBottom: '20px', width: '90%' }}
                            multiline
                            rows={6}
                        />
                    </Grid>

                    <Grid item xs={12} lg={6} >
                        <div style={{ marginBottom: "20px", marginTop: "30px" }}>
                            <Typography fontSize={"16px"} fontFamily={'Inter'}>
                                <b> Research Image*</b>
                            </Typography>
                            {
                                imageUrl === null ?
                                    (
                                        <img src={imgs}
                                            // alt="asdf"
                                            style={{
                                                width: "250px",
                                                height: "180px",
                                                objectFit: "contain"
                                            }} />
                                    )
                                    :
                                    (
                                        <img src={imageUrl}
                                            // alt="asdf"
                                            style={{
                                                width: "250px",
                                                height: "180px",
                                                objectFit: "contain"
                                            }} />
                                    )
                            }
                        </div>
                    </Grid>
                    <Grid lg={6} xs={12}>
                        <Typography1><b>Guidelines and Complete Details of Research*</b></Typography1>
                        <Stack direction="column" spacing={6}>
                            {fileName}
                            <label>
                                <Input accept=".pdf, application/pdf" multiple type="file" onChange={e => {
                                    setPdf(e.target.files[0]);
                                    setFileName(e.target.files[0].name)

                                }} />
                                <Button variant="contained" component="span" sx={{ marginBottom: '20px', backgroundColor: '#1a50b2', }}>
                                    {buttonText2}
                                </Button>
                            </label>
                        </Stack>





                    </Grid>

                    <Grid item xs={12} lg={6}>

                        <div>
                            <label>
                                <input accept="image/*" type="file" style={{
                                    display: 'none'
                                }}
                                    onChange={e => {
                                        setImageUrl(URL.createObjectURL(e.target.files[0]))
                                        setCourseImageFile(e.target.files[0])
                                    }}

                                />
                                <Button

                                    onClick={() => {
                                        console.log(imageUrl)
                                        // sx={{
                                        //     marginTop: "10px",
                                        //     padding: "12px",
                                        //     borderRadius: "6px",
                                        //     backgroundColor: '#1a50b2',
                                        // }}

                                    }}
                                    // disabled={courseImageFile != null ? true : false}
                                    // sx={{
                                    //     marginTop: "10px",
                                    //     padding: "12px",
                                    //     borderRadius: "6px",
                                    //     backgroundColor: '#1a50b2',
                                    // }}
                                    variant="contained"
                                    component="span"
                                    sx={{ backgroundColor: '#1a50b2' }}
                                >
                                    <Typography>Upload</Typography>

                                </Button>
                            </label>
                        </div>
                    </Grid>

                    <Grid item xs={12} lg={12} sx={{ display: 'flex', justifyContent: 'center', marginTop: "5px" }}>
                        <Button
                            variant='contained'
                            sx={{ backgroundColor: '#1a50b2', }}
                            onClick={submit}
                        >
                            Save
                        </Button>
                    </Grid>
                </Grid>
            </Card>
        </Grid>
    )
}


// Select component

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
    PaperProps: {
        style: {
            maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
            width: 250,
        },
    },
};



// Upload Button

const Input = styled('input')({
    display: 'none',
});
