import * as React from 'react';
import { styled } from '@mui/material/styles';
import {Box, Paper, Grid, Link, Button} from '@mui/material';
import GotoWork from '../images/GotoWork.png';
import Briefcase from '../images/Briefcase.png';
import ArrowDropDownCircleIcon from '@mui/icons-material/ArrowDropDownCircle';
import './IncubationPo.css';


const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
  ...theme.typography.body2,
  padding: theme.spacing(1),
  textAlign: 'center',
  color: theme.palette.text.secondary,
}));

export default function PlacementPo() {
  return (
    <Box overflowX="hidden" marginTop={'20px'}>
      <Grid container spacing={2} className="incub">
        <Grid item xs={3} sm={3} className="imggrid">
            <Grid item>
                <img src={GotoWork} alt="IdeaImg" style={{ maxWidth: '150%', position: 'relative' }} />
            </Grid>
        </Grid>
        <Grid item xs={6} sm={7} className='con-text' style={{backgroundColor: '#DDF7FF', minWidth: 'min-content', padding: '8%', borderRadius: '15px 0 0 15px'}}>
            <Grid item xs style={{ fontSize: '60px', fontFamily: 'Montserrat', fontWeight: '500' }} className="text1">
                Placement
            </Grid>
            <Grid item xs style={{ fontSize: '20px', fontFamily: 'Montserrat', fontWeight: '400', margin: '4% 0' }} className="text2">
                University with best Placements <br />
                Multi-Nationals visiting MRSPTU Campus in 2019-20, the number of offers have touched 7500+ and the highest package has registerd a growth of 30%.
            </Grid>
            
            <Grid item xs style={{ display: 'inline-block', margin: '4% 0' }}>
                <div className="arrow" style={{display: "flex", justifyContent: "space-between"}}>
                    <ArrowDropDownCircleIcon fontSize="large" style={{ color: '#B721FF'}}/>
                    <Button variant='contained' size='large' style={{ padding: '0 4%', width: '250px', height: '50px', fontWeight: '700',fontFamily: 'Montserrat', backgroundImage: 'linear-gradient(to right, #21D4FD , #B721FF)'}}>
                        MyOpenCase
                    </Button>
                </div>
                <div style={{display: 'flex', justifyContent: 'space-around'}}>
                    <Link style={{ padding: '5% 5% 0 0', textDecoration: 'none', width: "-webkit-fill-available" }}>
                        Placement Records
                    </Link>
                    <Link style={{ padding: '5% 5% 0 0', textDecoration: 'none' }}>
                        CVs
                    </Link>
                    <Link style={{ padding: '5% 5% 0 0', textDecoration: 'none' }}>
                        Plan an Interview
                    </Link>
                    <Link style={{ padding: '5% 5% 0 0', textDecoration: 'none' }}>
                        Job Posting
                    </Link>
                    <Link style={{ padding: '5% 5% 0 0', textDecoration: 'none' }}>
                        Apprenticeship
                    </Link>
                </div>
            </Grid>
        </Grid>
        <Grid item xs={3} sm={2} backgroundColor="#DDF7FF" borderRadius="0 15px 15px 0">
            <img src={Briefcase} alt="Briefcase" style={{ maxWidth: '160%', position: 'relative', top: '15%', left: '-60%' }} />
        </Grid>
      </Grid>
    </Box>
  );
}
