import React, { useState } from 'react';
import { Button } from '@mui/material';
import Box from '@mui/material/Box';
import FormControl from '@mui/material/FormControl';



function CategoryDrop() {

    const [category, setCategory] = useState('');

    const handleChange = (e) => {
        setCategory(e.target.value);
    }

    const categoryArray = [
        { id: 'COMP', name: 'Computers', subCategoryArray: [{id:'ROB', name: 'Robotics'},{id:'RN', name: 'React Native'}, {id:'DV', name: 'Data Visualization'},{id:'RJ', name: 'React JS'},{id:'DO', name: 'Dev Ops'},{id:'PY', name: 'Python'},{id:'FSD', name: 'Full Stack Development'},{id:'AD', name: 'App Development'}, {id:'CC', name: 'Cloud Computing'}] },
        { id: 'MECH', name: 'Mechanical', subCategoryArray: [{id:'ROB', name: 'Robotics'}, {id:'AI', name: 'AI'}] },
        { id: 'MGMT', name: 'Management' },
        { id: 'FIN', name: 'Finance' },
        { id: 'EEE', name: 'Electrical', subCategoryArray: [{id:'PCB', name: 'PCB Design'}, {id:'MAT', name: 'Matlab'}, {id:'ARD', name: 'Arduino'}, {id:'DRN', name: 'Drones'}, {id:'ACAD', name: 'Auto CAD'}, {id:'MC', name: 'Micro Controllers'}, {id:'MP', name: 'Micro Processors'}] },
        { id: 'TXTL', name: 'Textile' },
        { id: 'SALE', name: 'Sales' },
        { id: 'ECE', name: 'Electronics' },
        { id: 'AGRI', name: 'Agriculture' },
        { id: 'HR', name: 'HR' },
        { id: 'ENGG', name: 'Engineering' },
        { id: 'MRKT', name: 'Marketing' },
        { id: 'ANHR', name: 'Animal Husbandary' }
    ]

    const [subCategory, setSubCategory] = useState('')

    const handleClick = (e) => {
        setSubCategory(e.target.value)
    }
    const [Disp,setDisp]= useState('none')

    return (
        <>
            <Box>
                <Button variant='outlined' style={{ width: '280px', height: '60px', borderRadius: '5px', boxShadow: '0 1px 3px 0', fontFamily: 'Montserrat', fontWeight: '500', fontSize: '24px', paddingLeft: '10px', backgroundColor: 'white', border: '2px solid white', margin: '3% 0 0 0', overflowY: 'hidden', color:'#001A49' }}
                onClick={()=>setDisp(!Disp) }>Category</Button>
                <FormControl fullWidth >
                        {categoryArray.map((name) => (
                            <Button value={name.name} onClick={handleClick} style={{ display:Disp? 'none' : 'inline',minWidth: '0px', width: '220px', height: '45px', borderRadius: '5px', boxShadow: '0 1px 3px 0', fontFamily: 'Montserrat', fontWeight: '500', fontSize: '16px', paddingLeft: '10px', backgroundColor: 'white', border: '2px solid white', margin: '3% 0 0 7.5%', overflowY: 'hidden', color:'#001A49', alignItems: 'center' }}
                            >{name.name}
                            </Button>
                        ))}
                </FormControl>
            </Box>
        </>
    );
}

export default CategoryDrop;