import * as React from 'react';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';
import Grid from '@mui/material/Grid';
import MolecularCopy from './images/MolecularCopy.png';
import './ModalComponent.css';
import ModalPlant from './images/ModalPlant.png'
import { ControlPointDuplicateRounded } from '@mui/icons-material';

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: '70%',
  height: 'auto',
  bgcolor: 'background.paper',
  border: '1px solid #000',
  boxShadow: 24,
  p: 4,
  display: 'flex'
};

export default function ModalComponent({ heading, modalcontent, picture,pdf }) {
  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const downloadFile = () => {
    window.location.href = pdf
  }

  return (
    <div>
      <div >
        <Button
          onClick={handleOpen}
          sx={{
            color: '#21b2bb',


          }}
        >
          Show Details
        </Button>
      </div>

      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >

        <Box sx={style}>
          <Grid container className='modelling'>
            <Grid item xs={12} lg={3} sm={3} md={3}>
              <img src={picture} alt='image' className='molImage' width="340" height="200" />
            </Grid>

            <Grid item xs={12} lg={9} sm={9} md={9} sx={{ paddingLeft: '12%' }}>

              <Typography variant='h5'
                sx={{ display: 'flex', justifyContent: 'center' }}
              ><b>
                  {heading}
                </b></Typography>


              <Typography id="modal-modal-description" sx={{ mt: 2, textAlign: 'justify' }}>
                {modalcontent}
              </Typography>

              <Button variant='contained' onClick={downloadFile}
                sx={{
                  backgroundColor: '#21b2bb',
                  margin: '5% 20%',
                  width: '70%',
                  height:"20%",

                }}
              >
                View Guidelines and Research Details
              </Button>

            </Grid>



          </Grid>
        </Box>
      </Modal>
    </div>
  );
}