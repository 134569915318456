import React from 'react';


import HomePage from './HomePage';
import ResearchCard from './ResearchCard';
import ResearchFeed from './ResearchFeed';
import ResearchForm from './ResearchForm';
import ResearchDetails from './Agriculture';
import EditDetails from './EditDetails';
import ModalComponent from './ModalComponent';

import { BrowserRouter as Router, Routes, Route } from "react-router-dom";

function ResearchFinal() {
  return (
    <div >
      <Router>
        <Routes>
          <Route path='/' element={<HomePage />} />
          <Route path='/researchfeed' element={<ResearchFeed />} />
          <Route path='/researchdetails' element={<ResearchDetails buttonText1='Re-Upload Image' buttonText2='Re-Upload PDF' />} />
        </Routes>
      </Router>



    </div>
  );
}

export default ResearchFinal;