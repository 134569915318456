import * as React from 'react';
import { styled } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import Grid from '@mui/material/Grid';
import MocCV from '../images/MocCV.png';
import MocImg from '../images/MocImg.png';
import MocText from '../images/MocText.png';
import MocBg from '../images/MocBg.png';

const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
  ...theme.typography.body2,
  padding: theme.spacing(1),
  textAlign: 'center',
  color: theme.palette.text.secondary,
}));

export default function MocPreview() {
  return (
    <Box sx={{ flexGrow: 1 ,marginTop:'px'}}>
      <Grid container spacing={3} style={{backgroundImage: `url(${MocBg})`}}>
        <Grid item xs={4} md={4}>
          <img src={MocImg} alt='img1' width='100%'/>25
        </Grid>
        <Grid item xs={4} md={2}>
          <img src={MocText} alt='img1' width='100%'/>
        </Grid>
        <Grid item xs={4} md={6}>
          <img src={MocCV} alt='img1' width='100%'/>
        </Grid>
      </Grid>
    </Box>
  );
}
