import * as React from "react";
import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";
import { Grid, Typography } from "@mui/material";
import { JobContext } from "./Postjob";

export default function Firsttext() {
  const {job,setJob}=React.useContext(JobContext);
  
  return (
    <Grid item container xs={12} spacing={1} sx={{display: 'flex'}}>
      <Grid item container xs={12} sm={5} md={5}>
        <Grid item xs={12}>
          <Typography
            sx={{
              fontFamily: "Epilogue",
              fontStyle: "normal",
              fontWeight: "600",
              fontSize: "18px",
            }}
          >
            Job Title
          </Typography>
        </Grid>
        <Grid xs={12}>
          <Typography
            sx={{
              color: "#7C8493",
              fontFamily: "Epilogue",
              fontStyle: "normal",
              fontWeight: "400",
              fontSize: "18px",
            }}
          >
            Job titles must describe one position
          </Typography>
        </Grid>
      </Grid>
      {/* <Grid item container sm={1} md={1}></Grid> */}
      <Grid item container xs={12} sm={6} md={6} sx={{display: 'block'}}>
        <Box
          sx={{
            width: 500,
            maxWidth: "100%",
          }}
        >
          <TextField required
          fullWidth 
          placeholder="e.g. Software Engineer" 
          id="fullWidth"
          value={job.title}
          onChange={e=>setJob({...job,title:e.target.value})}
          />
        </Box>
      </Grid>
      <Grid item container lg={3}></Grid>
    </Grid>
  );
}
