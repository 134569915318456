import React from "react";
import { useState } from "react";
import Box from "@mui/material/Box";
import Card from "@mui/material/Card";
import { useNavigate } from "react-router-dom";
import Typography from "@mui/material/Typography";
import { Grid } from "@mui/material";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import TextField from "@mui/material/TextField";
import DateRangePicker from "@mui/lab/DateRangePicker";
import AdapterDateFns from "@mui/lab/AdapterDateFns";
import LocalizationProvider from "@mui/lab/LocalizationProvider";
import IconButton from "@mui/material/IconButton";



const arr = [
  { 
    color: "#56CDAD",
    // image: "3",
    name: "New Incubation Ideas",
    path: "/Incubation"
  },
  {
    color: "#26A4FF",
    // image: "24",
    name: "Applications for Research",
    path: "/Research"
  },
  {
    color: "#1A50B2",
    // image: "76",
    name: "New Job Applicants",
    path: "/Jobportal"
  },
];


export default function GoodCard() {
  const [value, setValue] = useState([null, null]);
  const navigate = useNavigate();
  return (
    <>
      <Grid container xs={12} lg={11.5} md={12} sm={12} sx={{ marginTop: '20px', justifyContent: 'space-between', display: 'flex' }}>
        <div style={{fontWeight:'400', fontSize:"28px", marginLeft: '20px', color: ''}}>
          {/* Good Morning, */}
          Latest Updates 
        </div>

        {/* <Button variant="outlined" startIcon={<ArrowForwardIcon />}>
        View all
      </Button> */}
        {/* <LocalizationProvider dateAdapter={AdapterDateFns}>
          <DateRangePicker
            startText=""
            endText=""
            value={value}
            onChange={(newValue) => {
              setValue(newValue);
            }}
            renderInput={(startProps, endProps) => (
              <React.Fragment>
                <TextField sx={{ width: "120px", marginLeft: '30px' }} {...startProps} />
                <Box sx={{ mx: 2 }}> to </Box>
                <TextField sx={{ width: "120px", }} {...endProps} />
              </React.Fragment>
            )}
          />
        </LocalizationProvider> */}
      </Grid>

      <Grid
        xs={11} lg={11.2} md={11} sm={11}
        item container
        sx={{ marginLeft: "20px", marginTop: "20px", justifyContent: 'space-between' }}
      >
        {arr.map((item, index) => {
          return (
            <Grid container item lg={3.5} xs={12} md={5.8} sm={5.8} marginBottom="1%">
              <Grid lg={12} xs={12} md={12} sm={12}
                sx={{
                  height: "100px",
                  backgroundColor: item.color,
                  // minWidth: '350px'
                }}
              >
                <Grid
                  xs={12} md={12} sm={12}
                  // item
                  container
                  direction = "row"
                  sx={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    // paddingTop: "2%",
                    textAlign: 'center'
                  }}
                >
                  {/* <Grid item xs={2}>
                    <Typography
                      sx={{ color: "#fff" }}
                      fontSize={"44px"}
                      fontWeight={"600"}
                    >
                      {item.image}
                    </Typography>
                  </Grid> */}
                  <Grid item xs={9} sx={{display: 'flex'}}>
                    <Typography
                      sx={{ color: "#fff" }}
                      fontSize={"20px"}
                      marginTop={'25px'}
                      fontWeight={"600"}
                    >
                      {item.name}
                    <IconButton aria-label="Forward" onClick={() => navigate(item.path)} size="small">
                      <ArrowForwardIcon fontSize="large" sx={{ color: "#fff" }} />
                    </IconButton>
                    </Typography>
                  </Grid>
                  {/* <Grid item xs={2}> */}
                  {/* </Grid> */}
                </Grid>
              </Grid>
              
            </Grid>
          );
        })}
      </Grid>
    </>
  );
}




// {https://codesandbox.io/s/elastic-water-ubwure?file=/src/styles.css}