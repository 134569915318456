import React, { useContext, useState } from "react";
import { Box, Grid, Typography, Button, Card, Divider, IconButton, TextField } from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import ClearIcon from '@mui/icons-material/Clear';
import Modal from '@mui/material/Modal';
import { styled } from '@mui/material/styles';
import { useNavigate } from 'react-router-dom';
import { JobContext } from "../Vaibhav/Postjob";
import axios from "axios";
import { useEffect } from "react";
const Input = styled('input')({
  display: 'none',
});

const Typography1 = styled(Typography)({
  fontFamily: 'inter'
})

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 276,
  bgcolor: 'background.paper',
  border: '1px solid white',
  boxShadow: 24,
  p: 4,
};
const NewSyaapa1 = () => {
    const navigate = useNavigate();
  
    const [name, setName] = useState('')
   
    const [open, setOpen] = useState(false);
  
    const [formErrors, setFormErrors] = useState({});
    const [isSubmit, setIsSubmit] = useState(false); 
    const handleSubmit = (e) => {
    e.preventDefault();
    setFormErrors(validate(job));
  };
  useEffect(() => {
    
    if (Object.keys(formErrors).length === 0 && isSubmit) {
      console.log(job);
    }
  }, [formErrors]);
  const validate = (job) => {
    const errors = {};
    if (!job.setName) {
      errors.setName = "Please enter Whoareyou";
    }
   
    
    return errors;
  };
  
    const {job,setJob} = useContext(JobContext)
  
    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);
    // const arr = ;
    const [arr, setArr] = useState([])
  
    
    
  return (
    <Box sx={{ margin: '1% 5%' }}>
    <Box>
      <Grid container >
        <Grid item >
          {/* <Typography1
            variant="h6"
            sx={{
              // fontFamily: "inter",
              color: "#001A49",
              // fontSize: '20px'
            }}
          ><b>
              List out your top perks and benefits.</b>
          </Typography1> */}
          {/* <Typography
            sx={{
              fontFamily: "Epilogue",
              fontWeight: "400",
              fontSize: "16px",
              lineHeight: "160%",
              color: "#515B6F",
            }}
          >
            List out your top perks and benefits.
          </Typography> */}
        </Grid>
      </Grid>
      <Divider sx={{ marginTop: "2%" }} />
    </Box>
    <Box sx={{ marginTop: "2%" }}>
      <Grid container>
        <Grid item xs={12} lg={2}>
          <Typography1
            sx={{
              // fontFamily: "Epilogue",
              fontWeight: "600",
              fontSize: "22px",
              lineHeight: "160%",
              color: "#25324B",
            }}
          >
        Who You Are
          </Typography1>
          <Typography1
            sx={{
              // fontFamily: "Epilogue",
              fontWeight: "400",
              fontSize: "16px",
              lineHeight: "160%",
              color: "#515B6F",
            }}
          >
           Add your preferred candidates qualifications.
          </Typography1>
        </Grid>
        <Grid item lg={0.8}></Grid>

        <Grid item xs={12} lg={9.2}>
          <Button
            variant="outlined"
            onClick={handleOpen}
            sx={{
              textTransform: "capitalize",
              fontSize: "1rem",
              border: "1px solid #D6DDEB",
              marginTop: "2%",
            }}
          >
            <AddIcon sx={{ fontSize: "1.5rem" }} />
            Add Who You Are
          </Button>
          <Modal
            open={open}
            onClose={handleClose}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
          >
            <Box sx={style}>
              <Typography1 variant="h5"
              // fontFamily={"Poppins"}
              >
                Add Who You Are
              </Typography1>
              <Divider />

              <form >
               
                <Typography1 variant="h6"
                  // fontFamily={"Poppins"}
                  color={"#25324B"}
                  marginTop={"2%"}>
                 Who You Are
                </Typography1>
                <TextField sx={{marginTop:'4%'}} fullWidth label={'Eg: You are good in React.js'}   onChange={e => setName(e.target.value)} />
             
               
                <Divider sx={{ marginTop: '8%' }} />
                <Box sx={{ justifyContent: "flex-end", display: 'flex' }}>
                  <Button variant="contained" sx={{ textTransform: 'capitalize', marginTop: '4%', borderRadius: '0%' }} onClick={() => {
                   
                    handleSubmit
                    let newArr = [...arr]
                    newArr.push({
                      name: name,
                      
                    })
                    setArr(newArr)
                    setJob({...job,Whoareyou:newArr})
                  }}>Save</Button></Box>
              </form>
            </Box>
          </Modal>
          <Box sx={{ marginTop: "2%", width: "100%" }}>
            {arr.map((item, index) => {
              return (
                <Card

                  sx={{
                    width: "auto",
                    
                    padding: "1%",
                    borderRadius: "0%",
                    border: "1px solid #D6DDEB",
                    marginRight: "24px",
                    marginBottom: "24px",
                    display: "inline-block",
                  }}
                >
                  <Box
                    sx={{ justifyContent: "space-between", display: "flex" }}
                  >
                   
                    <IconButton sx={{ color: "#1A50B2" }} onClick={() => {
                      let newarr = [...arr]
                      newarr.splice(index, 1)
                      setArr(newarr)
                      setJob({...job,Whoareyou:newarr})
                    }}>
                      <ClearIcon />
                    </IconButton>
                  </Box>

                  <Typography1
                    variant="h6"
                    // fontFamily={"Poppins"}
                    sx={{ marginTop: "4%" }}
                  >
                    {item.name}
                  </Typography1>
                  <br />
                 

                </Card>
              );
            })}
          </Box>
        </Grid>
      </Grid>
    </Box>
    <Divider sx={{marginTop:'2%'}}/>
    <Grid container sx={{ marginY: '1%' }}>
      <Grid item xs={7} lg={10} md={9.5} sm={9.5}/>

      {/* <Grid item xs={5} lg={2} md={2.5} sm={2.5}>
        <Button variant='contained' onClick={submit} 
          sx={{
            borderRadius: '0px', backgroundColor: '#1a50b2',
            ':hover': { color: '#1a50b2', backgroundColor: 'white' }
          }}
        >Submit</Button></Grid> */}
        </Grid>
  </Box>
  )
}

export default NewSyaapa1