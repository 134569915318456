import React, { useContext, useState } from "react";
// import RecipeReviewCard from "./New"
import Jobsss from "./Jobsss"
import ResearchDash from "./ResearchDash";
import GoodCard from "./Good";
import Footer1 from '../Vikram/Footer1'
import Footer from "../Deepak/Footer";
import Hheader from "../Vaibhav/Header";
import GoToTop from "./Gototop";




export default function Dashboard() {
  //     return modules.map((module, key) => <MenuItem key={key} topic={module} />);
  // }

  //     const MenuItem = ({ topic }) => {
  //         const [open, setOpen] = useState(false);
  //         const handleClick = () => {
  //           setOpen(!open)
  //         }


  return (
    <>
      <Hheader />
      <GoodCard />
      <Jobsss />
      <ResearchDash />
      <Footer1 />
      <GoToTop />

    </>
    // <div>
    // <SearchAppBar/>
    // <RecipeReviewCard/>
    // </div>

    // <Card sx={{}}>
    //     <div style={{ backgroundColor: "rgba(1, 105, 194, 0.05)", borderRadius: "12px", width: '800px' }}>
    //         <ListItem sx={{ marginBottom: "0.4%" }} button onClick={handleClick}>

    //             {open ? <RemoveIcon sx={{ color: "#5C6BFF" }} /> : <AddIcon sx={{ color: "#5C6BFF" }} />}
    //             {topic.name}

    //         </ListItem>
    //     </div>
    //     <Collapse sx={{ backgroundColor: "#fff", width: '800px', borderRadius: '12px' }} in={open} timeout="auto" unmountOnExit>
    //         <List component="div" sx={{ paddingLeft: "15px" }}>
    //             {topic.topics.map((topic, key) => (
    //                 <TopicTiles topic={topic} />
    //             ))}
    //         </List>
    //     </Collapse>
    // </Card>

  )
}


