import React,{useState,useEffect} from 'react';
import { Grid, Typography,Button,Box,Autocomplete,TextField } from '@mui/material';
import Modal from '@mui/material/Modal';

import ContentCard from './ContentCard';
import ContentCard2 from './ContentCard2';
import ContentCard3 from './ContentCard3';
import ContentCard4 from './ContentCard4';
import bg1 from '../Namrata/images/bg1.png';
import bg2 from '../Namrata/images/bg2.png';
import bg3 from '../Namrata/images/bg3.png';
import bg4 from '../Namrata/images/bg4.png';
import ImgBg1 from '../Namrata/images/ImgBg1.png';
import ImgBg2 from '../Namrata/images/ImgBg2.png';
import ImgBg3 from '../Namrata/images/ImgBg3.png';
import ImgBg4 from '../Namrata/images/ImgBg4.png';

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 350,
    bgcolor: 'background.paper',
    border: '2px solid white',
    boxShadow: 24,
    p: 4,
  };
export default function AnimationGrid() {

    const initialValues = {
        Name: "",
        Interestedfield: "",
        coursename: "",
        Industryexperience: "",
        Email: "",
        Contactnumber: ""
    };
    const [formValues, setFormValues] = useState(initialValues);
    const [formErrors, setFormErrors] = useState({});
    const [isSubmit, setIsSubmit] = useState(false);

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormValues({ ...formValues, [name]: value });
        console.log(formValues);
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        setFormErrors(validate(formValues));
        setIsSubmit(true);
    };
    useEffect(() => {
        console.log(formErrors);
        if (Object.keys(formErrors).lenght === 0 && isSubmit) {
            console.log(formValues);
        }
    }, [formErrors]);
    const validate = (values) => {
        const errors = {};
        const regex = /^[^\s@]+@[^\s@]+\.[^\s@]{2,}$/i;

         if (!regex.test(values.Email)) {
            errors.Email = "This is not a valid email format!";
        }
        if (values.Contactnumber.length < 10) {
            errors.Contactnumber = "Mobile number must be of 10 digits";
        } else if (values.Contactnumber.length > 10) {
            errors.Contactnumber = "Mobile number must be of 10 digits";
        }
        return errors;
    };



    const [open, setOpen] = React.useState(false);
    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);
    return (


        <Box sx={{ flexGrow: 1, marginTop: '40px',textAlign:'center' }} style={{ fontFamily: 'Montserrat' }}>
                
            <Grid container rowSpacing={15} columnSpacing={{ xs: 1, sm: 2, md: 3 }} direction='column' >
                <Grid item xs style={{ marginLeft: '60px',textAlign:'center' }}>
                    {/* <h4 className='guidelines'>01. Guidelines</h4> */}
                   
                        
                            <h2>Uploading Formats</h2>
                       
                        {/* <div className="btn" style={{ marginLeft: '30%' }}>
                            <Button variant='contained'>Contact Us</Button>
                        </div> */}
                   
                    
                        <p style={{fontSize: '20px'}}> There are certain guidelines that one must keep in mind while uploading the courses.</p>

                </Grid>
                <Grid item xs style={{ marginTop: '-7%' }}>
                    <ContentCard
                        heading="Audio Content or Podcast"
                        content="Upload in MP3  format"
                        imgback={bg1}
                        imgfront={ImgBg1}
                    />
                </Grid>
                {/* </Grid>
            <Grid container> */}
                <Grid item xs style={{ marginTop: '-7%' }}>
                    <ContentCard2
                        heading="Video Content"
                        content="Upload in MP4, MPG format"
                        imgback={bg2}
                        imgfront={ImgBg2}
                    />
                </Grid>
                {/* </Grid>
            <Grid container> */}
                <Grid item xs style={{ marginTop: '-7%' }}>
                    <ContentCard3
                        heading="Content in the form of PDF"
                        content="Upload in .PDF format"
                        imgback={bg3}
                        imgfront={ImgBg3}
                    />
                </Grid>
                {/* </Grid>
            <Grid container> */}
                <Grid item xs style={{ marginTop: '-7%' }}>
                    <ContentCard4
                        heading="Content in the form of Slides"
                        content="Upload in .PPT format."
                        imgback={bg4}
                        imgfront={ImgBg4}
                    />
                </Grid>


            </Grid>

            <Button onClick={handleOpen} variant='contained' sx={{  marginTop: '50px' }}>Upload Now</Button>

            <Grid conatiner sx={{ padding: "2%" }}>
                
                <Grid item xs={12} lg={8}>
                    <Modal
                        open={open}
                        onClose={handleClose}
                        aria-labelledby="modal-modal-title"
                        aria-describedby="modal-modal-description"
                    >
                        <Box sx={style}>
                            <form onSubmit={handleSubmit}>
                                <Typography sx={{
                                    fontWeight: "500",
                                    fontSize: "18px",
                                    fontFamily: 'poppins'
                                }}>
                                    Name :
                                </Typography>
                                <input
                                    type="text"
                                    name="Name"
                                    value={formValues.Name}
                                    onChange={handleChange}

                                    style={{ width: "100%", height: '40px', border: '1px solid rgba(0, 0, 0, 0.23)', borderRadius: "4px", fontSize: '16px', color: 'rgba(0, 0, 0, 0.87)', fontWeight: '400' }}
                                />
                                <Typography sx={{
                                    fontWeight: "500",
                                    fontSize: "18px",
                                    fontFamily: 'poppins',
                                    marginTop: '4%'
                                }}>
                                    Interested Field :
                                </Typography>
                                <Autocomplete

                                    disablePortal
                                    id="combo-box-demo"
                                    options={interestedfield}

                                    renderInput={(params) => (
                                        <TextField
                                            {...params}
                                            size="small"
                                            label=""
                                            type="text"
                                            name="Interestedfield"
                                            value={formValues.Interestedfield}
                                            onChange={handleChange}

                                            sx={{ fontSize: '16px', color: 'rgba(0, 0, 0, 0.87)', fontWeight: '400', width: '102%' }}
                                        />
                                    )}
                                />
                                <Typography sx={{
                                    fontWeight: "500",
                                    fontSize: "18px",
                                    fontFamily: 'poppins',
                                    marginTop: '4%'
                                }}>
                                    Course Name :
                                </Typography>
                                <Autocomplete

                                    disablePortal
                                    id="combo-box-demo"
                                    options={coursename}

                                    renderInput={(params) => (
                                        <TextField
                                            {...params}
                                            size="small"
                                            label=""
                                            type="text"
                                            name="Coursename"
                                            value={formValues.coursename}
                                            onChange={handleChange}

                                            sx={{ fontSize: '16px', color: 'rgba(0, 0, 0, 0.87)', fontWeight: '400', width: '102%' }}
                                        />
                                    )}
                                />
                                <Typography sx={{
                                    fontWeight: "500",
                                    fontSize: "18px",
                                    fontFamily: 'poppins',
                                    marginTop: '4%'
                                }}>
                                    Industry Experience :
                                </Typography>
                                <Autocomplete

                                    disablePortal
                                    id="combo-box-demo"
                                    options={experience}

                                    renderInput={(params) => (
                                        <TextField
                                            {...params}
                                            size="small"
                                            label=""
                                            type="text"
                                            name="Industryexperience"
                                            value={formValues.Industryexperience}
                                            onChange={handleChange}

                                            sx={{ fontSize: '16px', color: 'rgba(0, 0, 0, 0.87)', fontWeight: '400', width: '102%' }}
                                        />
                                    )}
                                />
                                <Typography sx={{
                                    fontWeight: "500",
                                    fontSize: "18px",
                                    fontFamily: 'poppins',
                                    marginTop: '4%'
                                }}>
                                    Email :
                                </Typography>
                                <input
                                    type="text"
                                    name="Email"
                                    value={formValues.Email}
                                    onChange={handleChange}

                                    style={{ width: "100%", height: '40px', border: '1px solid rgba(0, 0, 0, 0.23)', borderRadius: "4px", fontSize: '16px', color: 'rgba(0, 0, 0, 0.87)', fontWeight: '400' }}
                                />
                                <p style={{ color: "red" }}>{formErrors.Email}</p>
                                <Typography sx={{
                                    fontWeight: "500",
                                    fontSize: "18px",
                                    fontFamily: 'poppins',
                                    marginTop: '4%'
                                }}>
                                    Contact Number :
                                </Typography>
                                <input
                                    type="text"
                                    name="Contactnumber"
                                    placeholder="9999999999"
                                    value={formValues.Contactnumber}
                                    onChange={handleChange}

                                    style={{ width: "100%", height: '40px', border: '1px solid rgba(0, 0, 0, 0.23)', borderRadius: "4px", fontSize: '16px', color: 'rgba(0, 0, 0, 0.87)', fontWeight: '400' }}
                                />
                                <p style={{ color: "red" }}>{formErrors.Contactnumber}</p>
                                <Grid display={"flex"} justifyContent={"end"} marginTop={"4%"}>
                                    <Button variant="contained" onClick={handleSubmit} >
                                        Submit
                                    </Button>
                                </Grid>
                            </form>

                        </Box>
                    </Modal>
                </Grid>

            </Grid>

        </Box>

    );
}
const interestedfield = [
    { label: "IT" },
    { label: "CSE" },
    { label: "CSE" },
    { label: "ECE" },
  ];
  const coursename = [
    { label: "IT" },
    { label: "CSE" },
    { label: "CSE" },
    { label: "ECE" },
  ];
  const experience = [
    { label: "Fresher" },
    { label: "2 years" },
    { label: "5 years" },
    { label: "7 years" },
  ];