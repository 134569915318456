import React, { useContext, useState } from "react";
import { Box, Grid, Typography, Button, Card, Divider, IconButton, TextField } from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import ClearIcon from '@mui/icons-material/Clear';
import I1 from "./I1.png";
import I2 from "./I2.png";

import CloseIcon from "@mui/icons-material/Close";
import Modal from '@mui/material/Modal';
import { styled } from '@mui/material/styles';
import { useNavigate } from 'react-router-dom';
import { JobContext } from "../Vaibhav/Postjob";
import JobDescription from "../namrata/JobDescription";
import axios from "axios";
import { useEffect } from "react";
const Input = styled('input')({
  display: 'none',
});

const Typography1 = styled(Typography)({
  fontFamily: 'inter'
})

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 276,
  bgcolor: 'background.paper',
  border: '1px solid white',
  boxShadow: 24,
  p: 4,
};
const Step3 = () => {
  const navigate = useNavigate();
  const [imgsrc, setImgsrc] = useState('')
  const [name, setName] = useState('')
  const [description, setDescription] = useState('')
  const [open, setOpen] = useState(false);

  const [formErrors, setFormErrors] = useState({});
  const [isSubmit, setIsSubmit] = useState(false); 
  const handleSubmit = (e) => {
  e.preventDefault();
  setFormErrors(validate(job));
};
useEffect(() => {
  console.log(formErrors);
  if (Object.keys(formErrors).length === 0 && isSubmit) {
    console.log(job);
  }
}, [formErrors]);
const validate = (job) => {
  const errors = {};
  if (!job.setName) {
    errors.setName = "Please enter Description";
  }
  if (!job.setDescription) {
    errors.setDescription = "Please enter Responsibility";
  }
  
  return errors;
};

  const {job,setJob} = useContext(JobContext)

  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  // const arr = ;
  const [arr, setArr] = useState([])

  const submit = async()=>{
    var in_date = new Date();
    var str=in_date.getDate() +'/'+(in_date.getMonth()+1)+'/'+in_date.getFullYear()
    job.Startdate = str
    alert('Your form is submitted.')
    console.log(job)
    axios({url:'https://po.keewesolutions.com/jp/post',
    method:'POST',
    data:job
    })
    .then(response=>
      console.log(response))
    }
  
    
     
  return (
    <Box sx={{ margin: '1% 5%' }}>
      <Box>
        <Grid container >
          <Grid item >
            <Typography1
              variant="h6"
              sx={{
                // fontFamily: "inter",
                color: "#001A49",
                // fontSize: '20px'
              }}
            ><b>
                List out your top perks and benefits.</b>
            </Typography1>
            {/* <Typography
              sx={{
                fontFamily: "Epilogue",
                fontWeight: "400",
                fontSize: "16px",
                lineHeight: "160%",
                color: "#515B6F",
              }}
            >
              List out your top perks and benefits.
            </Typography> */}
          </Grid>
        </Grid>
        <Divider sx={{ marginTop: "2%" }} />
      </Box>
      <Box sx={{ marginTop: "2%" }}>
        <Grid container>
          <Grid item xs={12} lg={2}>
            <Typography1
              sx={{
                // fontFamily: "Epilogue",
                fontWeight: "600",
                fontSize: "22px",
                lineHeight: "160%",
                color: "#25324B",
              }}
            >
              Perks and Benefits
            </Typography1>
            <Typography1
              sx={{
                // fontFamily: "Epilogue",
                fontWeight: "400",
                fontSize: "16px",
                lineHeight: "160%",
                color: "#515B6F",
              }}
            >
              Encourage more people to apply by sharing the attractive rewards
              and benefits you offer your employees
            </Typography1>
          </Grid>
          <Grid item lg={0.8}></Grid>

          <Grid item xs={12} lg={9.2}>
            <Button
              variant="outlined"
              onClick={handleOpen}
              sx={{
                textTransform: "capitalize",
                fontSize: "1rem",
                border: "1px solid #D6DDEB",
                marginTop: "2%",
              }}
            >
              <AddIcon sx={{ fontSize: "1.5rem" }} />
              Add Benefit
            </Button>
            <Modal
              open={open}
              onClose={handleClose}
              aria-labelledby="modal-modal-title"
              aria-describedby="modal-modal-description"
            >
              <Box sx={style}>
                <Typography1 variant="h5"
                // fontFamily={"Poppins"}
                >
                  Add Benefit
                </Typography1>
                <Divider />

                <form >
                 
                  <Typography1 variant="h6"
                    // fontFamily={"Poppins"}
                    color={"#25324B"}
                    marginTop={"2%"}>
                    Benefit
                  </Typography1>
                  <TextField fullWidth label={'eg: Healthcare'} value={name} onChange={e => setName(e.target.value)} />
                  <p style={{ color: "red" }}>{formErrors.setName}</p>
                  <Typography1 variant="h6"
                    // fontFamily={"Poppins"}
                    color={"#25324B"}
                    marginTop={"2%"}>
                    Description
                  </Typography1>
                  <TextField fullWidth value={description} onChange={e => setDescription(e.target.value)} />
                  <p style={{ color: "red" }}>{formErrors.setDescription}</p>
                  <Divider sx={{ marginTop: '6%' }} />
                  <Box sx={{ justifyContent: "flex-end", display: 'flex' }}>
                    <Button variant="contained" sx={{ textTransform: 'capitalize', marginTop: '4%', borderRadius: '0%' }} onClick={() => {
                     
                      handleSubmit
                      let newArr = [...arr]
                      newArr.push({
                        name: name,
                        description: description
                      })
                      setArr(newArr)
                      setJob({...job,Perks:newArr})
                    }}>Save</Button></Box>
                </form>
              </Box>
            </Modal>
            <Box sx={{ marginTop: "2%", width: "100%" }}>
              {arr.map((item, index) => {
                return (
                  <Card

                    sx={{
                      width: "250px",
                      
                      padding: "1%",
                      borderRadius: "0%",
                      border: "1px solid #D6DDEB",
                      marginRight: "24px",
                      marginBottom: "24px",
                      display: "inline-block",
                    }}
                  >
                    <Box
                      sx={{ justifyContent: "space-between", display: "flex" }}
                    >
                     
                      <IconButton sx={{ color: "#1A50B2" }} onClick={() => {
                        let newarr = [...arr]
                        newarr.splice(index, 1)
                        setArr(newarr)
                        setJob({...job,Perks:newarr})
                      }}>
                        <ClearIcon />
                      </IconButton>
                    </Box>

                    <Typography1
                      variant="h5"
                      // fontFamily={"Poppins"}
                      sx={{ marginTop: "4%" }}
                    >
                      {item.name}
                    </Typography1>
                    <br />
                    <Typography1
                      variant="h7"
                      // fontFamily={"Poppins"}
                      color={"#515B6F"}
                    >
                      {item.description}
                    </Typography1>

                  </Card>
                );
              })}
            </Box>
          </Grid>
        </Grid>
      </Box>
      <Divider />
      <Grid container sx={{ marginY: '1%' }}>
        <Grid item xs={7} lg={10} md={9.5} sm={9.5}/>

        <Grid item xs={5} lg={2} md={2.5} sm={2.5}>
          <Button variant='contained' onClick={submit}
            sx={{
              borderRadius: '0px', backgroundColor: '#1a50b2',
              ':hover': { color: '#1a50b2', backgroundColor: 'white' }
            }}
          >Submit</Button></Grid></Grid>
    </Box>
  );
};

export default Step3;
