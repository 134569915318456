import React from "react";
import { Typography, Grid, Box, Button, Avatar, List, ListItem, IconButton } from "@mui/material";
import NFT from "./NFT.png"
import Harjit from "./harjit.png"
import FiberManualRecordIcon from '@mui/icons-material/FiberManualRecord';
import NotificationsActiveOutlinedIcon from '@mui/icons-material/NotificationsActiveOutlined';
import { NavLink } from "react-router-dom";
import Video from "./Player/Videoplayer"
import GoToTop from "../../Components/Gototop";



export default function IdeaCard() {


    const descp = [{ point: 'Ideation on Non-fungible tokens performing as lending platforms .' },
    { point: ' The acceptance of non-fungible assets as collaterals securities. ' },
    { point: ' This idea motivates to hold tokens and grants access to interest loans for lenders.' },
    { point: ' This  NFT lending platform is very significant that provide  holders of arising assets. ' },
    { point: ' The combination loans with non-fungible tokens attracts more users to your platform. ' }]
    return (
        <Grid container>
            <Grid container sx={{ margin: '2% 4%' }}>
                <Grid item xs={5} lg={9} md={7} sm={7}>
                    <Typography variant='h4'><b>Incubation</b></Typography>
                </Grid>

                <Grid item xs={10} lg={2} md={4} sm={4}>
                    <NavLink to={'/incubhome'} style={{ textDecoration: 'none' }}> <Button variant='outlined' sx={{ borderRadius: '0px' }}>Back to homepage</Button></NavLink>
                </Grid>

                <Grid item xs={2} lg={1} md={1} sm={1}>
                    <IconButton>
                        <NotificationsActiveOutlinedIcon />
                    </IconButton>
                </Grid>
            </Grid>

            <div style={{ position: "relative", width: "100%", height: '400px' }}>
                <img
                    src={NFT}
                    alt="imagesa"
                    style={{ width: "100%", height: "400px", position: "absolute" }}
                />
            </div>


            <Grid container item xs={12} lg={12} >
                <Grid lg={0.5} />
                <Grid container item xs={12} lg={2} marginTop='3%' >
                    <Typography sx={{ display: 'inline', fontSize: '20px', color: '#001A49' }}><Avatar sx={{ width: '70px', height: '70px', marginLeft: '12%' }} src={Harjit} />Harjeet Singh</Typography>
                </Grid>
                <Grid container item xs={12} lg={6} justifyContent='center' alignItems='center'>
                    <Typography variant="h3" fontFamily="PT serif" marginTop='4%'>
                        NFT loan platform in Real Estate
                    </Typography>
                    <Grid direction='row' marginTop={'2%'}>
                        <Button
                            variant="contained"
                            sx={{
                                backgroundColor: "#1A50B2",
                                borderRadius: "0px",
                                textTransform: "none",
                                width: '189px',
                                height: '50px',
                                fontSize: '16px'
                            }}
                        >
                            Schedule a Meeting
                        </Button>
                    </Grid>
                </Grid>
                <Grid lg={1} />
                <Grid container item xs={12} lg={2} alignItems='end' justifyContent='end' marginTop='4%' sx={{ display: 'inline' }}>
                    <Grid><Typography sx={{ fontSize: '20px', color: '#001A49' }}>Completion Status</Typography></Grid>
                    <Grid>
                        <Box sx={{ marginTop: '2%', border: '1px solid #FFB836', borderRadius: '20px', color: '#FFB836', width: '160px', height: '35px', textAlign: 'center', fontSize: '20px', marginLeft: '2%' }}> Under Progress</Box>
                    </Grid>
                </Grid>
                <Grid lg={0.5} />
            </Grid>
            <Grid container item xs={12} lg={12} marginTop='8%'>
                <Grid lg={1} />
                <Grid container item xs={12} lg={11}>
                    <Grid item xs={12} lg={12}>
                        <Typography variant="h4">Project Overview</Typography>
                        <Grid>
                            <Video />
                        </Grid>

                    </Grid>
                    <Grid item xs={12} lg={12}>
                        <List><Typography variant="h4">Project Description</Typography>
                            {descp.map((item) => {
                                return (
                                    <ListItem key={item.point}>
                                        <FiberManualRecordIcon sx={{ color: '#515B6F', width: '10px', height: '10px' }} />&nbsp;
                                        <Typography sx={{ color: '#515B6F', fontSize: '24px' }}>
                                            {item.point}
                                        </Typography>
                                    </ListItem>)
                            })}
                        </List>
                    </Grid>
                    <Grid container item xs={12} lg={11}>
                        <Grid item xs={12} lg={12} marginTop='10px'>
                            <Typography variant="h4">Working Model Demo</Typography>
                            <Grid>
                                <Video />
                            </Grid>

                        </Grid>
                     </Grid>   
                        <Grid item xs={12} lg={12}  marginTop='10px'>
                            <Typography variant="h4">Expenses and Revenue Model</Typography>
                            <Grid sx={{ display: 'flex' }}>
                               <Grid>
                               <Typography sx={{ color: '#515B6F', fontSize: '22px',marginTop:'10px' }}>
                                    NFT Loan Platform in Real Estate Revenue Model.pdf   </Typography>
                               </Grid>
                                <Grid sx={{ marginLeft: '10px' }}>
                                    <Button variant='contained' sx={{marginLeft:'20px',
                                        backgroundColor: '#E9EBFD', color: '#1A50B2', border: '1px solid #4640DE', ':hover': {
                                            backgroundColor: '#e9ebfd'
                                        }
                                    }}>View PDF</Button>
                                    <Button variant='contained' sx={{
                                        marginLeft: '20px', backgroundColor: '#E9EBFD', color: '#1A50B2', border: '1px solid #4640DE', ':hover': {
                                            backgroundColor: '#e9ebfd'
                                        }
                                    }}>Download PDF</Button>
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid item xs={12} lg={12}  marginTop='30px' marginBottom='20px'>
                            <Typography variant="h4">Expansion of Ideation</Typography>
                            <Grid sx={{ display: 'flex' }}>
                                <Grid>
                                <Typography sx={{ color: '#515B6F', fontSize: '22px',marginTop:'10px' }}>
                                    Expansion NFT Loan Platform in Real Estate.pdf  </Typography>
                                </Grid>
                                <Grid sx={{ marginLeft: '68px' }}>
                                    <Button variant='contained' sx={{marginLeft:'20px',
                                        backgroundColor: '#E9EBFD', color: '#1A50B2', border: '1px solid #4640DE', ':hover': {
                                            backgroundColor: '#e9ebfd'
                                        }
                                    }}>View PDF</Button>
                                    <Button variant='contained' sx={{
                                        marginLeft: '20px', backgroundColor: '#E9EBFD', color: '#1A50B2', border: '1px solid #4640DE', ':hover': {
                                            backgroundColor: '#e9ebfd'
                                        }
                                    }}>Download PDF</Button>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
                 <GoToTop/>
            </Grid>
            );
}
