import React from "react";
import { Typography, Grid, Card, Button } from "@mui/material";
import Incubation1 from "./incubation.png";
import IncubationNew from '../images/IncubationNew.png';
import fileDownload from 'js-file-download';
import "./Page1.css";
import axios from 'axios'

export default function HomePage1() {


  const handleClick = (url, filename) => {
    axios.get(url, {
      responseType: 'blob',
    })
      .then((res) => {
        fileDownload(res.data, filename)
      })
  }

  return (
    <Grid container>
      <div style={{ position: "relative", width: "100%", height: '450px',marginTop:'10px' }}>
        <img
          src={Incubation1}
          alt="imagesa"
          style={{ width: "100%", height: "450px", position: "absolute" }}
        />
      </div>

      <Grid item lg={1} />
      <Grid container item xs={12} lg={6} md={12}  sm={12} sx={{padding: '0 2%'}} >
        <Typography variant="h3" fontFamily="PT serif" marginTop='4%' sx={{textAlign: 'justify'}}>
          A Bridge Between An Ingenious Mind And A New Empire
        </Typography>
      </Grid>

      <Grid container sx={{ marginBottom: '3%', padding: "0 2%" }}>
        <Grid item lg={1}  />
        <Grid item xs={12} lg={6} sm={6} md={6}>
          <Typography
            variant="h6"
            fontFamily="poppins"
            sx={{ marginTop: "7%", textAlign: 'justify' }}
          >
            A budding start-up thrives among brilliant ideas and this is the
            Mecca of futuristic ideas. Here you can find impactful solutions to
            complex problems. Besides, Partnered Organizations have the
            opportunity of witnessing innovative ideas flourish. These ideas can
            be your business solutions or even the start of a new venture.
          </Typography>
        </Grid>
        <Grid item lg={1} md={0.8} sm={0.8} />
        <Grid item xs={12} lg={4} md={4} sm={4}> 
          <img src={IncubationNew} alt="image"
            style={{ marginLeft: '4%'}}
          />
        </Grid>
      </Grid>

      <Grid container sx={{ marginBottom: "2%" }}>
        <Grid container item xs={5.7} lg={2.7}  md={2.7} sm={2.7} sx={{margin:"1%"}}
          direction='row'
          justifyContent='center'
          alignItems='center'
        >
          <Button onClick={() => handleClick('https://avatars.githubusercontent.com/u/9919?s=280&v=4', 'sample')}
            variant="contained"
            sx={{
              backgroundColor: "#1a50b2",
              borderRadius: "0px",
              textTransform: "none",
              width: '250px',
              height: '64px',
              fontSize: '20px'
            }}
          >
            Garnering Intel
          </Button>
        </Grid>
        <Grid container item xs={5.7} lg={2.7} md={2.7} sm={2.7} sx={{margin:"1%"}}
          direction='row'
          justifyContent='center'
          alignItems='center'
        >
          <Button onClick={() => handleClick('https://avatars.githubusercontent.com/u/9919?s=280&v=4', 'sample')}
            variant="contained"
            sx={{
              backgroundColor: "#1a50b2",
              borderRadius: "0px",
              textTransform: "none",
              width: '250px',
              height: '64px',
              fontSize: '20px'
            }}
          >
            Mentorship
          </Button>
        </Grid>
        <Grid container item xs={5.7} lg={2.7} md={2.7} sm={2.7} sx={{margin:"1%"}}
          direction='row'
          justifyContent='center'
          alignItems='center'
        >
          <Button onClick={() => handleClick('https://avatars.githubusercontent.com/u/9919?s=280&v=4', 'sample')}
            variant="contained"
            sx={{
              backgroundColor: "#1a50b2",
              borderRadius: "0px",
              textTransform: "none",
              width: '250px',
              height: '64px',
              fontSize: '20px'
            }}
          >
            Legal Support
          </Button>
        </Grid>
        <Grid container item xs={5.7} lg={2.7} md={2.7} sm={2.7}  sx={{margin:"1%"}}
          direction='row'
          justifyContent='center'
          alignItems='center'
        >
          <Button onClick={() => handleClick('https://avatars.githubusercontent.com/u/9919?s=280&v=4', 'sample')}
            variant="contained"
            sx={{
              backgroundColor: "#1a50b2",
              borderRadius: "0px",
              textTransform: "none",
              width: '250px',
              height: '64px',
              fontSize: '20px'
            }}
          >
            Patent & Copyrights
          </Button>
        </Grid>
      </Grid>
    </Grid>
  );
}
