import * as React from "react";
import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";
import { Grid, Typography } from "@mui/material";
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import { JobContext } from "./Postjob";

export default function Forthdd() {
    const {job,setJob} = React.useContext(JobContext);

    
  
  return (
    <Grid item container  sx={{display: 'flex'}}>
      
    <Grid item xs={12} lg={3} sm={5}>
          <Typography
            sx={{
              fontFamily: "Epilogue",
              fontStyle: "normal",
              fontWeight: "600",
              fontSize: "18px",
            }}
          >
            Categories
          </Typography>
        
        <br/>
          <Typography
            sx={{
              color: "#7C8493",
              fontFamily: "Epilogue",
              fontStyle: "normal",
              fontWeight: "400",
              fontSize: "18px",
            //   width: "200px"
            }}
          >
            You can select multiple job categories
          </Typography>
        
      </Grid>
      <Grid item lg={2} sm={1}/>
      <Grid item  lg={7} xs={12} sm={6}>
      <div>
      <FormControl sx={{ m: 1, minWidth: 320 }}>
        <InputLabel id="demo-simple-select-helper-label">Your job category</InputLabel>
        <Select required
          labelId="demo-simple-select-helper-label"
          id="demo-simple-select-helper"
          value={job.category}
          label="Your job category"
          onChange={e=>setJob({...job,category:e.target.value})}
        >
          <MenuItem value="">
            <em>None</em>
          </MenuItem>
          <MenuItem value={'Language Course'}>Language Course</MenuItem>
          <MenuItem value={'DS & Algorithm'}>DS & Algorithm</MenuItem>
          <MenuItem value={'CNC Programming'}>CNC Programming</MenuItem>
          <MenuItem value={'Programmer'}>Programmer</MenuItem>
          <MenuItem value={'Designer'}>Designer</MenuItem>
          <MenuItem value={'Data Science'}>Data Science</MenuItem>
          <MenuItem value={'Machine Learning'}>Machine Learning</MenuItem>
          {/* <MenuItem value={'UI/UX Designer'}>UI/UX Designer</MenuItem> */}
          <MenuItem value={'Web Developer'}>Web Developer</MenuItem>
          <MenuItem value={'App Developer'}>App Developer</MenuItem>
          <MenuItem value={'Blockchain Developer'}>Blockchain Developer</MenuItem>
          <MenuItem value={'Business Analyst'}>Business Analyst</MenuItem>
          <MenuItem value={'Database Engineer'}>Database Engineer</MenuItem>
          <MenuItem value={'Patents & IP Research'}>Patents & IP Research</MenuItem>
          <MenuItem value={'Cloud Computing'}>Cloud Computing</MenuItem>
          <MenuItem value={'Vermicomposting'}>Vermicomposting</MenuItem>
          <MenuItem value={'Cyber Crime'}>Cyber Crime</MenuItem>
          <MenuItem value={'CAD'}>CAD</MenuItem>
          <MenuItem value={'Data Mining'}>Data Mining</MenuItem>
          <MenuItem value={'Computer Networks'}>Computer Networks</MenuItem>
          <MenuItem value={'Kinematics & Theory of Machines'}>Kinematics & Theory of Machines</MenuItem>
          <MenuItem value={'Personality Development/Trainer'}>Personality Development/Trainer</MenuItem>
          <MenuItem value={'Others'}>Marketing</MenuItem>
          <MenuItem value={'Others'}>Buisness and Management</MenuItem>
          <MenuItem value={'Others'}>Finance</MenuItem>
          <MenuItem value={'Others'}>Operations</MenuItem>
          <MenuItem value={'Others'}>Others</MenuItem>
          
          
        </Select>
      </FormControl>
    </div>
      </Grid>
      
    </Grid>
  );
}
