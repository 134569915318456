import React ,{createContext, useContext, useState} from 'react';
import Divider from '@mui/material/Divider';
import { Grid, Box, Card, Typography, Button, IconButton } from '@mui/material';
import Firsttext from "./Firsttext";
import Secondmcq from "./Secondmcq";
import Thirdrange from "./Thirdrange";
import Forthdd from "./Forthdd";
import Fifthcc from "./Fifthcc";
import Heading from './Heading';
import { NavLink, useNavigate } from 'react-router-dom';
import ArrowBackOutlinedIcon from '@mui/icons-material/ArrowBackOutlined';
import JobInfo from '../images/JobInfo.png';

import DisabledPerks from '../images/DisabledPerks.png';
import sticon from "../images/Stepper Icon.png"


import styled from "@emotion/styled";
import Nomad from './Nomad.png';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import GoToTop from '../../Components/Gototop';
import Sixth from './Sixth';
import Seventh from './Seventh';
import { JobContext } from './Postjob';
import NewSyaapa3 from '../NehaBhagat1/NewSyaapa3';


export default function Combine() {
    
    const {job,setJob}=useContext(JobContext)
    const Typography1 = styled(Typography)({
        fontFamily: 'inter'
    })

    
    const navigate = useNavigate();
    return (
        

        <Grid container >
            
            <Grid container>
                

               
                <Grid item container sx={{ padding: "3% 1% 2% 2%" }} xs={12}>
                    <Heading />
                </Grid>
                <Divider orientation='horizontal' sx={{ fontSize: "6px", border: "0.5px solid rgba(214, 221, 235, 1)", width: "100%"}} />
                <Grid item container sx={{ padding: "2% 1% 2% 2%" }} xs={12}>
                    <Firsttext />
                </Grid>
                <Divider orientation='horizontal' sx={{ fontSize: "6px", border: "0.5px solid rgba(214, 221, 235, 1)", width: "100%"}} />
                <Grid item container sx={{ padding: "2% 1% 2% 2%" }} xs={12}>
                    <Secondmcq />
                </Grid>
                <Divider orientation='horizontal' sx={{ fontSize: "6px", border: "0.5px solid rgba(214, 221, 235, 1)", width: "100%"}} />
                <Grid item container sx={{ padding: "2% 1% 2% 2%" }} xs={12}>
                    <Thirdrange />
                </Grid>
                <Divider orientation='horizontal' sx={{ fontSize: "6px", border: "0.5px solid rgba(214, 221, 235, 1)", width: "100%"}} />
                <Grid item container sx={{ padding: "2% 1% 2% 2%" }} xs={12}>
                    <Forthdd />
                </Grid>
                <Divider orientation='horizontal' sx={{ fontSize: "6px", border: "0.5px solid rgba(214, 221, 235, 1)", width: "100%"}} />
                <Grid item container sx={{ padding: "2% 1% 2% 2%" }} xs={12}>
                    <Fifthcc />
                </Grid>
                <Divider orientation='horizontal' sx={{ fontSize: "6px", border: "0.5px solid rgba(214, 221, 235, 1)", width: "100%"}} />
                <Grid item container sx={{ padding: "2% 1% 2% 2%" }} xs={12}>
                    <Seventh />
                </Grid>
                <Divider orientation='horizontal' sx={{ fontSize: "6px", border: "0.5px solid rgba(214, 221, 235, 1)", width: "100%"}} />
                <Grid item container sx={{ padding: "2% 1% 2% 2%" }} xs={12}>
                    <Sixth />
                </Grid>
                <Divider orientation='horizontal' sx={{ fontSize: "6px", border: "0.5px solid rgba(214, 221, 235, 1)", width: "100%"}} />
                <Grid item container sx={{ padding: "2% 1% 2% 2%" }} xs={12}>
                  <NewSyaapa3/>
                  
                </Grid>
            </Grid>
            
           <GoToTop/>
        </Grid>
        
    );
}