import React from 'react';
import Paper from '@mui/material/Paper';
import { Grid, Typography, Card } from '@mui/material';
import { styled } from '@mui/material/styles';
import Table from "../NehaBhagat1/Table";
import People from './People.png';

const Item = styled(Paper)(({ theme }) => ({

    padding: theme.spacing(1),
    textAlign: 'left',
    boxShadow: "none"
}));
const Item1 = styled(Paper)(({ theme }) => ({

    padding: theme.spacing(1),
    textAlign: 'right',
    boxShadow: "none"

}));


const Content1 = ({ data }) => {

    return (
        <Grid container xs={12}>

            <Grid item xs={12} lg={12} >
                <Item sx={{ border: " 1px solid #D6DDEB" }} >
                    {data.map((item) => {
                        return (

                            <Grid display={'flex'} marginLeft={'3%'} padding={'3%'}>
                                <Grid>
                                    <Typography variant='h4' fontFamily={'poppins'} color={'#25324B'}><b>{item.fname} {item.lname}</b></Typography>
                                    <Typography variant='h6' fontFamily={'poppins'} color={'#25324B'}><b>{item.headline}</b></Typography><br />
                                    <Typography variant='body2' fontFamily={'poppins'} fontSize={'18px'} color={'#7C8493'}>Experience</Typography>
                                    {item.workArray?.map((item1) => {
                                        return (
                                            <>
                                                <Typography variant='body2' fontFamily={'poppins'} fontSize={'18px'} color={'#25324B'}> <b>Senior UI/UX Product Designer</b></Typography>
                                                <Typography variant='body2' fontFamily={'poppins'} fontSize={'18px'} color={'#25324B'}>{item1.company}</Typography>
                                                <Typography variant='body2' fontFamily={'poppins'} fontSize={'18px'} color={'#25324B'}>{item1.start} - {item1.end}</Typography>
                                                <Typography variant='body2' fontFamily={'poppins'} fontSize={'18px'} color={'#25324B'}>{item1.location}</Typography><br />
                                            </>
                                        )
                                    })}
                                    <Typography variant='body2' fontFamily={'poppins'} fontSize={'18px'} color={'#7C8493'}>Education</Typography>

                                    {item.educationArr?.map((item2) => {
                                        return (
                                            <>
                                                <Typography variant='body2' fontFamily={'poppins'} fontSize={'18px'} color={'#25324B'}><b>{item2.special}</b></Typography>
                                                <Typography variant='body2' fontFamily={'poppins'} fontSize={'18px'} color={'#25324B'}>{item2.inst}</Typography>
                                                <Typography variant='body2' fontFamily={'poppins'} fontSize={'18px'} color={'#25324B'}>{item2.start} - {item2.end}</Typography><br />

                                            </>

                                        )
                                    })}

                                </Grid>
                                <Grid marginLeft={'40px'}>
                                    <img src={item.imageFile} alt={'no image'} /><br /><br />
                                    <Typography variant='body2' fontFamily={'poppins'} fontSize={'18px'} color={'#7C8493'}>{item.email}</Typography>
                                    <Typography variant='body2' fontFamily={'poppins'} fontSize={'18px'} color={'#7C8493'}>{item.phone}</Typography>
                                    <Typography variant='body2' fontFamily={'poppins'} fontSize={'18px'} color={'#7C8493'}>{item.city}</Typography><br />

                                    <Typography variant='body2' fontFamily={'poppins'} fontSize={'18px'} color={'#7C8493'}>Training</Typography>
                                    {item.trainingArr?.map((item3) => {
                                        return (
                                            <>
                                                <Typography variant='body2' fontFamily={'poppins'} fontSize={'18px'} color={'#25324B'}><b>{item3.title}</b></Typography>
                                                <Typography variant='body2' fontFamily={'poppins'} fontSize={'18px'} color={'#25324B'}>{item3.organization}</Typography>
                                                <Typography variant='body2' fontFamily={'poppins'} fontSize={'18px'} color={'#25324B'}>{item3.description}</Typography>
                                                <Typography variant='body2' fontFamily={'poppins'} fontSize={'18px'} color={'#25324B'}>{item3.start} - {item3.end}</Typography><br />

                                            </>

                                        )
                                    })}

                                    <Typography variant='body2' fontFamily={'poppins'} fontSize={'18px'} color={'#7C8493'}>Achievments</Typography>
                                    {item.achivementsArr?.map((item4) => {
                                        return (
                                            <>
                                                <Typography variant='body2' fontFamily={'poppins'} fontSize={'18px'} color={'#25324B'}><b>{item4.title}</b></Typography>
                                                <Typography variant='body2' fontFamily={'poppins'} fontSize={'18px'} color={'#25324B'}>{item4.description}</Typography>
                                                
                                                <Typography variant='body2' fontFamily={'poppins'} fontSize={'18px'} color={'#25324B'}>{item4.start}</Typography><br />

                                            </>

                                        )
                                    })}

                                    <Typography variant='body2' fontFamily={'poppins'} fontSize={'18px'} color={'#7C8493'}>Hobbies</Typography>
                                    {item.hobbies?.map((item4) => {
                                        return (
                                            <>
                                                <Typography variant='body2' fontFamily={'poppins'} fontSize={'18px'} color={'#25324B'}><b>{item4.title}</b></Typography>
                                                

                                            </>

                                        )
                                    })}
                                    

                                    {/* <Typography variant='body2' fontFamily={'poppins'} fontSize={'18px'} color={'#7C8493'}>Social</Typography>
                                    <Typography variant='body2' fontFamily={'poppins'} fontSize={'18px'} color={'#7C8493'}>yoursite.com</Typography>
                                    <Typography variant='body2' fontFamily={'poppins'} fontSize={'18px'} color={'#7C8493'}>linkedin.com/in/yourname</Typography>
                                    <Typography variant='body2' fontFamily={'poppins'} fontSize={'18px'} color={'#7C8493'}>dribbble.com/yourname</Typography><br /> */}

                                </Grid>
                            </Grid>
                        )
                    })}
                </Item>
            </Grid>

        </Grid>
    )
}

export default Content1