import React, { createContext, useState, useEffect } from 'react';
import Dashboard from './Components/Dashborad';
import Incubation from './Components/Incubation';
import Placement from './Components/Placement';
import Reasearch from './Components/Reasearch';
import { BrowserRouter as Router, Routes, Route, useNavigate } from 'react-router-dom';
import ResearchFeed from './Namrata/ResearchFeed';
import ResearchDetails1 from './Namrata/ResearchDetails';
import HomePage2 from './Namrata/Incubation/components/HomePage2'
import IdeaCard from './Deepak/Po/IdeaCard';
import BasicCard from './VikramForms/Final';
import axios from 'axios';
import JobDescription from './jp/namrata/JobDescription';
import JobInformation from './jp/namrata/JobInformation';
import Cobine from './jp/NehaBhagat1/Cobine';
import Combine from './jp/Vaibhav/Combine'
import Applicants from './Components/Applicants';
import Verification from './VikramForms/Verification'
import NewPassword from './VikramForms/NewPassword';
import ForgotPasswordEmail from './VikramForms/ForgotPasswordEmail'
import BasicInfo from './CompanyProfile/BasicInfo';
import CompanyProfile from './CompanyProfile/CompanyProfile';
import CreateStudio from './Components/CreateStudio';
import ConfirmUserProfile from './VikramForms/ConfirmUserProfile';
import Postjobs from './jp/Vaibhav/Postjob';
import Privacypolicy from './VikramForms/Privacypolicy';
import Terms from './VikramForms/Terms';
import Termsout from './VikramForms/Termout';
import Privacout from './VikramForms/Privacyout';
// import Register from './Components/Register';

export const Context = createContext();
function App() {
  const [authState, setAuthState] = useState({
    authenticated: false,
    user: {},
  })
  useEffect(() => {
    const loggedInUser = localStorage.getItem("keewe.PO");
    if (loggedInUser) {
      const foundUser = JSON.parse(loggedInUser);
      console.log(foundUser)
      axios({
        //url: "https://lmsapi.keewesolutions.com/check",
        url: 'https://po.keewesolutions.com/check',
        method: "POST",
        data: {
          token: foundUser.user.idToken
        }
      }).then(res => {
        if (res.data.callStatusCode === 200) {
          let newAuthState = { ...authState }
          newAuthState.authenticated = true
          let newPayload = res.data.response
          let newUser = {
            id: newPayload['cognito:username'],
            name: newPayload.name,
            email: newPayload.email,
            phone: newPayload.phone,
            website: newPayload.website,
            idToken: foundUser.user.idToken,
            refreshToken: foundUser.user.refreshToken.token
          }
          newAuthState.user = newUser
          setAuthState(newAuthState)
          console.log("success check")
        } else {
          console.log("failed Check")
          //navigate('/login')
        }
      }).catch(e => console.log(e))


    }
  }, []);
  return (
    <Context.Provider value={{ authState, setAuthState }}>
      <Router>
        <Routes>
          {authState.authenticated ?
            <>
              <Route path="/" element={<Dashboard />} />
              {/* <Route path="/register" element={<Register/>}/> */}
              <Route path="/Dashboard" element={<Dashboard />} />
              <Route path="/Incubation" element={<Incubation />} />
              <Route path="/Research" element={<Reasearch />} />
              <Route path='/researchfeed' element={<ResearchFeed />} />
              <Route path='/home' element={<Reasearch />} />
              <Route path='/researchdetails' element={<ResearchDetails1 buttonText1='Re-Upload Image' buttonText2='Re-Upload PDF' />} />
              {/* <Route path= '/researchprofile' element = { <Researchinfo/>}/> */}
              <Route path='/allideas' element={<HomePage2 />} />
              <Route path='/incubhome' element={<Incubation />} />
              <Route path='/incubdetails' element={<IdeaCard />} />
              <Route path='/payment' element={<CompanyProfile />} />
              <Route path='/CMS' element={<CreateStudio />} />

              <Route path="/Incubation" element={<Incubation />} />
              <Route path="/Research" element={<Reasearch />} />
              <Route path='/researchfeed' element={<ResearchFeed />} />
              <Route path='/home' element={<Reasearch />} />
              <Route path='/researchdetails' element={<ResearchDetails1 buttonText1='Re-Upload Image' buttonText2='Re-Upload PDF' />} />
              {/* <Route path= '/researchprofile' element = { <Researchinfo/>}/> */}
              <Route path='/allideas' element={<HomePage2 />} />
              <Route path='/incubhome' element={<Incubation />} />
              <Route path='/incubdetails' element={<IdeaCard />} />
              <Route path='/companyprofile' element={<CompanyProfile />} />
              <Route path='/CMS' element={<CreateStudio />} />
              <Route path='/confirm' element={<ConfirmUserProfile />} />
              <Route path='/Jobportal' element={<Placement />} />
              <Route path='/postajob' element={<Postjobs />} />
              {/* <Route path='/step2' element = {<JobDescription/>}/> */}
              <Route path='/jobinformation' element={<JobInformation />} />
              <Route path='/applicants' element={<Applicants />} />
              <Route path='/application' element={<Cobine />} />
              <Route path='/privacypolicy' element={<Privacypolicy />} />
              <Route path='/termsandcondition' element={<Terms />} />
            </>
            :
            <>
              <Route path="/" element={<BasicCard id={1} />} />
              <Route path="/register" element={<BasicCard id={0} />} />
              <Route path="/login" element={<BasicCard id={1} />} />
              <Route path="/verify" element={<Verification />} />
              <Route path="/forgotpassword" element={<ForgotPasswordEmail />} />
              <Route path="/changepassword" element={<NewPassword />} />
              <Route path='/privacyPolicy' element={<Privacout />} />
              <Route path='/termscondition' element={<Termsout />} />
            </>}
        </Routes>
      </Router>

    </Context.Provider>




  );
}

export default App;
