import React, { useContext } from 'react';
import { Typography, Grid, Button, IconButton, Box, TextField } from '@mui/material';
import NotificationsActiveOutlinedIcon from '@mui/icons-material/NotificationsActiveOutlined';
import ArrowBackOutlinedIcon from '@mui/icons-material/ArrowBackOutlined';
import JobInfo from '../images/JobInfo.png';
import JobDesc from '../images/JobDesc.png';
import DisabledPerks from '../images/DisabledPerks.png';
import Divider from '../images/Divider.png'
import { NavLink, useNavigate } from 'react-router-dom';
import TextDescription from './TextDescription';
import styled from "@emotion/styled";
import Nomad from './Nomad.png';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import GoToTop from '../../Components/Gototop';
import { JobContext } from '../Vaibhav/Postjob';
import { useEffect,useState } from "react";    
const Typography1 = styled(Typography)({
  fontFamily: 'inter'
})


export default function JobDescription() {
 const {job,setJob} = useContext(JobContext)
 const [formErrors, setFormErrors] = useState({});
 const [isSubmit, setIsSubmit] = useState(false);
 const handleSubmit = (e) => {
  e.preventDefault();
  setFormErrors(validate(job));
};
useEffect(() => {
  console.log(formErrors);
  if (Object.keys(formErrors).length === 0 && isSubmit) {
    console.log(job);
  }
}, [formErrors]);
const validate = (job) => {
  const errors = {};
  if (!job.Description) {
    errors.Description = "Please enter Description";
  }
  if (!job.Resposibility) {
    errors.Resposibility = "Please enter Responsibility";
  }
  if (!job.Whoareyou) {
    errors.Whoareyou = "Please enter Whoareyou";
  }
  if (!job.Nicetohaves) {
    errors.Nicetohaves = "Please enter Nicetohaves";
  }
  return errors;
};
  const navigate = useNavigate();

  return (

    <Grid container >

  
     <Grid container sx={{ margin: '1% 5%' }}>
      
          <Grid item xs={12}>
            <Typography1 variant="h6" sx={{ color: '#001A49' }}><b>
              Add details
            </b>
            </Typography1>
          </Grid>

          {/* <Grid item xs={4}>
            <Typography1 sx={{ color: '#7c8493' }}>
              Add the description of the job, responsibilities, who you are and nice-to-haves.
            </Typography1>
          </Grid> */}

          <Grid item xs={12} sx={{ marginTop: '1%' }}>
            <hr />
          </Grid>

        </Grid>


        <Grid container sx={{ margin: '1% 5%' }}>
          <Grid item xs={12} sx={{ marginBottom: '1%' }}>
            <Typography1><b>
              Job Descriptions
            </b>
            </Typography1>
          </Grid>




          <Grid container>

            <Grid item xs={12} lg={5}>
              <Typography1 sx={{ color: '#7c8493' }}>
                Job titles must describe one <br />
                position.
              </Typography1>
            </Grid>

            <Grid item xs={12} lg={7} sx={{ marginBottom: '2%' }}>
              <TextField required
                fullWidth
                placeholder="Start writing Description here"
                id="fullWidth"
                rows={4}
                multiline
                value={job.Description}
                onChange={e => setJob({ ...job, Description: e.target.value })}
              />
              <p style={{ color: "red" }}>{formErrors.Description}</p>
            </Grid>

            <Grid item xs={12}>
              <hr />
            </Grid>

          </Grid>
        </Grid>



        <Grid container sx={{ margin: '1% 5%' }}>
          <Grid item xs={12} sx={{ marginBottom: '1%' }}>
            <Typography1><b>
              Responsibilities
            </b>
            </Typography1>
          </Grid>




          <Grid container>

            <Grid item xs={12} lg={5}>
              <Typography1 sx={{ color: '#7c8493' }}>
                Outline the core responsibilities<br />
                of the position.
              </Typography1>
            </Grid>

            <Grid item xs={12} lg={7} sx={{ marginBottom: '2%' }}>
            <TextField required
                fullWidth
                placeholder="Start writing Responsibilities here"
                id="fullWidth"
                rows={4}
                multiline
                value={job.Resposibility}
                onChange={e => setJob({ ...job, Resposibility: e.target.value })}
              />
               <p style={{ color: "red" }}>{formErrors.Resposibility}</p>
            </Grid>

            <Grid item xs={12}>
              <hr />
            </Grid>

          </Grid>
        </Grid>


        <Grid container sx={{ margin: '1% 5%' }}>
          <Grid item xs={12} sx={{ marginBottom: '1%' }}>
            <Typography1><b>
              Who You Are
            </b>
            </Typography1>
          </Grid>




          <Grid container>

            <Grid item xs={12} lg={5}>
              <Typography1 sx={{ color: '#7c8493' }}>
                Add your preferred candidates<br />
                qualifications.
              </Typography1>
            </Grid>

            <Grid item xs={12} lg={7} sx={{ marginBottom: '2%' }}>
            <TextField required
                fullWidth
                placeholder="Start writing here...."
                id="fullWidth"
                rows={4}
                multiline
                value={job.Whoareyou}
                onChange={e => setJob({ ...job, Whoareyou: e.target.value })}
              />
               <p style={{ color: "red" }}>{formErrors.Whoareyou}</p>
            </Grid>

            <Grid item xs={12}>
              <hr />
            </Grid>

          </Grid>
        </Grid>

        <Grid container sx={{ margin: '1% 5%' }}>
          <Grid item xs={12} sx={{ marginBottom: '1%' }}>
            <Typography1><b>
              Nice-To-Haves
            </b>
            </Typography1>
          </Grid>




          <Grid container>

            <Grid item xs={12} lg={5}>
              <Typography1 sx={{ color: '#7c8493' }}>
                Add nice-to-have skills and<br />
                qualifications for the role to<br />
                encourage a more diverse set of<br />
                candidates to apply.
              </Typography1>
            </Grid>

            <Grid item xs={12} lg={7} sx={{ marginBottom: '2%' }}>
            <TextField required
                fullWidth
                placeholder="Start writing here...."
                id="fullWidth"
                rows={4}
                multiline
                value={job.Nicetohaves}
                onChange={e => setJob({ ...job, Nicetohaves: e.target.value })}
              />
               <p style={{ color: "red" }}>{formErrors.Nicetohaves}</p>
            </Grid>
            <Grid xs={12} sx={{justifyContent:'end',display:'flex'}}>
               <Button variant="contained" onClick={handleSubmit} >
              Upload
            </Button>
            </Grid>
           
            <Grid item xs={12}>
              <hr />
            </Grid>

          </Grid>


          
        </Grid>

      <GoToTop />


     

    </Grid>
  )
}