import * as React from "react";
import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";
import { Grid, Typography } from "@mui/material";
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import LocalizationProvider from '@mui/lab/LocalizationProvider'
import DatePicker from '@mui/lab/DatePicker';
import DesktopDatePicker from "@mui/lab/DesktopDatePicker";
import { JobContext } from "./Postjob";

export default function Seventh() {
  const {job,setJob}=React.useContext(JobContext)
  const [EndDate,setenddate]=React.useState('')
  // const handleChange = (newValue) => {
  //   ;}
  return (
    <Grid item container xs={12} spacing={1} sx={{display: 'flex'}}>
      <Grid item container xs={12} sm={5} md={5}>
        <Grid item xs={12}>
          <Typography
            sx={{
              fontFamily: "Epilogue",
              fontStyle: "normal",
              fontWeight: "600",
              fontSize: "18px",
            }}
          >
            End Date
          </Typography>
        </Grid>
        <Grid xs={12}>
          <Typography
            sx={{
              color: "#7C8493",
              fontFamily: "Epilogue",
              fontStyle: "normal",
              fontWeight: "400",
              fontSize: "18px",
            }}
          >
            Application close date.
          </Typography>
        </Grid>
      </Grid>
      {/* <Grid item container sm={1} md={1}></Grid> */}
      <Grid item container xs={12} sm={6} md={6} sx={{display: 'block'}}>
        <Box
          sx={{
            width: 500,
            maxWidth: "100%",
          }}
        >
            <LocalizationProvider dateAdapter={AdapterDateFns}>
            <DesktopDatePicker
                        label="End Date(Use Calender)"
                        inputFormat="MM/dd/yyyy"
                        value={EndDate}
                        onChange={e=>{setenddate(e)
                          setJob({...job,EndDate:e.getDate() + "/" +e.getMonth() + "/" + e.getFullYear()})
                        }}
                        renderInput={(params) => <TextField {...params} />}
                      />
      {/* <DatePicker 
        openTo="year"
        value={job.EndDate}
        onChange={e => {
          
          setJob({...job,EndDate:e.getDate() + "/"+ parseInt(e.getMonth()+1) +"/"+e.getFullYear()})

        }}
        renderInput={(params) => <TextField required {...params} />}
      /> */}
    </LocalizationProvider>
        </Box>
      </Grid>
      <Grid item container lg={3}></Grid>
    </Grid>
  );
    }
