import { Grid,Stack,Box,Alert } from "@mui/material";
import Footer from "../Deepak/Footer";
import Hheader from "../Vaibhav/Header";

import IncubFilter from "../prashant/Components/IncubFilter";
import Page4 from '../Rohan/Page4'
import IncubationPo from "../prashant/Components/IncubationPo";
import HomePage1 from "../Namrata/Incubation/components/HomePage1";
import IncubationCard from "../Namrata/Incubation/components/IncubationCard";
import GoToTop from "./Gototop";
import Footer1 from '../Vikram/Footer1'

const Incubation=() =>{
    return(<Grid overflowX="hidden" margin={'0.1%'}>
       <Hheader/>
       <HomePage1/>
       {/* 
       <IncubationCard/>
       
       <Footer1/> */}
       <Stack sx={{ width: '100%',fontSize:"20px",justifyContent:"center",align:"center",marginTop:'30px',marginBottom:'30px'}} spacing={2}>
        <Box sx={{fontSize:"20px"}}>
      <Alert severity="success" sx={{fontSize:"20px",justifyContent:'center'}}>Coming Soon...</Alert>
    </Box></Stack>
       <GoToTop/>
       </Grid>
    )
}
export default Incubation;