import React, { useState } from "react";


// import Footer1 from "../Vikram/Footer1";
import Footer1 from '../Vikram/Footer1'
import Hheader from "../Vaibhav/Header";
import GoToTop from "./Gototop";
import AnimationGrid from "./AnikmationGrid";
import Fstcntnr from "./Fstcntnr";




export default function CreateStudio() {


            
            return (
<>
<Hheader/>
                {/* <Nav/> */}
              <Fstcntnr/>
               <AnimationGrid/>
              <Footer1/>
              <GoToTop/>
            
</>
             

            )
        }
    
  
       