import React, { useContext, useEffect, useState } from "react";
import "./feed.css";
import { Grid, Typography, ImageList, Button } from "@mui/material";
import styled from "@emotion/styled";
import ResearchCard from "./ResearchCard";
import SearchField from "./SearchField";
import TextField from '@mui/material/TextField';
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import DesktopDatePicker from '@mui/lab/DesktopDatePicker';
import Plant from './images/Plant.png';
import Automation from './images/Automation.png';
import Brain from './images/Brain.png';
import ModalPlant from './images/ModalPlant.png';
import axios from 'axios';




import { NavLink, useNavigate } from "react-router-dom";
import GoToTop from "../Components/Gototop";
import { Context } from "../App";
import Hheader from "../Vaibhav/Header";
import Footer from "../Vikram/Footer1";


const Button1 = styled(Button)({
  color: '#1a50b2'
})



const Typography1 = styled(Typography)({
  color: "white",
  marginTop: "20px",
});


export default function ResearchFeed() {
  const { authState } = useContext(Context);
  const User = authState.user;
  const [data, setData] = useState([]);
  const [value, setValue] = React.useState(new Date('2014-08-18T21:11:54'));

  const handleChange = (newValue) => {
    setValue(newValue);
  };

  useEffect(async () => {
    const res = await axios({
      url: 'https://po.keewesolutions.com/research/reflectcompany',
      method: 'post',
      data: {
        companyId: User.id,
      }
    });
    setData(res.data.Items);
    console.log(res.data.Items)
  }, [])
  useEffect(() => {
    console.log(data);
  })

  return (

    <Grid container>
      <Hheader />

      <Grid
        container
        className="back-image"
        sx={{ width: "100%", height: "auto" }}
      >

        <Grid item xs={12} lg={12} className='text-grid'>
          <Grid sx={{ height: '200px' }}>
            <Typography1 sx={{ fontSize: "48px", fontFamily: 'Inter', padding: '3% ', textAlign: 'center' }}>
              <b>Research Feed</b>
            </Typography1>
          </Grid>
        </Grid>
      </Grid>

      <Grid container sx={{ margin: '2% 3%' }}>
        <Grid item xs={12} lg={4} sx={{ display: 'flex', marginBottom: '2%' }}>
          <Typography sx={{ marginRight: '4%', marginTop: '2%', fontFamily: 'poppins' }}><b>Sort By:</b></Typography>
          <LocalizationProvider dateAdapter={AdapterDateFns}>

            <DesktopDatePicker
              label="Date Created"
              inputFormat="MM/dd/yyyy"
              value={value}

              onChange={handleChange}
              renderInput={(params) => <TextField {...params} />}
            />
          </LocalizationProvider>
        </Grid>

        <Grid item xs={12} lg={7} sx={{ marginLeft: '1%', marginBottom: '2%' }} >
          <SearchField />
        </Grid>


      </Grid>

      <Grid item xs={12} sx={{ display: 'flex', overflowX: 'hidden' }}>
        <Grid item xs={12} sx={{ marginLeft: '1%' }}>
          <ImageList className='scroll' sx={{ display: "flex" }} >
            {data.map((val, index) => {
              return <ResearchCard key={index}
                discription={val.discription}
                topic={val.topic}
                date={val.date}
                allowance={val.allowance}
                thumbnail={val.thumbnail}
                companyName={val.companyName}
                deadline={val.deadline}
                guidelines={val.guidelines}
              />
            })}
          </ImageList>
        </Grid>
      </Grid>
      <GoToTop />
      <Footer />
    </Grid >

  )
}



