import React from 'react';
import { NavLink } from "react-router-dom"
import { Typography, Grid, Box } from '@mui/material';
import KeyboardDoubleArrowRightIcon from '@mui/icons-material/KeyboardDoubleArrowRight';
import logo from '../VikramForms/mrsptu.png'
const Footer = () => {
   return (

      <Grid container sx={{ marginTop: '10px', padding: "1% 0 2% 0", boxShadow: "none", width: '100%', backgroundColor: '#06213f' }}>
         <Grid item xs={2} sm={0.5} />
         <Grid item xs={8} sm={3} sx={{ marginTop: '40px' }}>
            <Box ><img src={logo} style={{ width: '100px', height: '100px' }} alt="ewrt" /></Box>
            <Grid container sx={{ marginTop: '15px' }}>
               <Typography variant="h4" sx={{ color: '#fff' }}><b>Partnered Organization</b></Typography></Grid>
            <Typography sx={{ color: '#D6DDEB', marginTop: '5px' }}>For MRSPTU online certification courses</Typography>
         </Grid>

         <Grid item xs={1} sm={0.5} />
         <Grid container item xs={12} sm={8} sx={{ display: 'flex', marginTop: '40px' }}>

            <Grid container Item xs={12} sm={6}>
               <Grid item xs={1} sm={0.5} />
               <Grid item xs={4} sm={5} >
                  <Typography variant="h4" paddingBottom="10px" color={"#FFFFFF"} sx={{ fontSize: '27px', fontWeight: '600', fontFamily: 'Poppins' }}>Quick Links</Typography>
                  <NavLink to={'/Incubation'} style={{ textDecoration: 'none' }}>
                     <div style={{ display: 'flex' }}><KeyboardDoubleArrowRightIcon sx={{ color: '#75ffd8' }} /><Typography sx={{ color: '#FFFFFF', fontSize: '22px', fontWeight: '400', letterSpacing: '0.3px', fontFamily: 'Poppins', "&:hover": { textDecoration: "underline" } }}>Incubation</Typography></div>
                  </NavLink>
                  <NavLink to={'/Research'} style={{ textDecoration: 'none' }}>
                     <div style={{ display: 'flex' }}><KeyboardDoubleArrowRightIcon sx={{ color: '#75ffd8' }} /><Typography sx={{ color: '#FFFFFF', fontSize: '22px', fontWeight: '400', letterSpacing: '0.3px', fontFamily: 'Poppins', "&:hover": { textDecoration: "underline" } }}>Research</Typography></div>
                  </NavLink>
                  <NavLink to={'/Jobportal'} style={{ textDecoration: 'none' }}>
                     <div style={{ display: 'flex' }}><KeyboardDoubleArrowRightIcon sx={{ color: '#75ffd8' }} /><Typography sx={{ color: '#FFFFFF', fontSize: '22px', fontWeight: '400', letterSpacing: '0.3px', fontFamily: 'Poppins', "&:hover": { textDecoration: "underline" } }}>Placement</Typography></div>
                  </NavLink>
                  <NavLink to={'/CMS'} style={{ textDecoration: 'none' }}>
                     <div style={{ display: 'flex' }}><KeyboardDoubleArrowRightIcon sx={{ color: '#75ffd8' }} /><Typography sx={{ color: '#FFFFFF', fontSize: '22px', fontWeight: '400', letterSpacing: '0.3px', fontFamily: 'Poppins', "&:hover": { textDecoration: "underline" } }}>Create Studio</Typography></div>
                  </NavLink>
               </Grid>

               <Grid item xs={1} sm={0.5} />
               <Grid item xs={5} sm={5.5} >
                  <Typography variant="h4" paddingBottom="10px" color={"#FFFFFF"} sx={{ fontSize: '27px', fontWeight: '600', fontFamily: 'Poppins' }}>Help Menu</Typography>
                  <div style={{ display: 'flex' }}><KeyboardDoubleArrowRightIcon sx={{ color: '#75ffd8' }} /><Typography sx={{ color: '#FFFFFF', fontSize: '22px', fontWeight: '400', letterSpacing: '0.3px', fontFamily: 'Poppins', "&:hover": { textDecoration: "underline" }, cursor: 'pointer' }}>Help and Support</Typography></div>
                  <NavLink to={'/privacypolicy'} style={{ textDecoration: 'none' }}>
                     <div style={{ display: 'flex' }}><KeyboardDoubleArrowRightIcon sx={{ color: '#75ffd8' }} /><Typography sx={{ color: '#FFFFFF', fontSize: '22px', fontWeight: '400', letterSpacing: '0.3px', fontFamily: 'Poppins', "&:hover": { textDecoration: "underline" }, cursor: 'pointer' }}>Privacy Policy</Typography></div>
                  </NavLink>
                  <NavLink to={'/termsandcondition'} style={{ textDecoration: 'none' }}>
                     <div style={{ display: 'flex' }}><KeyboardDoubleArrowRightIcon sx={{ color: '#75ffd8' }} /><Typography sx={{ color: '#FFFFFF', fontSize: '22px', fontWeight: '400', letterSpacing: '0.3px', fontFamily: 'Poppins', "&:hover": { textDecoration: "underline" }, cursor: 'pointer' }}>Terms and Conditons</Typography></div>
                  </NavLink>
               </Grid>
            </Grid>
            <Grid item xs={1} sm={0.5} />
            <Grid item xs={9} sm={5} >
               <Typography variant="h4" paddingBottom="10px" color={"#FFFFFF"} sx={{ fontSize: '27px', fontWeight: '600', fontFamily: 'Poppins' }}>Contact Us</Typography>
               <div style={{ display: 'flex' }}><KeyboardDoubleArrowRightIcon sx={{ color: '#75ffd8' }} /><Typography sx={{ color: '#FFFFFF', fontSize: '22px', fontWeight: '400', letterSpacing: '0.3px', fontFamily: 'Poppins', "&:hover": { textDecoration: "underline" }, cursor: 'pointer' }}>Phone: +91-9875944053</Typography></div>
               <div style={{ display: 'flex' }}><KeyboardDoubleArrowRightIcon sx={{ color: '#75ffd8' }} /><Typography sx={{ color: '#FFFFFF', fontSize: '22px', fontWeight: '400', letterSpacing: '0.3px', fontFamily: 'Poppins', "&:hover": { textDecoration: "underline" }, cursor: 'pointer' }}><a href='mailto:po@mrsptuonline.com' style={{textDecoration: 'none', color: '#fff'}}>Email-po@mrsptuonline.com</a></Typography></div>
               <div style={{ display: 'flex' }}><KeyboardDoubleArrowRightIcon sx={{ color: '#75ffd8' }} /><Typography sx={{ color: '#FFFFFF', fontSize: '22px', fontWeight: '400', letterSpacing: '0.3px', fontFamily: 'Poppins', "&:hover": { textDecoration: "underline" }, cursor: 'pointer' }}>Maharaja Ranjit Singh <br />Punjab Technical University<br/>Dabwali Road Bathinda, <br />Punjab, India- 151001</Typography></div>
               {/* <div style={{ display: 'flex' }}><KeyboardDoubleArrowRightIcon sx={{ color: '#75ffd8' }} /><Typography sx={{ color: '#FFFFFF', fontSize: '22px', fontWeight: '400', letterSpacing: '0.3px', fontFamily: 'Poppins', "&:hover": { textDecoration: "underline" }, cursor: 'pointer' }}></Typography></div> */}

            </Grid>
         </Grid>

         <Grid container item xs={12} sm={12} sx={{ marginTop: '40px' }}>
            <Grid item xs={1} sm={0.5} />
            <Grid item sm={6} xs={10}>
               <Typography sx={{ color: '#D6DDEB', fontSize: '12px', fontWeight: '500', textAlign: 'left', marginBottom: '20px' }}>
                  © Copyright MRSPTU Online 2022, Powered by KEEWE | Technology Partner | Crest Bell Support Pvt. Ltd.

               </Typography>
            </Grid>
         </Grid>
      </Grid >
   )
}

export default Footer