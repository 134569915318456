import * as React from "react";
import { useState } from 'react';
import Box from '@mui/material/Box';

import { Grid, Typography,TextField } from "@mui/material";

import { JobContext } from "./Postjob";


      
export default function Thirdrange() {

  const {job,setJob} = React.useContext(JobContext)


  return (
    <Grid item container spacing={2} sx={{display: 'flex'}}>
      <Grid item xs={12} sm={5} lg={3}>
        <Typography
          sx={{
            fontFamily: "Epilogue",
            fontStyle: "normal",
            fontWeight: "600",
            fontSize: "18px",
          }}
        >
          Salary
        </Typography>

        <br />
        <Typography
          sx={{
            color: "#7C8493",
            fontFamily: "Epilogue",
            fontStyle: "normal",
            fontWeight: "400",
            fontSize: "18px",
            width: "auto",
            justifyContent:"flex-start",
            alignItems: 'center'
            
          }}
        >
         Please specify the estimated salary per annum for the role. *In case of internship please specify stipend per month.
        </Typography>
      </Grid>
      <Grid item lg={2} sm={1}/>
      <Grid item lg={5} xs={12} sm={6}>
      <Grid item xs={12} sx={{ display: 'flex'}}>
 
      <TextField 
          fullWidth 
          placeholder="e.g. 5000" 
          type= "text"
          id="fullWidth"
          value={job.salary}
          onChange={e=>setJob({...job,salary:e.target.value})}
          />
       
         {/* <Typography> to</Typography>
        <FormControl  sx={{  width:"auto" }}>
          <InputLabel htmlFor="outlined-adornment-amount">Amount</InputLabel>
          <OutlinedInput
            id="outlined-adornment-amount"
            value={value1[1]}
            onChange={handleChange('amount')}
            startAdornment={<InputAdornment position="start" >Rs.</InputAdornment>}
            label="Amount"
          />
        </FormControl> */}
     
    </Grid>
      </Grid>
    </Grid>
  );
}

