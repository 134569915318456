
import React,{useState,useEffect} from 'react';
import { Grid, Typography,Button,Box,Autocomplete,TextField } from '@mui/material';
import Modal from '@mui/material/Modal';
import bg from './images/bg.png';
import Grid1 from './images/unnamed.png';
import './fstcntnr.css';
import Dekstop from './images/Desktop2.png';
import Hand_1 from './images/Hand_1.png';
import Hand_ from './images/Hand_.png';
import { NavLink } from 'react-router-dom';

function img2() {
    var img2 = document.querySelectorAll(".img2");
  
    for (var i = 0; i < img2.length; i++) {
      var windowHeight = window.innerHeight;
      var elementTop = img2[i].getBoundingClientRect().top;
      var elementVisible = 150;
  
      if (elementTop < windowHeight - elementVisible) {
        img2[i].classList.add("active");
      } else {
        img2[i].classList.remove("active");
      }
    }
  }
  
  window.addEventListener("scroll", img2);

  function img1() {
    var img1 = document.querySelectorAll(".img1");
  
    for (var i = 0; i < img1.length; i++) {
      var windowHeight = window.innerHeight;
      var elementTop = img1[i].getBoundingClientRect().top;
      var elementVisible = 150;
  
      if (elementTop < windowHeight - elementVisible) {
        img1[i].classList.add("active");
      } else {
        img1[i].classList.remove("active");
      }
    }
  }
  
  window.addEventListener("scroll", img1);

  function img4() {
    var img4 = document.querySelectorAll(".img4");
  
    for (var i = 0; i < img4.length; i++) {
      var windowHeight = window.innerHeight;
      var elementTop = img4[i].getBoundingClientRect().top;
      var elementVisible = 150;
  
      if (elementTop < windowHeight - elementVisible) {
        img4[i].classList.add("active");
      } else {
        img4[i].classList.remove("active");
      }
    }
  }
  
  window.addEventListener("scroll", img4);

  function img5() {
    var img5 = document.querySelectorAll(".img5");
  
    for (var i = 0; i < img5.length; i++) {
      var windowHeight = window.innerHeight;
      var elementTop = img5[i].getBoundingClientRect().top;
      var elementVisible = 150;
  
      if (elementTop < windowHeight - elementVisible) {
        img5[i].classList.add("active");
      } else {
        img5[i].classList.remove("active");
      }
    }
  }
  
  window.addEventListener("scroll", img5);
  const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 350,
    bgcolor: 'background.paper',
    border: '2px solid white',
    boxShadow: 24,
    p: 4,
  };

const Fstcntnr = () => {

  const initialValues = {
    Name: "",
    Interestedfield: "",
    coursename: "",
    Industryexperience: "",
    Email: "",
    Contactnumber: ""
};
const [formValues, setFormValues] = useState(initialValues);
const [formErrors, setFormErrors] = useState({});
const [isSubmit, setIsSubmit] = useState(false);

const handleChange = (e) => {
    const { name, value } = e.target;
    setFormValues({ ...formValues, [name]: value });
    console.log(formValues);
};

const handleSubmit = (e) => {
    e.preventDefault();
    setFormErrors(validate(formValues));
    setIsSubmit(true);
};
useEffect(() => {
    console.log(formErrors);
    if (Object.keys(formErrors).lenght === 0 && isSubmit) {
        console.log(formValues);
    }
}, [formErrors]);
const validate = (values) => {
    const errors = {};
    const regex = /^[^\s@]+@[^\s@]+\.[^\s@]{2,}$/i;

     if (!regex.test(values.Email)) {
        errors.Email = "This is not a valid email format!";
    }
    if (values.Contactnumber.length < 10) {
        errors.Contactnumber = "Mobile number must be of 10 digits";
    } else if (values.Contactnumber.length > 10) {
        errors.Contactnumber = "Mobile number must be of 10 digits";
    }
    return errors;
};



const [open, setOpen] = React.useState(false);
const handleOpen = () => setOpen(true);
const handleClose = () => setOpen(false);
  return <>
      <Grid container >
          <Grid style={{padding:'7%'}} xs={6} md={6} sm={6}>
            <Typography variant='h1' style={{fontWeight:'700'}}>
                Welcome
            </Typography>
            <Typography variant='h1' style={{fontWeight:'700'}}>
            to Course
            </Typography>
            <Typography variant='h1' style={{fontWeight:'700'}}>
            Studio
            </Typography>
            <Typography width={'80%'} padding={'2%'}>
            Create your Course and Start Earning.
            </Typography>
            <Grid paddingTop={'4%'}>
            
            <Button variant='contained' sx={{
              borderRadius: "24px"
            }} onClick={handleOpen}>
                Skip Tour and go to studio
            </Button>
           
            </Grid>
          </Grid>
          <Grid className='anime' lg={6} xs={6}  md={6} sm={6} padding={'3%'}>
                <div className='img1'>
                <img src={bg} alt="gh" />
                </div>
                <div>
                <img src={Grid1} alt='hu' className='img2'/>
                </div>
                <div>
                    <img src={Dekstop} alt='' className='img3' />
                </div>
                <div>
                <img src={Hand_1} alt='' className='img4' />
                </div>
                <img src={Hand_} alt='' className='img5' />
          </Grid>
          <Grid item xs={12} lg={8} md={8} sm={8}>
                    <Modal
                        open={open}
                        onClose={handleClose}
                        aria-labelledby="modal-modal-title"
                        aria-describedby="modal-modal-description"
                    >
                        <Box sx={style}>
                            <form onSubmit={handleSubmit}>
                                <Typography sx={{
                                    fontWeight: "500",
                                    fontSize: "18px",
                                    fontFamily: 'poppins'
                                }}>
                                    Name :
                                </Typography>
                                <input
                                    type="text"
                                    name="Name"
                                    value={formValues.Name}
                                    onChange={handleChange}

                                    style={{ width: "100%", height: '40px', border: '1px solid rgba(0, 0, 0, 0.23)', borderRadius: "4px", fontSize: '16px', color: 'rgba(0, 0, 0, 0.87)', fontWeight: '400' }}
                                />
                                <Typography sx={{
                                    fontWeight: "500",
                                    fontSize: "18px",
                                    fontFamily: 'poppins',
                                    marginTop: '4%'
                                }}>
                                    Interested Field :
                                </Typography>
                                <Autocomplete

                                    disablePortal
                                    id="combo-box-demo"
                                    options={interestedfield}

                                    renderInput={(params) => (
                                        <TextField
                                            {...params}
                                            size="small"
                                            label=""
                                            type="text"
                                            name="Interestedfield"
                                            value={formValues.Interestedfield}
                                            onChange={handleChange}

                                            sx={{ fontSize: '16px', color: 'rgba(0, 0, 0, 0.87)', fontWeight: '400', width: '102%' }}
                                        />
                                    )}
                                />
                                <Typography sx={{
                                    fontWeight: "500",
                                    fontSize: "18px",
                                    fontFamily: 'poppins',
                                    marginTop: '4%'
                                }}>
                                    Course Name :
                                </Typography>
                                <Autocomplete

                                    disablePortal
                                    id="combo-box-demo"
                                    options={coursename}

                                    renderInput={(params) => (
                                        <TextField
                                            {...params}
                                            size="small"
                                            label=""
                                            type="text"
                                            name="Coursename"
                                            value={formValues.coursename}
                                            onChange={handleChange}

                                            sx={{ fontSize: '16px', color: 'rgba(0, 0, 0, 0.87)', fontWeight: '400', width: '102%' }}
                                        />
                                    )}
                                />
                                <Typography sx={{
                                    fontWeight: "500",
                                    fontSize: "18px",
                                    fontFamily: 'poppins',
                                    marginTop: '4%'
                                }}>
                                    Industry Experience :
                                </Typography>
                                <Autocomplete

                                    disablePortal
                                    id="combo-box-demo"
                                    options={experience}

                                    renderInput={(params) => (
                                        <TextField
                                            {...params}
                                            size="small"
                                            label=""
                                            type="text"
                                            name="Industryexperience"
                                            value={formValues.Industryexperience}
                                            onChange={handleChange}

                                            sx={{ fontSize: '16px', color: 'rgba(0, 0, 0, 0.87)', fontWeight: '400', width: '102%' }}
                                        />
                                    )}
                                />
                                <Typography sx={{
                                    fontWeight: "500",
                                    fontSize: "18px",
                                    fontFamily: 'poppins',
                                    marginTop: '4%'
                                }}>
                                    Email :
                                </Typography>
                                <input
                                    type="text"
                                    name="Email"
                                    value={formValues.Email}
                                    onChange={handleChange}

                                    style={{ width: "100%", height: '40px', border: '1px solid rgba(0, 0, 0, 0.23)', borderRadius: "4px", fontSize: '16px', color: 'rgba(0, 0, 0, 0.87)', fontWeight: '400' }}
                                />
                                <p style={{ color: "red" }}>{formErrors.Email}</p>
                                <Typography sx={{
                                    fontWeight: "500",
                                    fontSize: "18px",
                                    fontFamily: 'poppins',
                                    marginTop: '4%'
                                }}>
                                    Contact Number :
                                </Typography>
                                <input
                                    type="text"
                                    name="Contactnumber"
                                    placeholder="9999999999"
                                    value={formValues.Contactnumber}
                                    onChange={handleChange}

                                    style={{ width: "100%", height: '40px', border: '1px solid rgba(0, 0, 0, 0.23)', borderRadius: "4px", fontSize: '16px', color: 'rgba(0, 0, 0, 0.87)', fontWeight: '400' }}
                                />
                                <p style={{ color: "red" }}>{formErrors.Contactnumber}</p>
                                <Grid display={"flex"} justifyContent={"end"} marginTop={"4%"}>
                                    <Button variant="contained" onClick={handleSubmit} >
                                        Submit
                                    </Button>
                                </Grid>
                            </form>

                        </Box>
                    </Modal>
                </Grid>
      </Grid>
  </>;
};

export default Fstcntnr;
const interestedfield = [
  { label: "IT" },
  { label: "CSE" },
  { label: "CSE" },
  { label: "ECE" },
];
const coursename = [
  { label: "IT" },
  { label: "CSE" },
  { label: "CSE" },
  { label: "ECE" },
];
const experience = [
  { label: "Fresher" },
  { label: "2 years" },
  { label: "5 years" },
  { label: "7 years" },
];