import { Grid } from "@mui/material";
import Footer from "../Deepak/Footer";
import Hheader from "../Vaibhav/Header";
import IncubFilter from "../prashant/Components/IncubFilter";
import MocPreview from "../prashant/Components/MocPreview";
import SLE from '../Neha/SLE'
import Page5 from '../Rohan/Page5'
import PlacementPo from "../prashant/Components/PlacementPo";
import Footer1 from '../Vikram/Footer1'

import JobListen from '../jp/NehaBhagat1/JobListen';

import GoToTop from "./Gototop";

const Placement=() =>{
    return(
        <Grid sx={{overflowX:"hidden",margin:'0.1%'}}>
           <Hheader/>
            {/* <Deep/> */}
            <JobListen/>
            <Footer1/>
            <GoToTop/>
        </Grid>
        
    )
}
export default Placement;