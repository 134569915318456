import React, { createContext, useContext, useState } from 'react';
import PropTypes from 'prop-types';
import Tabs from '@mui/material/Tabs';
import { Grid, IconButton, Button } from '@mui/material';
import Tab from '@mui/material/Tab';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import Combine from './Combine';
import JobDescription from '../namrata/JobDescription';
import Nomad from './Nomad.png'
import { NavLink, useNavigate } from 'react-router-dom';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import GoToTop from '../../Components/Gototop';
import ArrowBackOutlinedIcon from '@mui/icons-material/ArrowBackOutlined';
import styled from "@emotion/styled";
import JobInfo from '../images/JobInfo.png'
import JobInformation from '../namrata/JobInformation';
import { Context } from '../../App';
import Footer from '../../Vikram/Footer1';
import Hheader from '../../Vaibhav/Header';
import NewSyaapa from '../NehaBhagat1/NewSyaapa';
import NewSyaapa1 from '../NehaBhagat1/NewSyaapa1';
import NewSyaapa2 from '../NehaBhagat1/NewSyaapa2';

export const JobContext = createContext();

function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box sx={{ p: 3 }}>
                    <Typography>{children}</Typography>
                </Box>
            )}
        </div>
    );
}

TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.number.isRequired,
    value: PropTypes.number.isRequired,
};

function a11yProps(index) {
    return {
        id: `simple-tab-${index}`,
        'aria-controls': `simple-tabpanel-${index}`,
    };
}
function handleNextTab() {
    let value = this.state.value;
    if (value !== 1) {
        value = value + 1;
        this.setState({ value: value })
    }
}

function handleBackTab() {

    let value = this.state.value;
    if (value !== 0) {
        value = value - 1;
        this.setState({ value: value })
    }
}
export default function Postjobs() {
    const { authState } = useContext(Context);
    console.log(authState)
    const [job, setJob] = React.useState(
        {
            title: "",
            companyId: authState.user.id,
            companyName: authState.user.name,
            typeOfEmployment: [],
            salary: "",
            category: "",
            Skills: [],
            EndDate: "",
            Startdate: "",
            numberOfPositions: "",
            Description: "",
            responsibilities: [],
            Whoareyou: [],
            Nicetohaves: [],
            Perks: []
        })
    const [value, setValue] = React.useState(0);



    const handleChange = (event, newValue) => {
        setValue(newValue);
    };
    const Typography1 = styled(Typography)({
        fontFamily: 'inter'
    })

    const navigate = useNavigate();
    console.log(job)
    // const [index,setindex]=useState(0)
    return (
        <JobContext.Provider value={{ job, setJob }}>
            <Grid container>
                <Hheader/>
               

                {/* <Grid item xs={2} lg={1} sx={{paddingLeft:'10%'}}>
           <IconButton >
           <NotificationsActiveOutlinedIcon/>
           </IconButton>
         </Grid> */}


                <Grid item xs={12} lg={6} sx={{ marginLeft: '5%', display: 'flex',marginTop:'40px' }}>
                    <NavLink to={'/Jobportal'} style={{ textDecoration: 'none' }} ><IconButton>
                        <ArrowBackOutlinedIcon />
                    </IconButton></NavLink>
                    <Typography1 variant='h5'><b>
                        Post a Job
                    </b>


                    </Typography1>
                </Grid>



                <Box sx={{ width: '100%', padding: '2%' }}>

                    <Tabs variant="scrollable" value={value} onChange={handleChange} scrollable scrollButtons='on' aria-label="basic tabs example">

                        <Tab label={<Grid sx={{ display: 'flex', paddingLeft: '1%', width: '250px' }} >
                            <Grid>
                                <img src={JobInfo} alt='image' />
                            </Grid>
                            <Grid sx={{ padding: '1% 3%' }}>
                                <Typography ><b>Step 1/5</b></Typography>
                                <Typography><b>Job Information</b></Typography>
                            </Grid>
                        </Grid>} {...a11yProps(0)} />
                        <Tab label={<Grid sx={{ display: 'flex', paddingLeft: '1%', width: '250px' }}>
                            <Grid>
                                <img src={JobInfo} alt='image' />
                            </Grid>
                            <Grid sx={{ padding: '1% 3%' }}>
                                <Typography ><b>Step 2/5</b></Typography>
                                <Typography><b>Nice-to-Haves</b></Typography>
                            </Grid>
                        </Grid>} {...a11yProps(1)} />
                        <Tab label={<Grid sx={{ display: 'flex', paddingLeft: '1%', width: '250px' }}>
                            <Grid>
                                <img src={JobInfo} alt='image' />
                            </Grid>
                            <Grid sx={{ padding: '1% 3%' }}>
                                <Typography><b>Step 3/5</b></Typography>
                                <Typography><b>Responsibilities</b></Typography>
                            </Grid>
                        </Grid>} {...a11yProps(2)} />
                        <Tab label={<Grid sx={{ display: 'flex', paddingLeft: '1%', width: '250px' }}>
                            <Grid>
                                <img src={JobInfo} alt='image' />
                            </Grid>
                            <Grid sx={{ padding: '1% 3%' }}>
                                <Typography><b>Step 4/5</b></Typography>
                                <Typography><b>Who Are You</b></Typography>
                            </Grid>
                        </Grid>} {...a11yProps(3)} />
                        <Tab label={<Grid sx={{ display: 'flex', paddingLeft: '1%', width: '250px' }}>
                            <Grid>
                                <img src={JobInfo} alt='image' />
                            </Grid>
                            <Grid sx={{ padding: '1% 3%' }}>
                                <Typography><b>Step 5/5</b></Typography>
                                <Typography><b>perks and Benfits</b></Typography>
                            </Grid>
                        </Grid>} {...a11yProps(4)} />
                        
                    </Tabs>

                    <TabPanel value={value} index={0}>
                     
                            <Combine />
                           
                        
                        <Grid container item xs={12} lg={12} sx={{ marginTop: '20px', justifyContent: 'end' }}>
                            <Button variant='contained' onClick={e => {
                                if(job.title==""){
                                    alert("Please enter Title")
                                }
                                else{
                                    setValue(1)
                                }
                                if(job.typeOfEmployment==""){
                                    alert("Please enter Type of Employment")
                                }
                                else{ 
                                    setValue(1)
                                }
                                if(job.salary==""){
                                    alert("Please enter Salary")
                                }
                                else{ 
                                    setValue(1)
                                }
                                if(job.category==""){
                                    alert("Please enter Category")
                                }
                                else{ 
                                    setValue(1)
                                }
                                if(job.Skills==""){
                                    alert("Please enter Skills")
                                }
                                else{ 
                                    setValue(1)
                                }
                                if(job.EndDate==""){
                                    alert("Please enter End Date")
                                }
                                else{ 
                                    setValue(1)
                                }
                                if(job.numberOfPositions==""){
                                    alert("Please enter Number of Posts")
                                }
                                else{ 
                                    setValue(1)
                                }
                                if(job.Description==""){
                                    alert("Please enter Description")
                                }
                                else{ 
                                    setValue(1)
                                }
                                }} >Next</Button>
                        </Grid>
                    </TabPanel>
                    {/* <TabPanel value={value} index={1}>
                        
                        <Grid container item xs={12} lg={12} sx={{ marginTop: '20px', justifyContent: 'space-between' }}>
                            <Button variant='contained' onClick={e => (setValue(0))}>Back</Button>
                            <Button variant='contained' onClick={e => (setValue(2))}>Next</Button>
                        </Grid>
                    </TabPanel> */}
                    <TabPanel value={value} index={1}>

                    <NewSyaapa2/>
                    <Grid container item xs={12} lg={12} sx={{ marginTop: '20px', justifyContent: 'space-between' }}>
                            <Button variant='contained' onClick={e => (setValue(0))}>Back</Button>
                            <Button variant='contained' onClick={e => {
                                 if(job.Nicetohaves==""){
                                    alert("Please enter Nice-to-Haves")
                                }
                                else{
                                    setValue(2)
                                }
                            }}>Next</Button>
                        </Grid>
                    </TabPanel>
                    <TabPanel value={value} index={2}>

                        <NewSyaapa/>
                        <Grid container item xs={12} lg={12} sx={{ marginTop: '20px', justifyContent: 'space-between' }}>
                            <Button variant='contained' onClick={e => (setValue(1))}>Back</Button>
                            <Button variant='contained' onClick={e => {
                                  if(job.Responsibility==""){
                                    alert("Please enter Responsibility")
                                }
                                else{
                                    setValue(3)
                                }
                            }}>Next</Button>
                        </Grid>
                        
                    </TabPanel>
                    <TabPanel value={value} index={3}>

                      <NewSyaapa1/>
                      <Grid container item xs={12} lg={12} sx={{ marginTop: '20px', justifyContent: 'space-between' }}>
                            <Button variant='contained' onClick={e => (setValue(2))}>Back</Button>
                            <Button variant='contained' onClick={e => {
                                  if(job.Whoareyou==""){
                                    alert("Please enter Who are you")
                                }
                                else{
                                    setValue(4)
                                }
                            }}>Next</Button>
                        </Grid>
                </TabPanel>
            
                    <TabPanel value={value} index={4}>

                     
                     <JobInformation />
                </TabPanel>
                </Box>
                <GoToTop />
                <Footer/>
            </Grid>
        </JobContext.Provider>
    );
}
