import React, { useState } from 'react';
import { Grid, styled, Paper, Typography } from '@mui/material';
import Box from '@mui/material/Box';
import FilterAltIcon from '@mui/icons-material/FilterAlt';
import StageDrop from './StageDrop';
import CategoryDrop from './CategoryDrop';
import Vector from '../images/Vector.png'
import { alpha } from '@mui/material/styles';
import SearchIcon from '@mui/icons-material/Search';
import InputBase from '@mui/material/InputBase';


const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
  ...theme.typography.body2,
  padding: theme.spacing(1),
  textAlign: 'center',
  color: theme.palette.text.secondary,
}));
const Search = styled('div')(({ theme }) => ({
  position: 'relative',
  borderRadius: theme.shape.borderRadius,
  backgroundColor: alpha(theme.palette.common.white, 0.15),
  '&:hover': {
    backgroundColor: alpha(theme.palette.common.white, 0.25),
  },
  marginRight: theme.spacing(2),
  marginLeft: 0,

  height: '70px',
  marginTop: '5%',


  [theme.breakpoints.up('sm')]: {
    marginLeft: theme.spacing(3),
  },
}));
const SearchIconWrapper = styled('div')(({ theme }) => ({
  padding: theme.spacing(0, 2),
  height: '100%',
  position: 'absolute',
  pointerEvents: 'none',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  width: '36px',


}));
const StyledInputBase = styled(InputBase)(({ theme }) => ({
  color: 'inherit',
  '& .MuiInputBase-input': {
    padding: theme.spacing(1, 1, 1, 0),
    // vertical padding + font size from searchIcon
    paddingLeft: `calc(1em + ${theme.spacing(4)})`,
    transition: theme.transitions.create('width'),
    width: '100%',
    [theme.breakpoints.up('md')]: {
      width: '30ch',
    },
  },
}));


function IncubFilter() {


  return (
    <>
      <Box sx={{ flexGrow: 1, marginTop: '60px',marginBottom:'40px' }}>
        <Grid container className="module1">
          <Grid xs={12} sm={6}><div className='xyz' >
            <Typography sx={{
              fontFamily: 'Merriweather',
              fontStyle: 'normal',
              fontWeight: 'normal',
              fontSize: '48px',
              lineHeight: '68px',
              letterSpacing: '-1px',
              color: '#001A49',

              marginLeft: '50%'
            }}>
              Search

            </Typography>
          </div>

          </Grid>
          <Grid xs={12} sm={6} ><div className='abc' style={{ width: '60%' }}>
            <Search sx={{ boxShadow: '0px 0px 25px 0.5px gray', minWidth: '' }}>
              <SearchIconWrapper>
                <SearchIcon sx={{ fontSize: 'xxx-large', color: 'lightgrey' }} />
              </SearchIconWrapper>
              <StyledInputBase sx={{
                marginLeft: '30px',
                width: '330px',
                fontSize: '18px',
                height: '100%',
                color: 'grey',
                fontFamily: 'Merriweather'
              }}
                placeholder="Use search for the revelent field"
                inputProps={{ 'aria-label': 'search' }}
              />
            </Search></div>


          </Grid>
        </Grid>
        <Grid container spacing={{ xs: 1, md: 1 }} columns={{ xs: 12, sm: 8, md: 12 }}  >
          <Grid item xs={12} sm={3} md={5} container sx={{ display: 'block' }} marginTop={'40px'}>
            <Grid item xs={12} sm={4} md={12} sx={{ display: 'block', float: 'right' }}>
              <Grid item style={{ display: 'flex', justifyContent: 'flex-end' }}>
                {/* <FilterAltIcon sx={{fontSize: '80px', color: '#001A49'}}/> */}
                <img src={Vector} alt="" width='10%' height='20%' style={{ margin: '3% 2% 0 0' }} />
                <Typography fontSize="60px" fontWeight='700' color='#001A49'>Filter</Typography>
                <Typography padding='6% 2% 0 6%' color='#001A49' fontSize='20px'>Tag Cloud</Typography>
              </Grid>
              <Grid item sx={{ display: 'flex' }}>
                <Grid lg={6}></Grid>
                <Grid xs={12}>
                  <Typography sx={{ paddingLeft: '10%', textAlign: 'justify', color: '#001A49' }}>Select an appropriate filter by (Categories & Stages) or use the search bar to refine your search</Typography>
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={12} sm={4} md={12}>
              {/* <Item>xs=2</Item> */}
            </Grid>
          </Grid>
          <Grid item xs={12} sm={3} md={1}>
          </Grid>
          <Grid item xs={12} sm={3} md={3} marginTop={'40px'}>
            <CategoryDrop />
          </Grid>
          <Grid item xs={12} sm={3} md={3} marginTop={'40px'}>
            <StageDrop />
          </Grid>
        </Grid>
      </Box>

    </>
  );
}

export default IncubFilter;