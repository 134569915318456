import React, { useContext } from "react";
import {
  Typography,
  Grid,
  Button,
  IconButton,
  Box,
  TextField,
} from "@mui/material";
import { NavLink, useNavigate } from "react-router-dom";
import styled from "@emotion/styled";
import GoToTop from "../../Components/Gototop";
import { JobContext } from "../Vaibhav/Postjob";
import { useEffect, useState } from "react";
const Typography1 = styled(Typography)({
  fontFamily: "inter",
});

const NewSyaapa3 = () => {
  const { job, setJob } = useContext(JobContext);
  const [formErrors, setFormErrors] = useState({});
  const [isSubmit, setIsSubmit] = useState(false);
  const handleSubmit = (e) => {
    e.preventDefault();
    setFormErrors(validate(job));
  };
  useEffect(() => {
    console.log(formErrors);
    if (Object.keys(formErrors).length === 0 && isSubmit) {
      console.log(job);
    }
  }, [formErrors]);
  const validate = (job) => {
    const errors = {};
    if (!job.Description) {
      errors.Description = "Please enter Description";
    }

    return errors;
  };
  const navigate = useNavigate();

  return (
    
    <Grid container xs={12} spacing={1}>
      <Grid item container xs={12} sm={12} md={12}>
        <Grid item xs={12} sm={5} md={5} >
          <Typography
            sx={{
              fontFamily: "Epilogue",
              fontStyle: "normal",
              fontWeight: "600",
              fontSize: "18px",
            }}
          >
            Job Description
          </Typography>
          <>
            <Typography
              sx={{
                color: "#7C8493",
                fontFamily: "Epilogue",
                fontStyle: "normal",
                fontWeight: "400",
                fontSize: "18px",
              }}
            >
              Job Decription must describe more about job in 100-120 words.
            </Typography>
          </>
        </Grid>
        <Grid item  xs={12} sm={6} md={6} >
          <Box
            sx={{
              width: 500,
              maxWidth: "100%",
            }}
          >
            <TextField
              required
              fullWidth
              placeholder="Start writing Description here"
              id="fullWidth"
              rows={4}
              multiline
              value={job.Description}
              onChange={(e) => setJob({ ...job, Description: e.target.value })}
            />
            <p style={{ color: "red" }}>{formErrors.Description}</p>
          </Box>
         
           
        </Grid>
      </Grid>
    </Grid>
  );
};

export default NewSyaapa3;
