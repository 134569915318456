import { Grid, Typography, Box, Button, Avatar, TextField, InputLabel, MenuItem, FormControl, Select } from "@mui/material";
import React, { useEffect, useState } from "react"
import Drone from "../images/Drone.png"
import Harjit from "../images/AvatarImage.png"
import NFT from '../images/NFT.png'
import "./Incubationcard.css"
import { NavLink } from "react-router-dom";
import SearchIcon from '@mui/icons-material/Search';
import FilterAltIcon from '@mui/icons-material/FilterAlt';
import Hydroponics from '../images/Hydroponics.png';
import axios from "axios";
import Preeti from "./preeti.jpg"
import Jagjeet from "./download.jpg"

export default function zzIncubationCard() {
    const [data, setData] = useState([]);
    const [querry, setQuerry] = useState("")
    const [filter, setfilter] = React.useState("MOst Viewed");

    const handleChange = (event) => {
        setfilter(event.target.value);
    }

    const arr = [{ image: Drone, name: 'Drone Technology', para: 'On a construction site, drones can assist with pre-construction site review, aerial surveying, measurement of excavation depths, monitoring and documenting jobsite progress, inspecting safety and security at work that is difficult or dangerous for human inspection.', personimg: Harjit, person: 'Harjeet Singh' },
    { image: NFT, name: 'NFT Loan Platform', para: 'Ideation on Non-fungible tokens performing as lending platforms.The acceptance of non-fungible assets as collaterals securities.This idea motivates to hold tokens and grants access to interest loans for lenders.', personimg: Preeti, person: 'Preeti Sharma' },
    { image: Hydroponics, name: 'Hydroponics', para: 'Hydroponics is a type of horticulture and a subset of hydroculture which involves growing plants, usually crops, without soil, by using mineral nutrient solutions in an aqueous solvent.', personimg: Jagjeet, person: 'Jagjeet Singh' },

    ]

    useEffect(() => {
        axios({
            url: 'https://api.keewesolutions.com/research/reflectcompany',
            method: 'post',
            data: {
                company: 'company1'
            }
        }).then(res => setData[res.data]);
        console.log(data);
    }, [])

    return (
        <Grid container xs={12} lg={12}>
            <Grid item xs={12} >
                <Grid>
                    <Box sx={{ border: '1px solid #d6ddeb', width: '90%', margin: '1% 4%' }}>
                        <Grid container item>
                            <Grid lg={1} />
                            <Grid item xs={11} lg={4} marginLeft={'10px'}>
                                <SearchIcon sx={{ fontSize: 'xx-large', color: '#25324B', marginTop: '10px' }} />
                                <TextField onChange={event => setQuerry(event.target.value)} sx={{ width: '70%' }} id="outlined-basic" label="Search Here" variant="standard" />
                            </Grid>
                            <Grid xs={0.5} lg={0.5} marginTop={'10px'} >
                                <Box sx={{ width: '0.5px', height: '40px', backgroundColor: '#202430' }} />
                            </Grid>
                            <Grid item xs={6} lg={3} marginLeft='10px'>
                                <FilterAltIcon sx={{ fontSize: 'xx-large', color: '#25324B', marginTop: "10px" }} />
                                <FormControl variant="standard" sx={{ m: 1, minWidth: '60%' }}>
                                    <InputLabel id="demo-simple-select-standard-label">Filter</InputLabel>
                                    <Select
                                        labelId="demo-simple-select-standard-label"
                                        id="demo-simple-select-standard"
                                        value={filter}
                                        onChange={handleChange}

                                    >


                                        <MenuItem value={"Most Relevant"}>Most Relevant</MenuItem>
                                        <MenuItem value={"Most Viewed'"}>Most Viewed</MenuItem>

                                    </Select>
                                </FormControl>
                            </Grid>
                            <Grid lg={1} />

                            <Grid item xs={2} lg={1} marginTop={'10px'}>
                                <Button variant='contained' sx={{ borderRadius: '0px' }}>Search</Button>
                            </Grid>
                        </Grid>
                    </Box>
                    <Typography sx={{ margin: '1% 4%' }}>Filter your search by Categories and  use the search bar for refined results </Typography>
                </Grid>
            </Grid>



            <Grid item xs={12} lg={12} md={12} sm={12} sx={{ marginTop: '10px' }}>
                <Grid className="card" item xs={12} lg={12} md={12} sm={12} container sx={{
                    padding: "2px"
                }} > {arr.filter(items => {
                    if (querry == "") {
                        return items
                    }
                    else if (items.name.toLowerCase().includes(querry.toLowerCase())) {
                        return items;
                    }
                }).map((items) => {
                    return (
                        <Grid key={items.name} item xs={12} lg={4} md={6} sm={6} margin={'10px 0 5px 0'} >
                            <Grid item xs={12} sx={{ border: '1px solid #D6DDEB', padding: "12px", marginX: "26px", marginY: "10px  ", height: "100%", boxShadow: '0px 0px 12px 1px lightgray' }}>
                                <Grid><img src={items.image} style={{ width: '100%', height: '226px' }} /></Grid>
                                <Grid sx={{ display: 'flex', marginLeft: '10px', justifyContent: 'space-between' }}>
                                    <Grid>
                                        <Typography sx={{ fontSize: '15px', textDecoration: 'underline' }}>Category Name</Typography>
                                        <Typography marginTop={'1px'} sx={{ fontSize: '18px', color: '#25324B' }}><b>{items.name}</b></Typography>
                                    </Grid>
                                    <Grid>
                                        <Box sx={{ marginTop: '10px', border: '1px solid #FFB836', borderRadius: '8px', color: '#FFB836', width: '120px', height: '28px', textAlign: 'center' }}> Under Progress</Box>
                                    </Grid>
                                </Grid>
                                <Typography marginTop={'3px'} sx={{ color: '#001A49', marginLeft: '10px', fontSize: '16px', marginRight: '10px', textAlign: 'justify' }}>
                                    {items.para}
                                </Typography>


                                <Grid container lg={12} xs={12} md={12} sm={12} sx={{
                                    marginTop: '20px', marginLeft: '10px'
                                }}>
                                    <Grid item lg={11} xs={11} md={11} sm={11} sx={{display: 'flex', justifyContent: 'space-between'}} >
                                        <NavLink to={'/Incubdetails'} sx={{ textDecoration: 'none' }}><Button variant='outlined' sx={{ height: '40px', borderRadius: '5px' }}>Show Details</Button></NavLink>
                                        {/* </Grid>
                                    <Grid item lg={5} xs={4} > */}
                                        <Typography sx={{ display: 'flex', fontSize: '18px', color: '#001A49', width: '100px' }}><Avatar sx={{ width: '50px', height: '50px' }} src={items.personimg} />{items.person}</Typography>
                                    </Grid>
                                    {/* </Grid> */}
                                </Grid>
                            </Grid>
                            
                        </Grid>
                )})}
                </Grid>
            </Grid>
        </Grid>
    );
} 