import React from 'react';
import { Grid, Typography } from '@mui/material';
import { styled, alpha } from '@mui/material/styles';
import SearchIcon from '@mui/icons-material/Search';
import InputBase from '@mui/material/InputBase';
import WorkOutlineIcon from '@mui/icons-material/WorkOutline';
import LocationOnOutlinedIcon from '@mui/icons-material/LocationOnOutlined';
import './SLE.css';


const Search = styled('div')(({ theme }) => ({
  position: 'relative',
  borderRadius: theme.shape.borderRadius,
  backgroundColor: alpha(theme.palette.common.white, 0.15),
  '&:hover': {
    backgroundColor: alpha(theme.palette.common.white, 0.25),
  },
  marginRight: theme.spacing(2),
  marginLeft: 0,

  height: '70px',
  marginTop: '5%',


  [theme.breakpoints.up('sm')]: {
    marginLeft: theme.spacing(3),
  },
}));

const SearchIconWrapper = styled('div')(({ theme }) => ({
  padding: theme.spacing(0, 2),
  height: '100%',
  position: 'absolute',
  pointerEvents: 'none',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  width: '36px',


}));
const StyledInputBase = styled(InputBase)(({ theme }) => ({
  color: 'inherit',
  '& .MuiInputBase-input': {
    padding: theme.spacing(1, 1, 1, 0),
    // vertical padding + font size from searchIcon
    paddingLeft: `calc(1em + ${theme.spacing(4)})`,
    transition: theme.transitions.create('width'),
    width: '100%',
    [theme.breakpoints.up('md')]: {
      width: '30ch',
    },
  },
}));
const SLE = () => {

  return (
    <Grid container marginTop={'30px'} marginBottom={'-20px'}>
      {/* 1st */}

      {/* 2nd */}
      <Grid container>
        <Grid xs={12} sm={6} ><div className='xyz'> <Typography sx={{
          fontFamily: 'Merriweather',
          fontStyle: 'normal',
          fontWeight: 'normal',
          fontSize: '48px',
          lineHeight: '68px',
          letterSpacing: '-1px',
          color: '#001A49',
          marginLeft: '50%',
          marginTop: '7%'
        }}>
          Location

        </Typography>
          <Typography sx={{
            fontFamily: 'Merriweather',
            fontStyle: 'normal',
            fontWeight: 'normal',
            fontSize: '20px',
            lineHeight: '30px',
            color: '#4F596A',
            marginLeft: '50%'
          }}
          >
            Required a candidate<br /> for which location
          </Typography></div>

        </Grid>
        <Grid xs={12} sm={6} ><div className='abc' style={{ maxWidth: '60%' }}>

          <Search sx={{ boxShadow: '0px 0px 25px 0.5px gray', marginTop: '70px' }}>
            <SearchIconWrapper>
              <LocationOnOutlinedIcon sx={{ fontSize: 'xxx-large', color: 'lightgrey' }} />
            </SearchIconWrapper>
            <StyledInputBase sx={{
              marginLeft: '30px',
              width: '330px',
              fontSize: '18px',
              height: '100%',
              color: 'grey',
              fontFamily: 'Merriweather'
            }}
              placeholder="Use search for the revelent field"
              inputProps={{ 'aria-label': 'search' }}
            />
          </Search></div>


        </Grid>
      </Grid>
      {/* 3rd */}
      <Grid container>
        <Grid xs={12} sm={6}><div className='xyz'> <Typography sx={{
          fontFamily: 'Merriweather',
          fontStyle: 'normal',
          fontWeight: 'normal',
          fontSize: '48px',
          lineHeight: '68px',
          letterSpacing: '-1px',
          color: '#001A49',
          marginLeft: '50%',
          marginTop: '7%'
        }}>
          Work Experience

        </Typography>
          <Typography sx={{
            fontFamily: 'Merriweather',
            fontStyle: 'normal',
            fontWeight: 'normal',
            fontSize: '20px',
            lineHeight: '30px',
            color: '#4F596A',
            marginLeft: '50%',

          }}>
            Years of Experience or Fresher
          </Typography></div>

        </Grid>
        <Grid xs={12} sm={6}><div className='abc' style={{ maxWidth: '60%' }}>
          <Search sx={{ boxShadow: '0px 0px 25px 0.5px gray', marginTop: '70px' }}>
            <SearchIconWrapper>
              <WorkOutlineIcon sx={{ fontSize: 'xxx-large', color: 'lightgrey', marginTop: '6px', marginLeft: '5px', marginRight: '12%' }} />
            </SearchIconWrapper>
            <StyledInputBase sx={{
              width: '330px',
              fontSize: '18px',
              height: '100%',
              color: 'grey',
              fontFamily: 'Merriweather',
              marginLeft: '8%'
            }}
              placeholder="Use search for the revelent field"
              inputProps={{ 'aria-label': 'search' }}
            />
          </Search></div>
        </Grid>
      </Grid>
    </Grid>
  )
}

export default SLE