
import { Grid } from "@mui/material";

import Hheader from "../Vaibhav/Header";
import Deep from '../jp/NehaBhagat1/Deep';
import GoToTop from "./Gototop";
import Footer from "../Vikram/Footer1";

const Applicants=() =>{
    return(
        <Grid sx={{overflowX:"hidden",margin:'0.1%'}}>
           <Hheader/>
            <Deep/> 
            <Footer/>
            <GoToTop/>
        </Grid>
        
    )
}
export default Applicants