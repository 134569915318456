import React, { useState, useMemo } from "react";
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import { useNavigate } from "react-router-dom";
import {
  Box,
  Button,
  Card,
  Grid,
  Stack,
  Link,
  Typography,
  FormControl,
  Select,
  Menu,
  MenuItem,
  Pagination,
} from "@mui/material";
import Normad from "./Nomad.png";
import "./Jobsearch.css";
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import { Context } from '../App';
import axios from "axios";


const Jobsss = () => {
  const [filter, setFilter] = useState("Most Relevant");

  const handleChange = (event) => {
    setFilter(event.target.value);
  };
  const [data, setdata] = useState([]);
  const { authState } = React.useContext(Context)
  React.useEffect(() => {
      async function data() {
          try {
              const getdata = await axios("https://po.keewesolutions.com/jp/companyjob", {
                  method: "POST",
                  data: {
                      "companyId": authState.user.id
                  }
              }).then((response) => {setdata(response.data.Items)
            
              })


          } catch (error) {
              console.log(error)
          }
      }
      data()
  }, [])
  console.log(data)
  const arr = [
    {
      image: Normad,
      name: "Socail Media Assistant",
      place: "Normad . Delhi. Noida",
      time: "Full Time",
      type1: "Marketing",
      type2: "Design",
      Origin: "Nomad . Delhi. Noida",
    },



  ];

  const num = arr.length;
  const navigate = useNavigate();

  return (
    <Grid container xs={12} lg={12} md={12} sm={12} sx={{ marginTop: '30px' }}>
      <Grid item container xs={12} lg={11.2} md={11} sm={11} sx={{ display: "flex", justifyContent: 'space-between', margin: '0 30px 0 10px' }}>


        <Typography style={{ fontSize: '28px', marginLeft: '10px', fontWeight: '400' }}>Job Updates</Typography>

        {/* <Button variant="outlined" onClick={() => navigate('/jobportal')} startIcon={<ArrowForwardIcon />}>
          View all
        </Button> */}

      </Grid>

      <Grid
        xs={12}
        lg={12}
        md={12}
        sm={12}
        container
        item
        sx={{ display: "flex", marginLeft: "5px", marginTop: "20px", marginRight: "20px" }}
      >
        

          <Grid item lg={2} md={3} sm={4} xs={12} sx={{ margin: "1% 4% 2% 1.5%" }}>
            <Card style={{ backgroundColor: 'rgb(233 242 247)', height: '230px', textAlign: 'center', width: '250px' }}>
              <Grid container sx={{ justifyContent: 'center', alignItems: 'center' }}>
                <Button variant="contained" style={{ marginTop: '35%' }} onClick={() => navigate("/Jobportal")}>Go to JobPortal<AddCircleOutlineIcon /></Button>
              </Grid>
            </Card>
          </Grid>
        
        {data?.map((item, index) => {
          return (
            <Grid item lg={2.5} md={3} sm={4} xs={12}>

              <Card
                key={item.jobTitle}
                sx={{
                  height: "230px",
                  border: "1px solid #1A50B2",
                  display: "inline-block ",
                  margin: "4% 4% ",

                }}
              >
                <Grid item container xs={12} lg={12} sm={12} md={12}>
                  <Grid
                    item
                    container
                    sx={{
                      display: "flex",
                      marginLeft: "20px",
                      marginTop: "20px",
                      marginBottom: "20px",
                    }}
                  >
                    
                    <Grid itemsx={{ marginLeft: "15px" }}>
                      <Typography variant="h5">{item.jobTitle}</Typography>
                      <Typography sx={{ color: "#7C8493" }}>
                        Positions: {item.numberOfPositions}
                      </Typography>
                      <Typography sx={{ color: "#7C8493" }}>
                        {item.Startdate}-{item.EndDate}
                      </Typography>
                    </Grid>
                    <Grid item container>
                      
                      <Box
                        sx={{
                          borderRadius: "20px",
                          background: "#56CDAD1A",
                          width: "80px",
                          height: "30px",
                          color: "#56CDAD",
                          textAlign: "center",
                          marginRight: '2%'
                        }}
                      >
                        <Typography sx={{ fontSize: "15px", margin: "3%" }}>
                          {item.employmentType}
                        </Typography>
                      </Box>
                    </Grid>
                  </Grid>
                  <Grid
                    item
                    container
                    sx={{
                      display: "flex",
                      marginBottom: "20px",
                      marginLeft: "20px",
                    }}
                  >
                    {/* <Box
                      sx={{
                        borderRadius: "20px",
                        background: "#56CDAD1A",
                        width: "80px",
                        height: "30px",
                        color: "#56CDAD",
                        textAlign: "center",
                      }}
                    >
                      <Typography sx={{ fontSize: "15px", margin:"3%" }}>
                        {item.time}
                      </Typography>
                    </Box> */}
                    {/* <Typography sx={{ color: "#D6DDEB", margin: " 0% 3%" }}>
                      |
                    </Typography> */}
                    <Box
                      sx={{

                        borderRadius: "20px",
                        border: "1px solid #FFB836",
                        width: "80px",
                        height: "30px",
                        color: "#FFB836",
                        textAlign: "center",
                      }}
                    >
                      <Typography sx={{ fontSize: "15px", margin: "3%" }}>
                        {item.Skills[0]}
                      </Typography>
                    </Box>
                    <Box
                      sx={{
                        margin: " 0% 3%",
                        borderRadius: "20px",
                        border: "1px solid #1A50B2",
                        width: "80px",
                        height: "30px",
                        color: "#1A50B2",
                        textAlign: "center",
                      }}
                    >
                      <Typography sx={{ fontSize: "15px", margin: "3%" }}>
                      {item.Skills[1]}
                      </Typography>
                    </Box>
                  </Grid>
                  <Grid
                    item
                    container
                    xs={12}
                    sx={{
                      margin: "3%",

                      display: "inline",
                    }}
                  >

                  </Grid>
                </Grid>
              </Card>
            </Grid>
          );
        })}

      </Grid>
    </Grid>
  );
};
export default Jobsss;
