import { Grid } from "@mui/material";
import Footer from "../Deepak/Footer";
import Hheader from "../Vaibhav/Header";
import IncubFilter from "../prashant/Components/IncubFilter";
import ResearchPo from "../prashant/Components/ResearchPo"

import Footer1 from '../Vikram/Footer1'
import Finalarticles from "../Vikram/articles/Finalarticles";
import Slide from '../Deepak/Slide'
import ResearchFinal from "../Namrata/ResearchFinal";
import HomePage from "../Namrata/HomePage";
import ResearchFeed from "../Namrata/ResearchFeed";
import ResearchDetails from "../Namrata/Agriculture";
import GoToTop from "./Gototop";


const Reasearch=() =>{
    return(
        <Grid sx={{overflowX:"hidden",margin:'0.1%'}}> 
            <Hheader/>
            {/* <ResearchPo/>
            <IncubFilter/>
            
            <Slide/> 
            <Finalarticles/>  */}
            <HomePage/>
            
           
            {/* <ResearchFinal/> */}
            <Footer1/>
            <GoToTop/>

        </Grid>
    )
}
export default Reasearch;