import {
  Button,
  Grid,
  Typography,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Box,
  Divider,
  Checkbox,
  Avatar,
  IconButton,
} from "@mui/material";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import { useEffect, useState } from "react";
import { styled, alpha } from "@mui/material/styles";
import InputBase from "@mui/material/InputBase";
import SearchIcon from "@mui/icons-material/Search";
import FilterListIcon from "@mui/icons-material/FilterList";
import guy from "./img/guy.png";
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';
import Rating from "@mui/material/Rating";
import { Link, useLocation } from "react-router-dom";
import NotificationsActiveOutlinedIcon from '@mui/icons-material/NotificationsActiveOutlined';
import Nomad from './Nomad.png';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { NavLink, useNavigate } from "react-router-dom";
import React from "react";
import { DataGrid } from "@mui/x-data-grid";
import axios from "axios";

const Search = styled("div")(({ theme }) => ({
  position: "relative",
  borderRadius: theme.shape.borderRadius,
  backgroundColor: alpha(theme.palette.common.white, 0.15),
  "&:hover": {
    backgroundColor: alpha(theme.palette.common.white, 0.25),
  },
  marginRight: theme.spacing(2),
  marginLeft: 0,
  width: "100%",
  [theme.breakpoints.up("sm")]: {
    marginLeft: theme.spacing(3),
    width: "auto",
  },
}));

const SearchIconWrapper = styled("div")(({ theme }) => ({
  padding: theme.spacing(0, 2),
  height: "100%",
  position: "absolute",
  pointerEvents: "none",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
}));

const StyledInputBase = styled(InputBase)(({ theme }) => ({
  color: "inherit",
  "& .MuiInputBase-input": {
    padding: theme.spacing(1, 1, 1, 0),
    // vertical padding + font size from searchIcon
    paddingLeft: `calc(1em + ${theme.spacing(4)})`,
    transition: theme.transitions.create("width"),
    width: "100%",
    [theme.breakpoints.up("md")]: {
      width: "20ch",
    },
  },
}));


export default function Deep() {
  // const [open, setopen] = useState(false);
  // const [readMore, setReadMore] = useState(false);
  const navigate = useNavigate()
  let id = [];

  let Subdate = []
  
  // const [JobId, setJobID] = useState([])
  const [len, setlen] = useState()
  const [userid, setuserid] = useState()

  const [rows, setrows] = React.useState([]);

  const location = useLocation()
  const jobId = location.state.jobId
  

  React.useEffect(async () => {
    try {
      let JID
      const data = await axios("https://api.keewesolutions.com/jp/getresume", {
        method: "POST",

      }).then((res) => {
        const result = res.data.Items;
        console.log(result)
        
        for (let ids in result) {
          id.push(result[ids].userId);
        }

        for (let dates in result) {
          Subdate.push(result[dates].Submitdate);
        }
        for (let i in result) {
          
          JID = result[i].jobId
        }
        
      })
      let response2 = await axios({
        method: "POST",
        url: "https://api.keewesolutions.com/opencase/batchget",
        data: {
          userId: id
        }
      })
      if(response2) {

        const result1 = response2.data
        
        for (let i in result1) {
          result1[i].id = result1[i].fname
          if (Subdate.length != 0) {

            result1[i]['Submitdate'] = Subdate[i]
          }

        }
        
        if (JID != jobId) {
          console.log('No Data')
        } else (
          setrows(result1)
        )

        setlen(result1.length)

        if (id.length != 0) {
          setuserid(id)
        }
        
      }


    } catch (error) {
      console.log(error)
    }

  }, [])
  useEffect(() => {
    console.log(rows)
  }, [rows])

  
  // let Submitdate = {
  //   'Submitdate' : id.Submitdate
  // }
  

  const columns = [

    { field: 'fname', headerName: 'First Name', width: 200 },
    { field: 'lname', headerName: 'Last Name', width: 200 },
    { field: 'hiringstage', headerName: 'Hiring Stage', width: 200 },
    // { field: 'salary', headerName: 'Salary' },

    { field: 'Submitdate', headerName: 'Apply Date' },
    { field: 'Jobrole', headerName: 'Job Role', width: 150 },
    // { field: 'applicantsnum', headerName: 'Applicants' },
    {
      field: "seeapplication",
      headerName: "Action",
      width: 150,
      sortable: false,
      renderCell: (params) => {
        const onClick = (e) => {
          e.stopPropagation();
          console.log(params)
          return navigate("/application", { state: { data: [params.row], userId: userid } });
        };

        return <Button onClick={onClick}>
          See Application
        </Button>;
      }
    }
  ];





  return (
    <Grid container xs={12} lg={11.5} sx={{ marginTop: '30px' }}>
      <Grid lg={0.5} />
      <Grid item container xs={12} lg={11.5}>
        <Grid item container>
          <Grid item xs={4} lg={8.5} />
          {/* <img src={Nomad} alt={''} style={{ paddingLeft: '2%', width: '50px', height: '60px' }} />

            <Typography variant='body2' fontFamily={'poppins'} fontSize={'28px'} marginTop={'5px'} marginLeft={"1%"}><b>Nomad </b></Typography>
            <IconButton style={{ margin: "-10px" }} ><ExpandMoreIcon fontSize='large' /></IconButton>
          </Grid> */}

          <Grid item xs={2} lg={1} sx={{ paddingLeft: '8%' }}>
            <IconButton >
              <NotificationsActiveOutlinedIcon />
            </IconButton>
          </Grid>
          {/* <Grid lg={1} /> */}

          <Grid item xs={5} lg={2} sx={{ paddingLeft: '2%', marginLeft: '5px' }}>
            <NavLink to={'/postajob'} style={{ textDecoration: 'none' }} > <Button variant='contained'
              sx={{
                borderRadius: '0px', backgroundColor: '#1a50b2', marginTop: '2%',
                ':hover': { color: '#1a50b2', backgroundColor: 'white' }
              }}
            > + Post a Job</Button></NavLink>
          </Grid>
        </Grid>
        <Grid item container xs={12} lg={11} display={"flex"} justifyContent={"space-between"} marginTop={'20px'}>
          <Grid xs={12} lg={4} marginTop={'5px'}>
            <Typography
              sx={{
                fontFamily: "Montserrat",
                fontSize: "30px",
                fontStyle: "normal",
                fontWeight: "600",
                lineHeight: "25px",
                letterSpacing: "0.03em",
                textAlign: "left",
                marginTop: "2.5%",
              }}
            >
              Total Applicants : {len}
            </Typography>
          </Grid>


        </Grid>

        <Grid item xs={12} lg={10} style={{ height: 400, width: '80%', marginBottom: '40px', marginTop: '30px' }}>

          <DataGrid
            rows={rows}
            columns={columns}
            pageSize={5}
            rowsPerPageOptions={[5]}
            checkboxSelection

          >

          </DataGrid>
        </Grid>

        {/* <Button
          variant="contained"
          onClick={() => {
            setopen(!open);
            setReadMore(!readMore);
          }}
          sx={{ marginTop: "20px" }}
        >
          {!readMore ? <>View more</> : <>View Less</>}
        </Button> */}

      </Grid>
    </Grid>
  );
}
