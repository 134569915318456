import React from 'react';
import Paper from '@mui/material/Paper';
import { Grid, Typography, Card } from '@mui/material';
import Divider from '@mui/material/Divider';
import { styled } from '@mui/material/styles';
import Table from "../NehaBhagat1/Table";

const Item = styled(Paper)(({ theme }) => ({

    padding: theme.spacing(1),
    textAlign: 'left',
    boxShadow: "none"
}));
const Item1 = styled(Paper)(({ theme }) => ({

    padding: theme.spacing(1),
    textAlign: 'right',
    boxShadow: "none"

}));



const Content = ({ data, userId }) => {
    return (


        <Grid container xs={12} padding={'2%'}>


            <Item sx={{ border: " 1px solid #D6DDEB" }} >
                {data?.map((item, index) => {


                    if (
                        Boolean(userId.find((iteme) => iteme === item.userId))
                    ) {
                        return (
                            <Grid item xs={12} lg={12}>
                                <Typography variant='body2' fontFamily={'poppins'} fontSize={'20px'} color={'#25324B'}><b>Personal Info</b></Typography>
                                <Grid display={'flex'} padding={'6%'}>
                                    
                                    <Grid>
                                        
                                        <Typography variant='body2' fontFamily={'poppins'} fontSize={'20px'} color={'#7C8493'}>Full Name</Typography>
                                        <Typography variant='body2' fontFamily={'poppins'} fontSize={'20px'} color={'#25324B'}>{item.fname} {item.lname}</Typography><br />
                                        <Typography variant='body2' fontFamily={'poppins'} fontSize={'20px'} color={'#7C8493'}>Date of Birth</Typography>
                                        <Typography variant='body2' fontFamily={'poppins'} fontSize={'20px'} color={'#25324B'}>March 23, 1995  </Typography><br />
                                       
                                    </Grid>
                                    <Grid marginLeft={'30px'}>
                                        {/* <Typography variant='body2' fontFamily={'poppins'} fontSize={'20px'} color={'#7C8493'}>Gender</Typography>
                                        <Typography variant='body2' fontFamily={'poppins'} fontSize={'20px'} color={'#25324B'}>Male</Typography><br /> */}
                                         <Typography variant='body2' fontFamily={'poppins'} fontSize={'20px'} color={'#7C8493'}>Address</Typography>
                                        <Typography variant='body2' fontFamily={'poppins'} fontSize={'20px'} color={'#25324B'}>3341-Sector 47-D, Chnadigarh</Typography><br/>
                                        <Typography variant='body2' fontFamily={'poppins'} fontSize={'20px'} color={'#7C8493'}>Language</Typography>
                                        {item.languageArr?.map((item1)=>{return(
                                            <Typography variant='body2' fontFamily={'poppins'} fontSize={'20px'} color={'#25324B'}>{item1.title}</Typography>
                                        )})}
                                        
                                    </Grid>
                                </Grid>
                                <Divider variant="middle" />
                                <Grid padding={'6%'}>
                                    <Grid>
                                        <Typography variant='body2' fontFamily={'poppins'} fontSize={'20px'} color={'#25324B'}><b>Professional Info</b></Typography><br />
                                        <Typography variant='body2' fontFamily={'poppins'} fontSize={'20px'} color={'#7C8493'}>About Me</Typography>
                                        <Typography variant='body2' fontFamily={'poppins'} fontSize={'20px'} color={'#25324B'}>{item.summary}</Typography>
                                        
                                    </Grid><br />
                                    <Grid display={'flex'}>
                                        <Grid>
                                            <Typography variant='body2' fontFamily={'poppins'} fontSize={'20px'} color={'#7C8493'}>Current Job</Typography>
                                            <Typography variant='body2' fontFamily={'poppins'} fontSize={'20px'} color={'#25324B'}>{item.headline}</Typography><br />
                                            {/* <Typography variant='body2' fontFamily={'poppins'} fontSize={'20px'} color={'#7C8493'}>Highest Qualification Held</Typography>
                                            <Typography variant='body2' fontFamily={'poppins'} fontSize={'20px'} color={'#25324B'}>Bachelors in Engineering</Typography> */}
                                        </Grid>
                                        {/* <Grid marginLeft={'15%'}   >
                                            <Typography variant='body2' fontFamily={'poppins'} fontSize={'20px'} color={'#7C8493'}>Experience in Years</Typography>
                                            <Typography variant='body2' fontFamily={'poppins'} fontSize={'20px'} color={'#25324B'}>4 Years</Typography><br />
                                            <Typography variant='body2' fontFamily={'poppins'} fontSize={'20px'} color={'#7C8493'}>Skill set</Typography>

                                            <> <Typography variant='body2' fontFamily={'poppins'} fontSize={'20px'} color={'#4640DE'}>Project Management</Typography></>
                                            <Typography variant='body2' fontFamily={'poppins'} fontSize={'20px'} color={'#4640DE'}>Copywriting</Typography>
                                            <Typography variant='body2' fontFamily={'poppins'} fontSize={'20px'} color={'#4640DE'}>English</Typography>
                                        </Grid> */}
                                    </Grid>
                                </Grid>
                            </Grid>
                        )
                    }

                })}
                </Item>
        </Grid>
    )
}
export default Content