import React from "react";
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import { Typography, Button } from "@mui/material";
import { useNavigate } from "react-router-dom";
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';

const arr = [
    
    {
        title: "Research Updates",
        bttn: "Go to Research",
        path: "/Research"
    }
]


export default function ResearchDash() {
    const navigate = useNavigate();
    return (
        <>
            <Grid>
                {arr.map((item, index) => {
                    return (
                        <Grid>
                            <Typography style={{ fontSize: '28px', marginLeft: '20px', fontWeight: '400' }}>{item.title}</Typography>
                            <Grid container lg={6} md={6} sm={6} xs={12} sx={{ margin: "1% 4% 2% 1.5%" }}>
                                <Card style={{ backgroundColor: 'rgb(233 242 247)', height: '230px', textAlign: 'center', width: '250px' }}>
                                    <Grid container sx={{ justifyContent: 'center', alignItems: 'center' }}>
                                        <Button variant="contained" style={{ marginTop: '35%' }} onClick={() => navigate(item.path)}>{item.bttn} <AddCircleOutlineIcon /></Button>
                                    </Grid>
                                </Card>
                            </Grid>
                        </Grid>   
                    )
                })}
            </Grid>
        </>
    );
};