import React from 'react';
import { Grid, Box, Card, Typography, Button, IconButton } from '@mui/material';


import { NavLink, useLocation, useNavigate } from 'react-router-dom';
import Nav from "../Rohan/Nav" 

import styled from "@emotion/styled";

import Table from './Table';

import InterviewSch from './InterviewSch';
import GoToTop from '../../Components/Gototop';
import Hheader from '../../Vaibhav/Header';
import Footer from '../../Vikram/Footer1';



export default function Cobine() {
    const Typography1 = styled(Typography)({
        fontFamily: 'inter'
    })

    // const navigate = useNavigate();
    const location = useLocation()
    const data = location.state.data
    const userId = location.state.userId
    console.log(location.state)
    // const data = location.state.data
    return (

        <Grid container >

            <Grid xs={12} lg={12}><Hheader/></Grid>

            <Grid container xs={12} lg={12} marginTop='40px'>


                <Grid item sx={{ padding: "3% 1% 2% 2%" }} xs={12} lg={3.5}>
                    <Table data={data} userId={userId}/>
                </Grid>
                <Grid item sx={{ padding: "3% 1% 2% 2%" }} xs={12} lg={8} >
                    <InterviewSch data={data} userId={userId}/>
                </Grid>

            </Grid>
            {/* <Grid item container style={{ justifyContent: 'end' }} sx={{ padding: "2% 5% 2% 2%" }} xs={12}>
                <Button variant="contained" sx={{
                    borderRadius: '0px', backgroundColor: '#1a50b2',
                    ':hover': { color: '#1a50b2', backgroundColor: 'white' }
                }}
                    onClick={() => navigate('/step2')}>Next Page</Button>
            </Grid> */}
            <Footer/>
          <GoToTop/>
        </Grid>
    );
}